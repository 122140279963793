import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-confirm',
  template: `
    <h1 matDialogTitle class="mb-05">{{ data.title }}</h1>
    <div mat-dialog-content>
      <div class="mb-1">{{ data.message }}</div>
      <form [formGroup]="remarksForm">
        <mat-form-field appearance="fill" style="width: 100%;">
          <mat-label>Remarks</mat-label>
          <textarea matInput formControlName="remarks" placeholder="Enter your remarks"></textarea>
          <mat-error *ngIf="remarksForm.get('remarks').hasError('required')">
            Remarks are required.
          </mat-error>
        </mat-form-field>
      </form>
    </div>
    <div mat-dialog-actions>
      <button type="button" mat-raised-button color="primary" (click)="onConfirm()" [disabled]="remarksForm.invalid">OK</button>
      <button type="button" color="accent" mat-raised-button (click)="dialogRef.close(false)">Cancel</button>
    </div>
  `,
})
export class RemarkComfirmComponent {
  remarksForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<RemarkComfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,  
    private fb: FormBuilder
  ) {
    this.remarksForm = this.fb.group({
      remarks: ['', Validators.required] // Add Validators.required here
    });
  }

  onConfirm(): void {
    if (this.remarksForm.valid) {
      // Close the dialog and pass back the data
      this.dialogRef.close({ confirmed: true, remarks: this.remarksForm.value.remarks });
    }
  }
}

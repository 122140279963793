import { HttpErrorResponse } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild, Inject, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { AppConfirmService } from "app/shared/services/app-confirm/app-confirm.service";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { CommonService } from "app/shared/services/MyServices/common.service";
import { DataPassService } from "app/shared/services/MyServices/data-pass.service";
import { InventoryService } from "app/shared/services/MyServices/inventory.service";
import Swal from "sweetalert2";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddProductComponent } from "./add-product/add-product.component";
import { DealerInfoComponent } from "./dealer-info/dealer-info.component";
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit, OnDestroy {

  @ViewChild("scrollBottom", { static: false })
  private scrollBottom: ElementRef;
  checkTML: any;
  DivisionId: string;
  public viewMode: string = "list-view";
  pgTitle = "Confirmation ";
  pgText = "Are you sure you want to change the selection?";
  ServiceData: [];
  visiblitydata = "";
  ProductGroupLine = [];
  Type = [];
  config: any;
  StockVehicleInfo: any = [];
  InventoryImages: any = [];
  InventoryCoverImage: [];
  FinaldataData = [];
  temp = [];
  data: any;
  chassis_number = [];
  showmsg: boolean = false;

  p: number = 1;
  readable: any;
  regionalData: any = [];
  previousIndex: any = null;
  PANIndiaData: any;
  isApiCalled: boolean;
  totalCount: any = 0;
  selectedTabIndex: any = 0;
  isApiCalledMyStock: boolean;
  isApiCalledRegion: boolean;
  isApiCalledPan: boolean;
  isApiCalledAll:boolean = true;
  SessionData: any;
  excludedSubCatNames: string[] = [
    'CV NTML',
    'ISUZU DMAX',
    'M&HCV Const',
    'Magic IRIS',
    'NTML',
    'PCV - Venture',
    'Small Cars',
    'TASL',
  ];
  includedSubCatNames:string[]=[
    'ScPass',
'ICV Trucks',
'Pickups',
'HCV Const',
'HCV Cargo',
'Buses',
'MCV Trucks',
'LCV',
'CV NTML',
'SCVPass',
'SCV Cargo',
  ]
  filterValue:any={}
  constructor(
    private confirmService: AppConfirmService,
    private commonservice: CommonService,
    private loader: AppLoaderService,
    private router: Router,
    private Passdata: DataPassService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ProductListComponent>,
    private datePipe: DatePipe
  ) {
    this.config = {
      itemsPerPage: 0,
      currentPage: 0,
      totalItems: 0,
    };
  }


  isPageUrlCheck: any
  filterFields: boolean = false;
  isRegional: boolean = false;
  isMobile: boolean=false;
  ngOnInit() {
    this.SessionData = this.commonservice.getUserDetails();
    var list = JSON.parse(localStorage.getItem('PageDetails'))
    for (let entry of list) {
      if (entry.page_detail.length > 0) {
        for (let entryDetail of entry.page_detail) {
          if (entryDetail.page_url == 'ProductList') {
            this.isPageUrlCheck = "ProductList"
            this.readable = entryDetail.readable
          }
        }
      }
    }

    if (this.isPageUrlCheck == "ProductList") {
      this.scrollToBottom();
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
      this.DivisionId = localStorage.getItem("DivisionId");
      this.checkTML = "TML";
      // var TMLdata = {
      //   app_name: "com.tatamotors.tataoksourcing",
      //   app_version: "1.2",
      //   dev_id: "897jhjh6",
      //   offset: 0,
      //   size: 1000,
      //   //brand_type: this.checkTML,
      //   brand_type: "all",
      //   applications: ["Market Load Operators"],
      //   budget_category: "0-* ",
      //   gvw_from: "all",
      //   gvw_to: "all",
      //   hsn_include: ["all"],
      //   model_vc_number: "all",
      // };
      // this.GetData(TMLdata);
      const ResponseData: inputData = {} as inputData;
      ResponseData.offset = 0;
      ResponseData.expired = "";
      ResponseData.visibility = this.visiblitydata;
      this.getInventoryList(ResponseData);
    }
    else {
      this.router.navigate(["pages/ErrorPage"]);
    }
    this.checkMobileView();
    window.addEventListener('resize', this.checkMobileView.bind(this));
  }
  checkMobileView() {
    this.isMobile = window.matchMedia('(max-width: 768px)').matches;
    console.log(this.isMobile);
    
  }
  refreshListing() {
    this.filterValue={};
    this.isApiCalled = true;
    const ResponseData: inputData = {} as inputData;
    ResponseData.offset = 0;
    this.config.currentPage = 0;
    ResponseData.expired = "";
    ResponseData.visibility = this.visiblitydata;
    this.getInventoryList(ResponseData);
  }
  scrollToBottom(): void {
    try {
      this.scrollBottom.nativeElement.scrollTop = 0;
    } catch (err) { }
  }
  getchassis = [];
  AddVehicle(data: any = {}) {
    localStorage.removeItem("vc_number");
    localStorage.setItem("vc_number", data.vc_number);
    localStorage.removeItem("model_id");
    localStorage.setItem("model_id", data.model_id);
    this.Passdata.setStockData(data);
    this.router.navigate(["pages/InventoryMaster/"]);
  }

  Checkchassis_number: [];
  hidebutton: boolean = false;
  label: string;
  tempdata = [];
  allData = [];
  tempdata1 = []
  getInventoryList(json) {
    const ResponseData: inputData = {} as inputData;
    ResponseData.cat_id="3"
    if (this.filterValue) {
      var loginData = JSON.parse(localStorage.getItem('loginData'));

ResponseData.visibility = this.visiblitydata;
ResponseData.state = this.filterValue.state;
ResponseData.district = this.filterValue.district;
ResponseData.no_of_owners =this.filterValue.owner;
if (this.visiblitydata == "" && this.isApiCalledRegion == true) {
        ResponseData.region = this.filterValue.region ? this.filterValue.region : loginData.region;
      }
      else {
        if (this.visiblitydata == "" && this.isApiCalledRegion == true) {
          ResponseData.region = this.filterValue.region ? this.filterValue.region : "";
        }
      }
      // ResponseData.expired = this.filterValue.sold == "true" ? true : false;
      ResponseData.expired = this.filterValue.sold === "true" ? true : this.filterValue.sold === "false" ? false : false ;

      ResponseData.vehicle_type = this.filterValue.vehicleType;
      ResponseData.finance_availability =this.filterValue.finance;
      ResponseData.engine_warranty = this.filterValue.warranty;
      ResponseData.emission = this.filterValue.emission;
      ResponseData.lob=this.filterValue.sub_cat_name;
      ResponseData.ppl=this.filterValue.sub_cat_detail_name;
      ResponseData.is_active=this.filterValue.active === null || this.filterValue.active === undefined || this.filterValue.active === '' ? '' : this.filterValue.active ? true : false
      ResponseData.osp_is_active=this.filterValue.ospactive === null || this.filterValue.ospactive === undefined || this.filterValue.ospactive === '' ? '' : this.filterValue.ospactive ? true : false
      
    }
    Object.assign(ResponseData, json);
    if (Object.keys(this.filterValue).length === 0) {
      ResponseData.expired = false
    }
    this.allData = [];
    this.tempdata = [];
    this.PANIndiaData = [];
    this.regionalData = [];
    this.loader.open();
    this.config.totalItems = 0

    this.commonservice.GetInventoryList(ResponseData).subscribe(
      (res) => {
        this.loader.close();
        this.isApiCalled = false;
        if (res.success) {
          this.totalCount = res.total_count;
          if (this.visiblitydata == "private") {
            this.tempdata = res.data;
          }
          else if (this.visiblitydata == "public") {
            this.PANIndiaData = res.data;
          }
          else if (this.isApiCalledRegion == true){
            this.regionalData = res.data;
          }
          else {
            this.allData = res.data;
          }
          // this.config.currentPage = 1;
          this.config.itemsPerPage = 10
          this.config.totalItems = res.total_count;

          this.dialog.closeAll();

        } else {
          this.totalCount = 0;
          const errorMessage = res;
          // return;
        }
      },
      (err) => {
        this.isApiCalled = false;
        this.loader.close();
        console.log("error occured", err);
      }
    );
  }

  getDaysLeft(rowData: any): number {
    let date1 = this.datePipe.transform(rowData.created_at, 'yyyy/MM/dd');
    const fromDate = new Date(date1);
    const toDate = new Date();
    const thirtyDaysLater = new Date(fromDate);
    // let daysInMonth = this.GFG_Fun();
    let daysInMonth = 30;
    thirtyDaysLater.setDate(thirtyDaysLater.getDate() + daysInMonth);



    // Get the time difference in milliseconds
    if (fromDate) {


      if (!rowData.extend) {

        const timeDifference = toDate.getTime() - fromDate.getTime();
        const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

        return 31 - daysDifference;
      } else {

        const timeDifference = fromDate.getTime() - toDate.getTime();
        const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
        return daysDifference;
      }
    }

    // Convert milliseconds to days

  }
  // calculateDaysValidity(createdDate: Date): number {
  //   const currentDate = new Date();
  //   const timeDifference = currentDate.getTime() - createdDate.getTime();
  //   const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  //   return daysDifference;
  // }
  calculateDiff(dateSent) {
    let currentDate = new Date();
    dateSent = new Date(dateSent);

    return Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) / (1000 * 60 * 60 * 24));
  }
  daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  GFG_Fun() {
    let date = new Date();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    // console.log("Number of days in " + month
    //   + "th month of the year " + year
    //   + " is " + this.daysInMonth(month, year));
    return this.daysInMonth(month, year);
  }

  off: number;
  pageChanged(event) {
    document.body.scrollTop = 0;
    var offset = event;
    this.off = event;
    if (offset > 0) {
      offset = offset - 1;
    }
    this.config.currentPage = event;
    this.p = event;

    const ResponseData: inputData = {} as inputData;
    ResponseData.offset = offset ;
    ResponseData.visibility = this.visiblitydata;
    if (this.visiblitydata == "" && this.isApiCalledRegion == true) {
      var loginData = JSON.parse(localStorage.getItem('loginData'));
      ResponseData.region = loginData.region;
    }
    this.getInventoryList(ResponseData);
  }

  openDialog(flag, rowData): void {
    console.log(rowData.params_approval_status,rowData.osp_is_active);
    if (rowData.osp_is_active == true && rowData.params_approval_status == 'APPROVED') {
      Swal.fire({
        toast: true,
        icon: 'warning',
        title: 'This vehicle has already been approved by TSM. If you wish to edit it, please mark the vehicle as inactive first.',
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500,
        // timerProgressBar: true,
      });
    }
    else{
    let daysLeft = this.getDaysLeft(rowData);
    let tempVisibility = this.visiblitydata == "private" ? "Dealer Only" : this.visiblitydata == "public" ? "PAN India" : "region";
    const dialogRef = this.dialog.open(AddProductComponent, {
      width: '50%',
      data: { data: rowData, flag: flag, visibility: tempVisibility, daysLeft: daysLeft },
      disableClose: true

    });

    // cancle popup api calling
    dialogRef.afterClosed().subscribe(result => {
      // console.log('here', result)
      // if (result === 'saved') {commented because load api on cancle for image delete functionality
        // Perform actions after the dialog is closed with save
        const ResponseData: inputData = {} as inputData;
        ResponseData.offset = 0;
        ResponseData.visibility = this.visiblitydata;
        if (this.visiblitydata == "" && this.isApiCalledRegion == true) {
          var loginData = JSON.parse(localStorage.getItem('loginData'));
          ResponseData.region = loginData.region;
        }
        this.getInventoryList(ResponseData);
      // }
    });
  }
  }
  TabReader(index) {
    this.isApiCalled = true;
    this.isApiCalledMyStock = false;
    this.isApiCalledPan = false;
    this.isApiCalledRegion = false;
    this.isApiCalledAll=false;
    const ResponseData: inputData = {} as inputData;
    this.popupHandler();
    this.isRegional = false;
    this.selectedTabIndex = index;

    if (index == 0) {
      this.isApiCalledAll = true;
      this.allData = [];
      this.visiblitydata = "";
      ResponseData.offset = 0;
      ResponseData.visibility = this.visiblitydata;
    } else if (index == 1) {
      this.isApiCalledMyStock = true;
      this.tempdata = [];
      this.visiblitydata = "private";
      ResponseData.offset = 0;
      ResponseData.visibility = this.visiblitydata;
    }
    else if (index == 2) {
      this.isApiCalledPan = true;
      this.PANIndiaData = [];
      this.visiblitydata = "public";
      ResponseData.offset = 0;
      ResponseData.visibility = this.visiblitydata;
    } else {
      this.isApiCalledRegion = true;
      this.regionalData = [];
      this.isRegional = true;
      this.visiblitydata = "";
      var loginData = JSON.parse(localStorage.getItem('loginData'));
      ResponseData.region = loginData.region;
      ResponseData.offset = 0;
      ResponseData.visibility = this.visiblitydata;
    }
    // else {
    //   this.isApiCalledAll = true;
    //   this.allData = [];
    //   this.visiblitydata = "";
    //   ResponseData.offset = 0;
    //   ResponseData.visibility = this.visiblitydata;
    // }
    this.filterValue={};
    this.config.offset = 0;
    this.config.currentPage = 0;
    this.getInventoryList(ResponseData);
  }

  dealerInfo(index, flag) {

    if (flag == 'mystocks') {
      if (this.previousIndex != null && (index != this.previousIndex)) {
        this.tempdata[this.previousIndex].isDealer = false
      }

      if (this.tempdata[index].isDealer) {
        this.tempdata[index].isDealer = !this.tempdata[index].isDealer
      }
      else {
        this.tempdata[index].isDealer = true
      }
    }
    else if (flag == 'regionalstocks') {
      if (this.previousIndex != null && (index != this.previousIndex)) {
        this.regionalData[this.previousIndex].isDealer = false
      }
      if (this.regionalData[index].isDealer) {
        this.regionalData[index].isDealer = !this.regionalData[index].isDealer
      }
      else {
        this.regionalData[index].isDealer = true;
      }
    }
    this.previousIndex = index;
    // this.openDealerDialog()  
  }
  // openDealerDialog(): void {
  //   const dialogRef = this.dialog.open(DialogComponent, {
  //     width: '250px',
  //     data: {name: this.name, animal: this.animal}
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log('The dialog was closed');
  //     this.animal = result;
  //   });
  // }

  formControlValue(req) {
    this.filterValue={};
    this.filterValue=req
    var loginData = JSON.parse(localStorage.getItem('loginData'));
    const ResponseData: inputData = {} as inputData;
    ResponseData.visibility = this.visiblitydata;
    ResponseData.offset = 0;

    if (req == "reset") {
      if (this.visiblitydata == "" && this.isApiCalledRegion == true) {
        ResponseData.region = loginData.region;
      }
      this.filterValue={};
      this.getInventoryList(ResponseData);
    }
    else {

      // ResponseData.state = req.state;
      // ResponseData.district = req.district;
      // if (this.visiblitydata == "") {
      //   ResponseData.region = req.region ? req.region : loginData.region;
      // }
      // else {
      //   if (this.visiblitydata == "") {
      //     ResponseData.region = req.region ? req.region : "";
      //   }
      // }
      // ResponseData.no_of_owners = req.owner;
      // ResponseData.expired = req.sold == "true" ? true : false;
      // ResponseData.vehicle_type = req.vehicleType;
      // ResponseData.finance_availability = req.finance;
      // ResponseData.engine_warranty = req.warranty;
      // ResponseData.emission = req.emission;
      // ResponseData.sub_cat_name=req.sub_cat_name;
      // ResponseData.sub_cat_detail_name=req.sub_cat_detail_name

      this.getInventoryList(ResponseData);
    }

  }

  openDealerInfo(rowData): void {
    let imagesArr = rowData.vehicle_documents.filter((item: any) => item.name == null);
    console.log(imagesArr);

    if (imagesArr.length == 0) {
      return;
    }
    else {
      const dialogRef = this.dialog.open(DealerInfoComponent, {
        disableClose: true, width: '50%',
        data: rowData
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result === 'saved') {
          // Perform actions after the dialog is closed with save
          // const ResponseData: inputData = {} as inputData;
          // ResponseData.offset = 0;
          // ResponseData.visibility = this.visiblitydata;
          // this.getInventoryList(ResponseData);
        }
      });
    }
  }

  openImage(rowData) {
    var element = document.getElementById('popup1');
    element.style.display = 'block'
  }

  UpdateVehicleValidity(flag, rowData) {
    this.confirmService
      .confirm({ message: ` Are you sure you want to sold this vehicle ?` })
      .subscribe((data) => {
        if (data) {
          var json = {
            "vehicle_id": rowData.id,
            "action_type": flag,
            "extend_date": ""
          }

          this.loader.open();
          this.commonservice.updateVehicleValidity(json).subscribe(
            (res) => {
              this.loader.close();
              if (res.success) {
                // Swal.fire(res.data.msg);
                Swal.fire("Vehicle sold Successfully");
                const ResponseData: inputData = {} as inputData;
                ResponseData.offset = 0;
                ResponseData.expired = "";
                ResponseData.visibility = this.visiblitydata;
                this.getInventoryList(ResponseData);
              } else {
                const errorMessage = res;
                Swal.fire(res.error.data.msg)
                // return;
              }
            },
            (err) => {
              this.loader.close();
              console.log("error occured", err);
            }
          );

        }
      });

  }
  Approve(flag, rowData) {
if (rowData.osp_is_active) {
    if (Number(rowData.dealer_suggestive_price) > 0) {
      this.confirmService
      .confirm({ message: ` Are you sure you want to Initiate this vehicle ?` })
      .subscribe((data) => {
        if (data) {
          var json = {
            "vehicle_id": rowData.id,
            "params_approval_status": flag,
          }

          this.loader.open();
          this.commonservice.approveCall(json).subscribe(
            (res) => {
              this.loader.close();
              if (res.success) {
                // Swal.fire(res.data.msg);
                Swal.fire("Vehicle Initiated Successfully");
                const ResponseData: inputData = {} as inputData;
                ResponseData.offset = 0;
                ResponseData.expired = "";
                ResponseData.visibility = this.visiblitydata;
                this.getInventoryList(ResponseData);
              } else {
                const errorMessage = res;
                Swal.fire(res.error.data.msg)
                // return;
              }
            },
            (err) => {
              this.loader.close();
              console.log("error occured", err);
            }
          );

        }
      });
    }
    else{
      Swal.fire('Price not updated in CRM, please update in CRM to proceed')
    }
  }
  else{
    Swal.fire("Please Active the vehicle from Fleetverse Action")
  }
  } 

  DisApprove(flag, rowData) {
    this.confirmService
      .confirm({ message: ` Are you sure you want to DisApprove this vehicle ?` })
      .subscribe((data) => {
        if (data) {
          var json = {
            "vehicle_id": rowData.id,
            "params_approval_status": flag,
          }

          this.loader.open();
          this.commonservice.approveCall(json).subscribe(
            (res) => {
              this.loader.close();
              if (res.success) {
                // Swal.fire(res.data.msg);
                Swal.fire("Vehicle Disapprove Successfully");
                const ResponseData: inputData = {} as inputData;
                ResponseData.offset = 0;
                ResponseData.expired = "";
                ResponseData.visibility = this.visiblitydata;
                this.getInventoryList(ResponseData);
              } else {
                const errorMessage = res;
                Swal.fire(res.error.data.msg)
                // return;
              }
            },
            (err) => {
              this.loader.close();
              console.log("error occured", err);
            }
          );

        }
      });

  }
   
  popupHandler() {
    this.filterFields = !this.filterFields;
  }
  activeInactive(data:any,product:any){
let payLoad={
  "id":product.id,
  "flag":data.checked
}
this.loader.open();
this.commonservice.activenInactive(payLoad).subscribe(
  (res) => {
    this.loader.close();
    if (res.success) {
      // Swal.fire(res.data.msg);
      // Swal.fire("Vehicle Disapprove Successfully");
      const ResponseData: inputData = {} as inputData;
      ResponseData.offset = 0;
      ResponseData.expired = "";
      ResponseData.visibility = this.visiblitydata;
      this.getInventoryList(ResponseData);
    } else {
      const errorMessage = res;
      // Swal.fire(res.error.data.msg)
      // return;
    }
  },
  (err) => {
    this.loader.close();
    console.log("error occured", err);
  }
);


  }
  activeInactiveOSP(data:any,product:any){
    console.log();
    
    let payLoad={
      "id":product.id,
      "flag":data.checked
    }
    this.loader.open();
    this.commonservice.activenInactiveOSP(payLoad).subscribe(
      (res) => {
        this.loader.close();
        if (res.success) {
          // Swal.fire(res.data.msg);
          // Swal.fire("Vehicle Disapprove Successfully");
          const ResponseData: inputData = {} as inputData;
          ResponseData.offset = 0;
          ResponseData.expired = "";
          ResponseData.visibility = this.visiblitydata;
          this.getInventoryList(ResponseData);
        } else {
          const errorMessage = res;
          // Swal.fire(res.error.data.msg)
          // return;
        }
      },
      (err) => {
        this.loader.close();
        console.log("error occured", err);
      }
    );
    
    
      }
  getiscover(data:any){
    const original_data = data
    const images = [...data];
    const filteredImages = images.filter(item => item.is_cover === true);

    if (filteredImages.length > 0) {
        return filteredImages[0].path
    } else {
        return original_data[0].path
    }
  }
  ngOnDestroy(): void {
    window.removeEventListener('resize', this.checkMobileView.bind(this));
  }

}

export class InventoryListData {
  is_cover: string;
  color: string;
  model_name: string;
  model_id: string;
  brand_type: string;
  ppl: string;
  ppl_id: string;
  msrp: string;
  lob: string;
  vc_number: string;
  registration_number: string;
  chassis_number: string;
  mfg_date: Date;
  Vehicle_cat: string;
  hsn: string;
  grows_weight: string
}

export class inputData {
  user_id: any;
  offset: number;
  text_search: string;
  visibility: string;
  state: string;
  max_odometer: number;
  min_odometer: number;
  city: string;
  district: string;
  minimum_budget: number;
  no_of_owners: string;
  region: string;
  expired: any;
  vehicle_category: string;
  vehicle_type: string;
  finance_availability: string;
  engine_warranty: string;
  emission: string;
  sub_cat_name:string;
  sub_cat_detail_name:string;
  lob:string;
  ppl:string;
  is_active:any;
  osp_is_active:any;
  cat_id:any;
}
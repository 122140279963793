import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dealer-info',
  templateUrl: './dealer-info.component.html',
  styleUrls: ['./dealer-info.component.scss']
})
export class DealerInfoComponent implements OnInit {
  imagesArr = [];
  constructor( @Inject(MAT_DIALOG_DATA) public data,public dialog: MatDialog) { }

  ngOnInit() {
    this.imagesArr = this.data.vehicle_documents.filter((item: any) => item.name == null);
    // console.log("imagesArr  ========  ",this.imagesArr);
    
    // this.data.vehicle_documents = imagesArr;
    this.showImage(this.currentIndex);
  }

  currentIndex = 0;
  // images = document.querySelectorAll('.images img') as NodeListOf<HTMLImageElement>;

 showImage(index) {
  // this.images.forEach(img => img.style.display = 'none');
  // this.images[index].style.display = 'block';
}

prevImage() {
  if(this.currentIndex != 0){
    this.currentIndex -= 1;
  }
  // if (this.currentIndex === 0) {
  //   this.currentIndex = this.images.length - 1;
  // } else {
  //   this.currentIndex--;
  // }
  // this.showImage(this.currentIndex);
}

nextImage() {  
  if(this.currentIndex != this.data.vehicle_documents.length - 1){
    this.currentIndex += 1;
  }
  
  // if (this.currentIndex === this.images.length - 1) {
  //   this.currentIndex = 0;
  // } else {
  //   this.currentIndex++;
  // }
  // this.showImage(this.currentIndex);
}

 // Show initial image


}

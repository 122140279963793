import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { egretAnimations } from "../../../../shared/animations/egret-animations";
import { TransactionService } from '../../../../shared/services/MyServices/transaction.service';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { AppConfirmService } from '../../../../shared/services/app-confirm/app-confirm.service';
import { AppLoaderService } from '../../../../shared/services/app-loader/app-loader.service';
import { DataPassService } from '../../../../shared/services/MyServices/data-pass.service';
import { Page } from '../../../../../../src/app/shared/models/PaginationPage';
import { CommonService } from 'app/shared/services/MyServices/common.service';
import { DatePipe } from '@angular/common';
import { MatDialogRef, MatDialog } from '@angular/material';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

// import { ViewEnquiryPopupComponent } from './view-enquiry-popup/view-enquiry-popup.component';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { trigger, style, animate, transition, query, animateChild } from '@angular/animations';

@Component({
  selector: 'app-lead-list',
  templateUrl: './lead-list.component.html',
  styleUrls: ['./lead-list.component.scss'],
  animations: [
    trigger('animate', [
      transition('* => *', [
        query(':enter, :leave', [
          style({ transform: 'translateY({{y}})' }),
          animate('{{delay}} ease-out', style({ transform: 'translateY(0)' }))
        ], { optional: true })
      ])
    ])
  ]
})
export class LeadListComponent implements OnInit {
  page = new Page();
  public items: any[];
  temp = [];
  tableOffset: any;
  from_date: any;
  to_date: any;
  chassis_number: any;
  vc_number: any;
  lob: any;
  distributor_code: any;
  state: any;
  city: any;
  customer_type: any;
  customer_name: any;
  lead_id: any;
  opty_id: any;
  full_name: any;
  last_name: any;
  vehicle_application: any;
  source_of_contact: any;
  district: any;
  organization_name: any;
  mobile_no: any;
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  public getItemSub: Subscription;
  leaditems: any;

  constructor(
    private loader: AppLoaderService, private commonservice: CommonService, private datepipe: DatePipe,private router: Router,
    private dialog: MatDialog) {
    this.page.pageNumber = 0;
    this.page.size = 10;
    this.page.totalElements = 0;
    this.page.totalElementsPark = 0
  }
  RoleType: any;
  SessionData: any;
  isDealer: boolean = true;
  isPageUrlCheck: any;
  tab1: boolean = true; // Initially show Lead List 1
  tab2: boolean = false;
  ngOnInit() {
    var list = JSON.parse(localStorage.getItem('PageDetails'))
    for (let entry of list) {
      if (entry.page_detail.length > 0) {
        for (let entryDetail of entry.page_detail) {
          if (entryDetail.page_url == 'ViewEnquiry') {
            this.isPageUrlCheck = "ViewEnquiry"
          }
        }
      }
    }

    if (this.isPageUrlCheck == "ViewEnquiry") {
      this.tableOffset = 0
      this.SessionData = this.commonservice.getUserDetails();
      this.RoleType = this.SessionData.role_name;
      if (this.RoleType == 'Seller') {
        this.RoleType = 'Dealer';
      }
      else {
        this.RoleType = 'Admin';
      }
      if (this.RoleType == 'Dealer') {
        this.isDealer = false;
      }
      else {
        this.isDealer = true;
      }
      const ListInput: ListInput = {} as ListInput;
      ListInput.offset = 0;
      ListInput.cat_id=3
      // ListInput.size = 10;
      // this.getEnquiryDataList(ListInput)
      this.getInventoryList(ListInput)
    }
    else {
      this.router.navigate(["pages/ErrorPage"]);
    }

  }

  getInventoryList(json) {
    // this.tempdata = [];
    // this.loader.open();
    // this.config.totalItems = 0
    this.loader.open();
    this.commonservice.getLeadList(json).subscribe(
      (res) => {
        this.loader.close();
        if (res.success) {
          // this.tempdata = res.data;
          // this.config.itemsPerPage = 10
          // this.config.totalItems = res.total_count;
          this.page.totalElementsPark = res.page_count;
          this.items = this.temp = res.data;
          this.loader.close();
        } else {
          const errorMessage = res;
          Swal.fire(res.data.msg)
          this.items = []
          this.page.totalElementsPark = 0;
          // this.page.size = 0;
          this.items = this.temp = [];
          this.loader.close();
          // return;
        }
      },
      (err) => {
        this.loader.close();
        console.log("error occured", err);
      }
    );
    
  }

  getEnquiryDataList(ListInput) {
    this.loader.open();
    this.items = [];
    this.FilterStrings(ListInput);
    this.commonservice.BindEnquiryListData(ListInput)
      .subscribe(data => {
        if (data instanceof HttpErrorResponse) {
          return;
        }
        if (data.success = true) {
          this.loader.close();
          this.page.totalElementsPark = data.page_count;
          this.items = this.temp = data.data;
        }
        else {
          this.items = [];
          Swal.fire(data.data.msg, 'Error')
          this.page.totalElementsPark = 0;
          this.page.size = 0;
          this.items = this.temp = [];

          this.loader.close();
        }
      },
        (err) => {
          this.page.totalElements = 0;
          this.items = [];
          this.loader.close();
        });


  }
  updateFilter(event) {
    this.table.offset = 0;
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);
    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
  }

  setPage(pageInfo) {
    this.tableOffset = pageInfo.offset;
    this.page.pageNumber = pageInfo.offset;
  
    const ListInput: ListInput = this.getFilteredListInput();
    ListInput.size = this.page.size;
    ListInput.offset = (pageInfo.offset * 1);
    ListInput.size = 10;
    this.table.offset = 0;

  
    if (this.tab2) {
      ListInput.lead_source = "osp";
      this.getospleadDataList(ListInput);
    } else {
      ListInput.cat_id=3
      this.getInventoryList(ListInput);
      // this.getEnquiryDataList(ListInput);
    }
  }
  
  getFilteredListInput(): ListInput {
    const ListInput: ListInput = {} as ListInput;
    
    if (this.opty_id) { ListInput.opty_id = this.opty_id; } else { ListInput.opty_id = ""; }
    if (this.lead_id) { ListInput.lead_id = this.lead_id; } else { ListInput.lead_id = ""; }
    if (this.mobile_no) { ListInput.mobile_no = this.mobile_no; } else { ListInput.mobile_no = ""; }
    if (this.chassis_number) { ListInput.chassis_number = this.chassis_number; } else { ListInput.chassis_number = ""; }
    if (this.from_date) { ListInput.from_date = this.from_date; } else { ListInput.from_date = ""; }
    if (this.to_date) { ListInput.to_date = this.to_date; } else { ListInput.to_date = ""; }
    if (this.vc_number) { ListInput.vc_number = this.vc_number; } else { ListInput.vc_number = ""; }
    if (this.lob) { ListInput.lob = this.lob; } else { ListInput.lob = ""; }
    if (this.distributor_code) { ListInput.distributor_code = this.distributor_code; } else { ListInput.distributor_code = ""; }
    if (this.state) { ListInput.state = this.state; } else { ListInput.state = ""; }
    if (this.district) { ListInput.district = this.district; } else { ListInput.district = ""; }
    if (this.city) { ListInput.city = this.city; } else { ListInput.city = ""; }
    if (this.full_name) { ListInput.full_name = this.full_name; } else { ListInput.full_name = ""; }
    if (this.customer_type) { ListInput.customer_type = this.customer_type; } else { ListInput.customer_type = ""; }
    if (this.source_of_contact) { ListInput.source_of_contact = this.source_of_contact; } else { ListInput.source_of_contact = ""; }
    if (this.organization_name) { ListInput.organization_name = this.organization_name; } else { ListInput.organization_name = ""; }
    if (this.vehicle_application) { ListInput.vehicle_application = this.vehicle_application; } else { ListInput.vehicle_application = ""; }
  
    return ListInput;
  }
  FilterString: any;



  FilterStrings(ListInput) {
    this.FilterString = "";
    if (ListInput.opty_id == "" || ListInput.opty_id == undefined || ListInput.opty_id == null) { }
    else { this.FilterString = this.FilterString + ' <b>Opty ID.: </b>' + ListInput.opty_id; }

    if (ListInput.vc_number == "" || ListInput.vc_number == undefined || ListInput.vc_number == null) { }
    else { this.FilterString = this.FilterString + ' <b>VC No. : </b>' + ListInput.vc_number; }

    if (ListInput.lead_id == "" || ListInput.lead_id == undefined || ListInput.lead_id == null) { }
    else { this.FilterString = this.FilterString + ' <b>Lead ID: </b>' + ListInput.lead_id; }

    if (ListInput.mobile_no == "" || ListInput.mobile_no == undefined || ListInput.mobile_no == null) { }
    else { this.FilterString = this.FilterString + ' <b>Mobile No.: </b>' + ListInput.mobile_no; }

    if (ListInput.chassis_number == "" || ListInput.chassis_number == undefined || ListInput.chassis_number == null) { }
    else { this.FilterString = this.FilterString + ' <b>Chassis No. : </b>' + ListInput.chassis_number; }

    if (ListInput.lob == "" || ListInput.lob == undefined || ListInput.lob == null) { }
    else { this.FilterString = this.FilterString + ' <b>LOB: </b>' + ListInput.lob; }

    if (ListInput.distributor_code == "" || ListInput.distributor_code == undefined || ListInput.distributor_code == null) { }
    else { this.FilterString = this.FilterString + ' <b>Distributor Code: </b>' + ListInput.distributor_code; }

    if (ListInput.state == "" || ListInput.state == undefined || ListInput.state == null) { }
    else { this.FilterString = this.FilterString + ' <b>State : </b>' + ListInput.state; }

    if (ListInput.district == "" || ListInput.district == undefined || ListInput.district == null) { }
    else { this.FilterString = this.FilterString + ' <b>District: </b>' + ListInput.district; }

    if (ListInput.city == "" || ListInput.city == undefined || ListInput.city == null) { }
    else { this.FilterString = this.FilterString + ' <b>City: </b>' + ListInput.city; }

    if (ListInput.full_name == "" || ListInput.full_name == undefined || ListInput.full_name == null) { }
    else { this.FilterString = this.FilterString + ' <b>Customer Name: </b>' + ListInput.full_name; }

    if (ListInput.customer_type == "" || ListInput.customer_type == undefined || ListInput.customer_type == null) { }
    else { this.FilterString = this.FilterString + ' <b>Customer Type: </b>' + ListInput.customer_type; }

    if (ListInput.vehicle_application == "" || ListInput.vehicle_application == undefined || ListInput.vehicle_application == null) { }
    else { this.FilterString = this.FilterString + ' <b>Vehicle Application: </b>' + ListInput.vehicle_application; }

    if (ListInput.source_of_contact == "" || ListInput.source_of_contact == undefined || ListInput.source_of_contact == null) { }
    else { this.FilterString = this.FilterString + ' <b>Source of Contact: </b>' + ListInput.source_of_contact; }

    if (ListInput.organization_name == "" || ListInput.organization_name == undefined || ListInput.organization_name == null) { }
    else { this.FilterString = this.FilterString + ' <b>Dealer Name: </b>' + ListInput.organization_name; }

    if (ListInput.from_date == "" || ListInput.from_date == undefined || ListInput.from_date == null) { }
    else { this.FilterString = this.FilterString + ' <b>From Date: </b> ' + this.datepipe.transform(this.from_date, 'dd-MM-yyyy') }

    if (ListInput.to_date == "" || ListInput.to_date == undefined || ListInput.to_date == null) { }
    else {
      this.FilterString = this.FilterString + ' <b>To Date: </b> ' + this.datepipe.transform(this.to_date, 'dd-MM-yyyy')
    }
  }




  receiveMessage($event) {
    this.tableOffset = 0
    this.chassis_number = $event.chassis_number;
    this.vc_number = $event.vc_number
    this.mobile_no = $event.mobile_no
    this.lob = $event.lob
    this.distributor_code = $event.distributor_code
    this.from_date = $event.from_date
    this.to_date = $event.to_date
    this.full_name = $event.full_name
    this.customer_type = $event.customer_type
    this.vehicle_application = $event.vehicle_application
    this.source_of_contact = $event.source_of_contact
    this.from_date = this.datepipe.transform(this.from_date, 'yyyy-MM-dd')
    this.to_date = this.datepipe.transform(this.to_date, 'yyyy-MM-dd')
    this.opty_id = $event.opty_id
    this.lead_id = $event.lead_id
    try {
      if ($event.state.name == null || $event.state.name == undefined || $event.state.name == "") {

      }
      else {
        this.state = $event.state.name
      }
    }
    catch (ex) {
      this.state = ''
    }
    try {
      if ($event.lob.sub_cat_name == null || $event.lob.sub_cat_name == undefined || $event.lob.sub_cat_name == "") {

      }
      else {
        this.lob = $event.lob.sub_cat_name
      }
    }
    catch (ex) {
      this.lob = ''
    }


    this.district = $event.district
    this.city = $event.city
    this.organization_name = $event.organization_name
    const ListInput: ListInput = {} as ListInput;
    if (this.lead_id) { ListInput.lead_id = this.lead_id; } else { this.lead_id = ""; ListInput.lead_id = ""; }

    if (this.opty_id) { ListInput.opty_id = this.opty_id; } else { ListInput.opty_id = ""; }

    if (this.mobile_no) { ListInput.mobile_no = this.mobile_no; ListInput.mobile_no = this.mobile_no; } else { ListInput.mobile_no = ""; }

    if (this.chassis_number) { ListInput.chassis_number = this.chassis_number; } else { this.chassis_number = ""; ListInput.chassis_number = ""; }

    if (this.vc_number) { ListInput.vc_number = this.vc_number; } else { ListInput.vc_number = ""; }

    if (this.distributor_code) { ListInput.distributor_code = this.distributor_code; } else { ListInput.distributor_code = ""; }

    if (this.state) { ListInput.state = this.state; } else { ListInput.state = ""; }

    if (this.district) { ListInput.district = this.district; ListInput.district = this.district; } else { ListInput.district = ""; }

    if (this.city) { ListInput.city = this.city; ListInput.city = this.city; } else { ListInput.city = ""; }

    if (this.full_name) { ListInput.full_name = this.full_name; ListInput.full_name = this.full_name; } else { ListInput.full_name = ""; }

    if (this.vehicle_application) { ListInput.vehicle_application = this.vehicle_application; } else { ListInput.vehicle_application = ""; }

    if (this.source_of_contact) { ListInput.source_of_contact = this.source_of_contact; ListInput.source_of_contact = this.source_of_contact; } else { ListInput.source_of_contact = ""; }

    if (this.from_date) { ListInput.from_date = this.from_date; } else { ListInput.from_date = ""; }

    if (this.to_date) { ListInput.to_date = this.to_date; } else { ListInput.to_date = ""; }

    if (this.organization_name) { ListInput.organization_name = this.organization_name; } else { ListInput.organization_name = ""; }
    if (this.customer_type) { ListInput.customer_type = this.customer_type; } else { ListInput.customer_type = ""; }

    if (this.lob) { ListInput.lob = this.lob; } else { ListInput.lob = ""; }

    ListInput.size = 10
    ListInput.offset = 0;

    this.tableOffset = 0
    this.getEnquiryDataList(ListInput)
  }

  // ViewEnquiry(data: any = {}) {
  //   if (data != '') {
  //     let title = '';
  //     let dialogRef: MatDialogRef<any> = this.dialog.open(ViewEnquiryPopupComponent, {
  //       width: '1000px',
  //       disableClose: false,
  //       data: { title: title, payload: data }
  //     })
  //   }
  //   else {

  //   }
  // }


  switchTab(tabNumber: number) {
    this.page.pageNumber = 0;
    this.tableOffset = 0;
    
    if (tabNumber === 1) {
      this.tab1 = true;
      this.tab2 = false;
      this.getLeadList();
    } else if (tabNumber === 2) {
      this.tab1 = false;
      this.tab2 = true;
      this.getOspLeadList();
    }
  }
  
  getLeadList() {
    const ListInput: ListInput = {} as ListInput;
    ListInput.offset = 0;
    ListInput.size = this.page.size;
    ListInput.cat_id=3
    this.getInventoryList(ListInput);
  }
  
  getOspLeadList() {
    const ListInput: ListInput = {} as ListInput;
    ListInput.offset = 0;
    ListInput.size = this.page.size;
    ListInput.lead_source = "osp";
    this.getospleadDataList(ListInput);
  }

  getospleadDataList(ListInput) {
    this.loader.open();
    this.commonservice.ospLeadsList(ListInput)
      .subscribe(data => {
        if (data instanceof HttpErrorResponse) {
          return;
        }
        if (data.success) {
          this.loader.close();
          this.page.totalElements = data.page_count;
          this.leaditems = this.temp = data.data;
        } else {
          // Error handling
          this.leaditems = [];
          Swal.fire(data.data.msg, 'Error')
          this.page.totalElements = 0;
          // this.page.size = 0;
          this.leaditems = this.temp = [];
          this.loader.close();
        }
      },
      (err) => {
        this.page.totalElements = 0;
        this.leaditems = [];
        this.loader.close();
      });
  }

  downloadAllLeadDataAndExcel(): void {
    this.loader.open();
    let offset = 0;
    const pageSize = 10; 
    const leadSource = 'osp';
    const allLeadItems: any[] = []; 
    const fetchData = (currentOffset: number) => {
      const ListInput = {
        offset: currentOffset,
        size: pageSize,
        lead_source: leadSource
      };
  
      // setTimeout(() => {
        this.commonservice.ospLeadsList(ListInput).subscribe(
          data => {
            if (data instanceof HttpErrorResponse) {
              this.loader.close();
              Swal.fire('Error fetching data', 'Error');
              return;
            }
  
            if (data.success) {
              allLeadItems.push(...data.data);
            const  totalFetched = allLeadItems.length;
              const totalAvailable = data.page_count;
   
              if (totalFetched >= totalAvailable) {
                this.downloadExcel(allLeadItems);
                this.loader.close();
                return; 
              }
  
              fetchData(currentOffset + 1); 
            } else {
              this.loader.close();
              Swal.fire(data.data.msg, 'Error');
            }
          },
          err => {
            
            this.loader.close();
            Swal.fire('Error fetching data', 'Error');
          }
        );
      // }, 1000);
      

    };

    fetchData(offset);
  }
  
  private downloadExcel(leadItems: any[]): void {
    const modifiedLeadItems = leadItems.map(item => ({
      'Customer Name':  item.first_name + ' ' +item.last_name ,
      'Mobile No': item.mobile_no,
      'Customer Type': item.customer_type,
      'Vehicle Type': item.vehicle.cat.id == 3 ? "Park & Sell" : "Catalogue",
      'OPTY ID': item.opty_id,
      'Brand': item.brand,
      'LOB': item.lob,
      'Price': item.dealer_suggestive_price,
      'Quantity': item.quantity,
      'Finance Required': item.financing == true ? "Yes" : "No",
      'Sale Invoice Name': item.sale_invoice_name,
      'Created Date': this.datepipe.transform(item.created_at, 'dd-MM-yyyy hh:mm:ss a'),
      'Source of Contact': item.source_of_contact,
      'Intended Application': item.intended_application,
      'Dealer Name': item.vehicle.user.organization_name, 
      "Source": item.utm_source,
      "Medium": item.utm_medium,
      "Campaign": item.utm_campaign,
    }));
  
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(modifiedLeadItems);
    const columnCount = Object.keys(modifiedLeadItems[0]).length;
    const columnWidths = Array(columnCount).fill({ wch: 30 }); 
  
    worksheet['!cols'] = columnWidths;
  
    const workbook: XLSX.WorkBook = {
      Sheets: { 'data': worksheet },
      SheetNames: ['data']
    };
  
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, 'Fleetverse_Leads');
  }
  
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    });
    saveAs(data, `${fileName}_${new Date().getTime()}.xlsx`);
  }
  downloadAllLeadDataAndExcelCatalouge(): void {
    this.loader.open();
    let offset = 0;
    const pageSize = 10; 
    const allLeadItems: any[] = []; 
    const fetchData = (currentOffset: number) => {
      const ListInput = {
        offset: currentOffset,
        size: pageSize,
        cat_id:3
      };
  
      // setTimeout(() => {
        this.commonservice.getLeadList(ListInput).subscribe(
          data => {
            if (data instanceof HttpErrorResponse) {
              this.loader.close();
              Swal.fire('Error fetching data', 'Error');
              return;
            }
  
            if (data.success) {
              allLeadItems.push(...data.data);
            const  totalFetched = allLeadItems.length;
              const totalAvailable = data.page_count;
   
              if (totalFetched >= totalAvailable) {
                this.downloadExcelCatalouge(allLeadItems);
                this.loader.close();
                return; 
              }
  
              fetchData(currentOffset + 1); 
            } else {
              this.loader.close();
              Swal.fire(data.data.msg, 'Error');
            }
          },
          err => {
            
            this.loader.close();
            Swal.fire('Error fetching data', 'Error');
          }
        );
      // }, 1000);
      

    };

    fetchData(offset);
  }
  private downloadExcelCatalouge(leadItems: any[]): void {
    const modifiedLeadItems = leadItems.map(item => ({
      'Customer Name': item.first_name + ' ' +item.last_name ,
      'Mobile No': item.mobile_no,
      'Customer Type': item.customer_type,
      'Brand': item.brand,
      'LOB': item.lob,
      'Price': item.dealer_suggestive_price,
      'Quantity': item.quantity,
      'Funding Required': item.funding_required,
      'Sale Invoice Name': item.sale_invoice_name,
      'Created Date': this.datepipe.transform(item.created_at, 'dd-MM-yyyy hh:mm:ss a'),
      'Source of Contact': item.source_of_contact,
      'Intended Application': item.intended_application,
      'Dealer Name': item.created_by.organization_name, 
    }));
  
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(modifiedLeadItems);
    const columnCount = Object.keys(modifiedLeadItems[0]).length;
    const columnWidths = Array(columnCount).fill({ wch: 30 }); 
  
    worksheet['!cols'] = columnWidths;
  
    const workbook: XLSX.WorkBook = {
      Sheets: { 'data': worksheet },
      SheetNames: ['data']
    };
  
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFileCatalouge(excelBuffer, 'Catalougue_Leads');
  }
  private saveAsExcelFileCatalouge(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    });
    saveAs(data, `${fileName}_${new Date().getTime()}.xlsx`);
  }

  public formatUtmCampaign(utmCampaign: string): string {
    return utmCampaign ? utmCampaign.replace(/_/g, ' ') : '';
  }

}

export class ListInput {

  chassis_number: string;
  vc_number: string;
  lob: string;
  from_date: string;
  to_date: string;
  distributor_code: string;
  customer_type: string;
  offset: number;
  organization_name: string;
  vehicle_application: string
  source_of_contact: string;
  lead_id: string;
  opty_id: string;
  full_name: string;
  last_name: string;
  mobile_no: string;
  state: string;
  district: string;
  city: string;
  // vehicle_application: string;
  size: number;
  lead_source:string;
  cat_id:any
}
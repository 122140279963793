import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from "@angular/core";
import { MatSnackBar, MatSidenav } from "@angular/material";
import { ShopService, CartItem } from "../../../shared/services/shop.service";
import { Product } from "../../../shared/models/product.model";
import { InventoyDataList, InventoryList } from "../../../shared/models/models";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { Subscription, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { egretAnimations } from "../../../shared/animations/egret-animations";
import { AppLoaderService } from "../../../shared/services/app-loader/app-loader.service";
import { InventoryService } from "../../../shared/services/MyServices/inventory.service";
import { InventoryListData } from "../../../shared/models/inventory-list-data";
import { AppConfirmService } from "app/shared/services/app-confirm/app-confirm.service";
import { ApproveData } from "app/shared/models/models";
import { HttpErrorResponse } from "@angular/common/http";
import { CommonService } from "app/shared/services/MyServices/common.service";
import { ActivatedRoute, Router } from "@angular/router";
import { first } from "rxjs/operators";
import Swal from "sweetalert2";
import { DataPassService } from "../../../shared/services/MyServices/data-pass.service";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { AddAuctionComponent } from "../inv-list/add-auction/add-auction.component";
import { element } from "protractor";

@Component({
  selector: "app-inv-list",
  templateUrl: "./inv-list.component.html",
  styleUrls: ["./inv-list.component.scss"],
  animations: egretAnimations,
})
export class InvListComponent implements OnInit , OnDestroy {
  public isSideNavOpen: boolean;
  showMsg: boolean = false;
  public viewMode: string = "list-view";
  public currentPage: any;
  @ViewChild(MatSidenav, { static: false }) private sideNav: MatSidenav;
  @ViewChild('dealer', { static: false }) myInputVariable: ElementRef;
  @ViewChild('shared', { static: false }) inputidFileVariable: ElementRef;
  public InventoyList$: Observable<InventoyDataList[]>;
  public InventoryListData: any[];
  callrefresh:boolean=false;
  FinaldataData = [];
  temp = [];

  public products$: Observable<Product[]>;
  public categories$: Observable<any>;
  public activeCategory: string = "all";
  public filterForm: FormGroup;
  public cart: CartItem[];
  public cartData: any;
  filterFields: boolean = false;
  public ServiceData: any[];
  public Test: any[];
  public SerVivceFinalData: any[];
  InventoryImages = [];
  InventoryCoverImage: [];
  userData:any;
  SessionData: any;
  isMobile: boolean=false;
  isApiCalledmystock:boolean=true;
  isApiCalledmyregion:boolean;
  excludedSubCatNames: string[] = [
    'CV NTML',
    'ISUZU DMAX',
    'M&HCV Const',
    'Magic IRIS',
    'NTML',
    'PCV - Venture',
    'Small Cars',
    'TASL',
  ];
  includedSubCatNames:string[]=[
    'ScPass',
'ICV Trucks',
'Pickups',
'HCV Const',
'HCV Cargo',
'Buses',
'MCV Trucks',
'LCV',
'CV NTML',
'SCVPass',
'SCV Cargo',
  ]
  filterValue :any = {}
  constructor(
    private router: Router,
    private shopService: ShopService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private loader: AppLoaderService,
    private InvenService: InventoryService,
    private confirmService: AppConfirmService,
    private CommonService: CommonService,
    private Passdata: DataPassService,
    public dialog: MatDialog,
  ) {
    // window.scrollTo(0, 0);
    this.config = {
      itemsPerPage: 0,
      currentPage: 0,
      totalItems: 0,
    };
  }

  HideToggleButton: boolean = false
  selectedTab: number;
  isPageUrlCheck: any;
  ngOnInit() {
    this.SessionData = this.CommonService.getUserDetails();
    this.userData = JSON.parse(localStorage.getItem('loginData'))
    var list = JSON.parse(localStorage.getItem('PageDetails'))
    for (let entry of list) {
      if (entry.page_detail.length > 0) {
        for (let entryDetail of entry.page_detail) {
          if (entryDetail.page_url == 'InventoryList') {
            this.isPageUrlCheck = "InventoryList"
          }
        }
      }
    }

    if (this.isPageUrlCheck == "InventoryList") {
      this.route.queryParams.subscribe(params => {
        if (parseInt(params['index']) == 1) {
          this.selectedTab = 0
          this.visiblitydata = "private";
          this.Getdata(0, 0);
        }
        else if (parseInt(params['index']) == 2) {
          this.selectedTab = 1;
          this.visiblitydata = "public";
          this.Getdata(0, 0);
        }
        else {
          this.selectedTab = 0
          this.visiblitydata = "private";
          this.Getdata(0, 0);
        }
      })
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
      let partyType = localStorage.getItem('partyTypeName')
      if (partyType == "Admin") {
        this.HideToggleButton = true;
      }
      else {
        this.HideToggleButton = false;
      }
    }
    else {
      this.router.navigate(["pages/ErrorPage"]);
    }

    this.checkMobileView();
    window.addEventListener('resize', this.checkMobileView.bind(this));

  }
  checkMobileView() {
    this.isMobile = window.matchMedia('(max-width: 768px)').matches;
  }
  visiblitydata: string;
  certifiedType: any;
  Getdata(offset, request) {
    window.scrollTo(0, 0);
    this.loader.open();
    this.FinaldataData = [];

    // const InventoryListDataNew: InventoryListData = {} as InventoryListData;
    this.showMsg = false;
    this.categories$ = this.shopService.getCategories();
    this.buildFilterForm(this.shopService.initialFilters);
    // setTimeout(() => {
    //   this.loader.open();
    //   //this.loader.close();
    // });

    const InventoryListrData: InventoryList = {} as InventoryList;
    InventoryListrData.user_id = parseInt(this.CommonService.getUserCode());
    // InventoryListrData.user_id = 1;
    InventoryListrData.offset = offset;
    // InventoryListrData.lob = "";
    // InventoryListrData.ppl = "";
    InventoryListrData.text_search = (request.text_search) ? request.text_search : '';
    // InventoryListrData.is_portal = true;
    InventoryListrData.visibility = this.visiblitydata;
      InventoryListrData.lob=(this.filterValue.sub_cat_name) ? this.filterValue.sub_cat_name : '';
    InventoryListrData.ppl=(this.filterValue.sub_cat_detail_name) ? this.filterValue.sub_cat_detail_name : '';
    InventoryListrData.brand=(this.filterValue.brand) ? this.filterValue.brand : '';
    InventoryListrData.is_active = this.filterValue.active === null || this.filterValue.active === undefined || this.filterValue.active === '' ? '' : this.filterValue.active ? true : false
    this.InvenService.InventoryList(InventoryListrData).subscribe(
      (data) => {
        if ((data.success = true)) {
          this.callrefresh=false
          this.showMsg = true;
          this.loader.close();
          this.InventoyList$ = data.data;
          this.config.itemsPerPage = data.rangeInfo.limit;
          this.config.offset = data.rangeInfo.offset;
          this.config.totalItems = data.page_count;
          this.ServiceData = data.data;
          let InventoryTitle,
            RegistrationNumber,
            chassisNo,
            NoofOwners,
            FuelType,
            is_cover,
            InventoryshortDescription: string;
          let cat_name,
            sub_cat_name,
            sub_cat_detail_id,
            inventory_status,
            inventory_id,
            approved_status,
            Inv_date,
            DealerCode,
            toDisplay,
            inventory_code: string;
            let params_approve_status:any,control_detail:any,brand:any,price
          for (let entry of this.ServiceData) {
            this.InventoryImages = [];
            this.InventoryCoverImage = [];
            cat_name = entry.cat_name;
            sub_cat_name = entry.sub_cat_name;
            sub_cat_detail_id = entry.sub_cat_detail_name;
            inventory_status = entry.inventory_status;
            inventory_id = entry.inventory_id;
            inventory_code = entry.inventory_code;
            approved_status = entry.approved_status;
            Inv_date = new Date(entry.created_at);
            DealerCode = entry.dealer_code;
            toDisplay = entry.is_active;
            params_approve_status =entry.params_approve_status 
            control_detail=entry.control_detail
            brand = control_detail.filter(entry => entry.element_detail_value === 'Brand');
            brand=brand[0]
            
            price = control_detail.filter(entry => entry.element_detail_value === 'MSRP');
            price = price[0]
            //ShowAddAuction = ShowAddAuction;

            this.InventoryImages = entry.inventory_documents.images;
            if (this.InventoryImages.length > 0) {
              var images = this.InventoryImages.find(
                (x) => x.is_cover === true
              );
              if (images == "" || images == undefined || images == null) {
                var images = this.InventoryImages[0];
                this.InventoryCoverImage = images.path;
              } else {
                this.InventoryCoverImage = images.path;
              }
            } else {
            }

            for (let detail1 of entry.control_detail) {
              if (
                detail1.element_detail_value == "Inventory short Description"
              ) {
                InventoryshortDescription = detail1.control_detail_value;
              }

              if (detail1.element_detail_value == "Chassis No") {
                chassisNo = detail1.control_detail_value;
              }
              if (detail1.element_detail_value == "Veh registration Number") {
                RegistrationNumber = detail1.control_detail_value;
              }
              if (detail1.element_detail_value == "Fuel Type") {
                FuelType = detail1.control_detail_value;
              }
              if (detail1.element_detail_value == "Inventory Title") {
                InventoryTitle = detail1.control_detail_value;
              }
              if (detail1.element_detail_value == "No of Owners") {
                NoofOwners = detail1.control_detail_value;
              }
              if (detail1.element_detail_value == "Vehicle Certificate Type") {
                this.certifiedType = detail1.control_detail_value;
              }
            }

            this.FinaldataData.push(
              this.formData(
                InventoryTitle,
                RegistrationNumber,
                chassisNo,
                NoofOwners,
                FuelType,
                this.InventoryCoverImage,
                InventoryshortDescription,
                cat_name,
                sub_cat_name,
                sub_cat_detail_id,
                inventory_status,
                inventory_id,
                approved_status,
                Inv_date,
                DealerCode,
                toDisplay,
                inventory_code,
                entry.is_active
                ,params_approve_status,
                control_detail,
                brand,price
              )
            );
            this.temp = this.FinaldataData;
          }
        } else {
          this.callrefresh=false
          this.showMsg = true;
          this.loader.close();
        }
      },
      (error) => {
        this.showMsg = true;
        this.loader.close();
        Swal.fire(error.error.data.msg);
        console.log(error);
      }
    );

    // this.products$ = this.shopService
    //   .getFilteredProduct(this.filterForm)
    //   .pipe(
    //     map(products => {
    //       this.loader.close();
    //       return products;
    //     })
    //   );
    // this.getCart();
    // this.cartData = this.shopService.cartData;
  }


  formData(
    InventoryTitle,
    RegistrationNumber,
    chassisNo,
    NoofOwners,
    FuelType,
    is_cover,
    InventoryshortDescription,
    cat_name,
    sub_cat_name,
    sub_cat_detail_id,
    inventory_status,
    inventory_id,
    approved_status,
    Inv_date,
    DealerCode,
    toDisplay,
    inventory_code,
    is_active,
    params_approve_status,
    control_detail,brand,price
  ): InventoryListData {
    const InventoryListData: InventoryListData = {} as InventoryListData;
    InventoryListData.InventoryTitle = InventoryTitle;
    InventoryListData.RegistrationNumber = RegistrationNumber;
    InventoryListData.chassisNo = chassisNo;
    InventoryListData.NoofOwners = NoofOwners;
    InventoryListData.FuelType = FuelType;
    InventoryListData.is_cover = is_cover;
    InventoryListData.InventoryshortDescription = InventoryshortDescription;

    InventoryListData.cat_name = cat_name;
    InventoryListData.sub_cat_name = sub_cat_name;
    InventoryListData.sub_cat_detail_id = sub_cat_detail_id;
    InventoryListData.inventory_status = inventory_status;
    InventoryListData.inventory_id = inventory_id;
    InventoryListData.inventory_code = inventory_code;

    InventoryListData.approved_status = approved_status;
    InventoryListData.Inv_date = Inv_date;
    InventoryListData.DealerCode = DealerCode;
    InventoryListData.toDisplay = toDisplay;
    InventoryListData.is_active = is_active;
    InventoryListData.params_approve_status = params_approve_status;
     InventoryListData.control_detail = control_detail;
     InventoryListData.brand = brand;
     InventoryListData.price = price;
    // InventoryListData.ShowAddAuction = ShowAddAuction;
    return InventoryListData;
  }

  getCart() {
    this.shopService.getCart().subscribe((cart) => {
      this.cart = cart;
    });
  }
  addToCart(product) {
    let cartItem: CartItem = {
      product: product,
      data: {
        quantity: 1,
      },
    };
    this.shopService.addToCart(cartItem).subscribe((cart) => {
      this.cart = cart;
      this.snackBar.open("Product added to cart", "OK", { duration: 4000 });
    });
  }

  buildFilterForm(filterData: any = {}) {
    this.filterForm = this.fb.group({
      search: [""],
      category: ["all"],
      minPrice: [filterData.minPrice],
      maxPrice: [filterData.maxPrice],
      minRating: [filterData.minRating],
      maxRating: [filterData.maxRating],
    });
  }

  ViewInventory(data: any = {}, isNew?) {
    console.log(data.params_approve_status);
    localStorage.removeItem("InventoryId");
    this.EditInventoryData(data.inventory_id, isNew,data.params_approve_status);
    //localStorage.removeItem("InventoryId");
    localStorage.setItem("InventoryId", data.inventory_id);
    localStorage.removeItem("InventoryDetail");
    localStorage.setItem("InventoryDetail", JSON.stringify(data));
    localStorage.removeItem("InventoryStatus");
    localStorage.setItem("InventoryStatus", data.inventory_status);
    localStorage.removeItem("approved_status");
    localStorage.setItem("approved_status", data.approved_status);
    //this.router.navigate(["pages/InventoryMaster/"]);
    // this.Passdata.setStockData(data);
  }

  EditInventoryData(InventoryID, isNew,approval_status) {
    this.Passdata.resetOption();
    const objData: ApproveData = {} as ApproveData;
    objData.id = InventoryID;
    this.InvenService.ViewInventoryById(objData).subscribe(res => {
      if (res instanceof HttpErrorResponse) {
        return;
      }
      if (res.success) {
        this.Passdata.setOption(res.data);
        if (isNew == "dealer")
          this.router.navigate(["pages/InventoryMaster/"], { queryParams: { isDealer: true ,approval_status :approval_status === 'APPROVED' ? true : false} });
        else {
          this.router.navigate(["pages/InventoryMaster/"], { queryParams: { isDealer: false ,approval_status :approval_status === 'APPROVED' ? true : false } });
        }
      } else {
        Swal.fire(res.data.msg, "Error");
      }
    });
    // this.InvenService.ViewInventoryById(objData).subscribe((data) => {
    //   if (data instanceof HttpErrorResponse) {
    //     return;
    //   }
    //   if (data.success) {
    //     this.Passdata.setOption(data.data);

    //     this.router.navigate(["pages/InventoryMaster/"]);
    //   } else {
    //     Swal.fire(data.data.msg, "Error");
    //   }
    // });
  }

  // // ApproveInventory(data: any = {}, isNew?) {

  // //   // alert(data);
  // //   this.confirmService.confirm({ message: `Approve this Inventory?` })
  // //     .subscribe(res => {
  // //       if (res) {
  // //         const objData: ApproveData = {} as ApproveData;
  // //         objData.id = data.toString();
  // //         // alert(Id)
  // //         this.InvenService.ApproveInventory(objData)
  // //           .subscribe(data => {
  // //             if (data instanceof HttpErrorResponse) {
  // //               return;
  // //             }
  // //             if (data.success = true) {
  // //               this.Getdata(0);
  // //               Swal.fire('Inventory Approved Successfully!', 'success');

  // //             }
  // //             else {
  // //               Swal.fire(data.data.msg, 'Error')
  // //             }
  // //           });
  // //       }
  // //     })
  // // }

  // updateFilter(event) {
  //   const val = event.target.value.toLowerCase();
  //   var columns = Object.keys(this.temp[0]);
  //   // Removes last "$$index" from "column"
  //   columns.splice(columns.length - 1);
  //   if (!columns.length) return;
  //   const rows = this.temp.filter(function (d) {
  //     for (let i = 0; i <= columns.length; i++) {
  //       let column = columns[i];
  //       if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
  //         return true;
  //       }
  //     }
  //   });

  //   this.FinaldataData = rows;
  // }

  setActiveCategory(category) {
    this.activeCategory = category;
    this.filterForm.controls["category"].setValue(category);
  }

  toggleSideNav() {
    this.sideNav.opened = !this.sideNav.opened;
  }

  ViewAuction(data: any = {}, isNew?) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = true;
    dialogConfig.data = data;
    dialogConfig.disableClose = false;
    let dialogRef = this.dialog.open(AddAuctionComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => { });
  }
  config: any;
  off: number;
  pageChanged(event) {
    document.body.scrollTop = 0;
    var offset = event;
    this.off = event;
    if (offset > 0) {
      offset = offset - 1;
    }

    const InputSearch: InputData = {} as InputData;
    if (this.search) { InputSearch.text_search = this.search; } else { InputSearch.text_search = ""; }
    this.config.currentPage = event;
    this.Getdata(offset, InputSearch);
  }


  TabReader(index) {
    this.isApiCalledmyregion=false;
    this.isApiCalledmystock=false;
    if (index == 1) {
      this.isApiCalledmyregion=true;
      // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      // this.router.onSameUrlNavigation = 'reload';
      // this.router.navigate(["pages/InventoryList/"], { queryParams: { index: 1 } });
      this.inputidFileVariable.nativeElement.value = '';
      this.visiblitydata = "public";
      this.FinaldataData = [];
      this.config.currentPage = 0
      this.filterValue={}
    this.popupHandler()
      this.Getdata(0, 0);
    } else {
      this.isApiCalledmystock=true;
      this.myInputVariable.nativeElement.value = '';
      this.visiblitydata = "private";
      this.FinaldataData = [];
      this.config.currentPage = 0
      this.filterValue={}
    this.popupHandler()
      this.Getdata(0, 0);
    }
  }
  popupHandler() {
    this.filterFields = !this.filterFields;
  }
  CheckedLable: any;
  InventoryID: any;
  updateFunc(e, data) {
    if (e.checked == true) {
      this.CheckedLable = data.toDisplay;
      this.InventoryID = data.inventory_id;
      this.ActiveDeactiveInventory();
    } else {
      this.CheckedLable = data.toDisplay;
      this.InventoryID = data.inventory_id;
      this.ActiveDeactiveInventory();
    }
  }

  ActiveDeactiveInventory() {
    let json = { id: this.InventoryID };
    this.CommonService.ActiveDeactiveInventory(json)
      .pipe()
      .subscribe(
        (res) => {
          if (res instanceof HttpErrorResponse) {
            return;
          }
          if (res.success) {
            var Data = res.data;
            this.Getdata(0, 0);
          } else {
            const errorMessage = res;
            console.log(errorMessage);
            return;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  is_active: boolean = true;
  text_search: any;
  onSearchFilter(event: any) {
    if (event.key === "Enter") {
      this.text_search = event.target.value;
      let request =
      {
        "is_portal": true,
        // "lob": "",
        // "ppl": "",
        "text_search": event.target.value
      }
      this.Getdata(0, request)
      // const ListInput: inputAll = {} as inputAll;

      // if (this.text_search) { ListInput.text_search = this.text_search; } else { ListInput.text_search = ""; }
      // ListInput.is_portal = true
      // this.Getdata(0, ListInput)
    }


  }

  onFocusOutSearchFilter(searchValue: string) {
    let request =
    {
      // "is_portal": true,
      // "lob": "",
      // "ppl": "",
      "text_search": searchValue
    }
    this.Getdata(0, request)
  }

  titleSearch: any;
  search: any;

  searchInventory() {
    this.search = this.titleSearch;
    this.config.currentPage = 1;
    var a = this.off
    let request =
    {
      // "is_portal": true,
      // "lob": "",
      // "ppl": "",
      "text_search": this.search
    }
    this.Getdata(0, request)
  }

  resetInventory() {
    this.titleSearch = ""
    this.search = "";
    this.config.currentPage = 1;
    // console.log(this.titleSearch)
    // let request =
    // {
    //   // "is_portal": true,
    //   // "lob": "",
    //   // "ppl": "",
    //   "text_search": ""
    // }
    // this.Getdata(0, request)
    this.Getdata(0, 0);
  }

  Approve(flag, rowData) {
    const control_detail = rowData.control_detail.filter((element)=> element.element_detail_value == 'MSRP');    
    const price = Number(control_detail[0].control_detail_value)
    if (price > 0) {
      this.confirmService
      .confirm({ message: ` Are you sure you want to Initiate this vehicle ?` })
      .subscribe((data) => {
        if (data) {
          var json = {
            "inventory_code": rowData.inventory_code,
            "params_approval_status": flag,
          }

          this.loader.open();
          this.CommonService.approveCall(json).subscribe(
            (res) => {
              this.loader.close();
              if (res.success) {
                // Swal.fire(res.data.msg);
                Swal.fire("Vehicle Initiated Successfully");
                const ResponseData: InputData = {} as InputData;
                ResponseData.offset = 0;
                ResponseData.expired = false;
                ResponseData.visibility = this.visiblitydata;
                // this.getInventoryList(ResponseData);
                this.Getdata(0, 0);
              } else {
                const errorMessage = res;
                Swal.fire(res.error.data.msg)
                // return;
              }
            },
            (err) => {
              this.loader.close();
              console.log("error occured", err);
            }
          );

        }
      });
    }
    else{
      Swal.fire("Price not updated in CRM, please update in CRM to proceed")
    }
  

  } 

  DisApprove(flag, rowData) {
    this.confirmService
      .confirm({ message: ` Are you sure you want to DisApprove this vehicle ?` })
      .subscribe((data) => {
        if (data) {
          var json = {
            "vehicle_id": rowData.id,
            "params_approval_status": flag,
          }

          this.loader.open();
          this.CommonService.approveCall(json).subscribe(
            (res) => {
              this.loader.close();
              if (res.success) {
                // Swal.fire(res.data.msg);
                Swal.fire("Vehicle Disapprove Successfully");
                const ResponseData: InputData = {} as InputData;
                ResponseData.offset = 0;
                ResponseData.expired = false;
                ResponseData.visibility = this.visiblitydata;
                // this.getInventoryList(ResponseData);
                this.Getdata(0, 0);
              } else {
                const errorMessage = res;
                Swal.fire(res.error.data.msg)
                // return;
              }
            },
            (err) => {
              this.loader.close();
              console.log("error occured", err);
            }
          );

        }
      });

  }
  formControlValue(req) {
    this.filterValue={}
    this.filterValue=req
    if (req == "reset") {
      this.filterValue={}
      this.Getdata(0,0);
    }
    else
    {
this.Getdata(0,0)
    }

  }
  refreshListing(){
    this.callrefresh=true
    this.filterValue={}
    this.config.currentPage = 0
    this.Getdata(0,0)
  }
  ngOnDestroy(): void {
    window.removeEventListener('resize', this.checkMobileView.bind(this));
  }
}

export class inputAll {
  text_search: string;
  is_portal: boolean;
}

export class InputData {
  text_search: string;
  user_id: any;
  offset: number;
  visibility: string;
  state: string;
  max_odometer: number;
  min_odometer: number;
  city: string;
  district: string;
  minimum_budget: number;
  no_of_owners: string;
  region: string;
  expired: boolean;
  vehicle_category: string;
  vehicle_type: string;
  finance_availability: string;
  engine_warranty: string;
  emission: string;
}

import { Component, EventEmitter, OnInit, Output, VERSION, ViewChild, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonService } from 'app/shared/services/MyServices/common.service';
import { SubCategoryDetails } from 'app/shared/models/models';
import Swal from 'sweetalert2';
import { FileUploadService } from 'app/file-upload/file-upload.service';
import { AppConfirmService } from 'app/shared/services/app-confirm/app-confirm.service';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit {
  tempForm: FormGroup;
  searchValue: string = '';
  sub = new Subscription
  public breakpoint: number; // Breakpoint observer code
  public fname: string = `Ramesh`;
  public lname: string = `Suresh`;
  public addProductForm: FormGroup;
  wasFormChanged = false;
  addList: any;
  inventoryArray: any;
  cat_id: string;
  InventoryArray: any;
  sub_cat_id: string;
  SubcategoryArray: any;
  bindControlArray: any;
  @Output() saveClicked = new EventEmitter<any>();
  selectedFiles: any;
  previews: any[];
  stateArray: any = [];
  districtArray: any;
  isDoc: boolean = false;
  ImagePositionTypeList = [
    { name: "Cover Image", value: "cover_image", isSelected: false, position: NaN },
    { name: "Front", value: "front", isSelected: false, position: NaN },
    { name: "Back", value: "back", isSelected: false, position: NaN },
    { name: "LHS", value: "lhs", isSelected: false, position: NaN },
    { name: "RHS", value: "rhs", isSelected: false, position: NaN },
    { name: "Cabin", value: "inside_cabin", isSelected: false, position: NaN },
    { name: "Speedometer", value: "speedometer", isSelected: false, position: NaN },
    { name: "Chassis", value: "chassis", isSelected: false, position: NaN },
    { name: "Front right tyre", value: "front_right_tyre", isSelected: false, position: NaN },
    { name: "Front left tyre", value: "front_left_tyre", isSelected: false, position: NaN },
    { name: "Rear right tyre", value: "rear_right_tyre", isSelected: false, position: NaN },
    { name: "Rear left tyre", value: "rear_left_tyre", isSelected: false, position: NaN },
    { name: "Tyre 5", value: "tyre_5", isSelected: false, position: NaN },
    { name: "Tyre 6", value: "tyre_6", isSelected: false, position: NaN },
    { name: "Tyre 7", value: "tyre_7", isSelected: false, position: NaN },
    { name: "Tyre 8", value: "tyre_8", isSelected: false, position: NaN },
    { name: "Tyre 9", value: "tyre_9", isSelected: false, position: NaN },
    { name: "Tyre 10", value: "tyre_10", isSelected: false, position: NaN },
    { name: "Tyre 11", value: "tyre_11", isSelected: false, position: NaN },
    { name: "Tyre 12", value: "tyre_12", isSelected: false, position: NaN },
    { name: "Spare Tyre", value: "spare", isSelected: false, position: NaN },
  ]
  isCoverImage: boolean = true;
  imgFile: null;
  insuranceImg: { file: File; srcPath: any; fileExtention: any; id: any }
  rcImg: { file: File; srcPath: any; fileExtention: any; id: any }
  pucImg: { file: File; srcPath: any; fileExtention: any; id: any }
  permitImg: { file: File; srcPath: any; fileExtention: any; id: any }
  fitnessImg: { file: File; srcPath: any; fileExtention: any; id: any }
  nofOwners = [
    "First-Owner",
    "Second-Owner",
    "Third-Owner",
    "Four-Owner",
    "Fifth-Owner",
    "Six-Owner",
    "Seven-Owner",
    "Eight-Owner",
    "Nine-Owner"
  ];
  fuelType = [
    "CNG",
    "Petrol",
    "LPG",
    "Diesel",
  ]
  emissionNorms = [
    { value: 'BS3', display: 'BS III' },
    { value: 'BS4', display: 'BS IV' },
    { value: 'BS6', display: 'BS VI' }
  ];
  loadBodyData = [
    "Full Deck- Steel",
    "Container- Steel",
    "Half Body Wooden",
    "Full Body Wooden- Punjab",
    "Flat Platform",
    "Goods Carrier",
    "SS Tanker",
    "Water Tanker",
    "MS Tanker",
    "20' Flat Bed Trailer",
    "40' Flat Bed Trailer",
    "Car Carrier",
    "Open Cargo Body",
    "Scooter Carrier",
    "RMC/Transit Mixer",
    "16 CuM Tipper",
    "18 CuM Tipper",
    "10 CuM Tipper",
    "Cargo High Deck",
    "Passenger- 8 Seater",
    "Passenger- 5 Seater",
    "Tipper Body",
    "Bus-Passengers",
    "40' Trailer/Trolley",
    "40' +Trailer/Trolley",
    "Angle Body",
    "Half Deck- Steel",
    "Full Deck- Steel",
    "Container- Steel",
    "Half Body Wooden",
    "Full Body Wooden- Punjab",
    "Flat Platform",
    "Goods Carrier",
    "SS Tanker",
    "Water Tanker",
    "MS Tanker",
    "20' Flat Bed Trailer",
    "40' Flat Bed Trailer",
    "Car Carrier",
    "Open Cargo Body",
    "Scooter Carrier",
    "RMC/Transit Mixer",
    "16 CuM Tipper",
    "18 CuM Tipper",
    "10 CuM Tipper",
    "Cargo High Deck",
    "Passenger- 8 Seater",
    "Passenger- 5 Seater",
    "Tipper Body",
    "Bus-Passengers",
    "40' Trailer/Trolley",
    "40' +Trailer/Trolley"
  ]
  applicationData: any;
  brandsData: any;
  maxDate: any;
  minDate: any;
  selectedExtendDate: Date;
  brandsDataArray = [
    { name: "TML" },
    { name: "NTML" }
  ]
  modelData: any = [];
  vehicleDocumentLength: number = 0;
  loginData: any;
  onLoad: boolean = false;
  lob:any;
  ppl:any;
  setFormData:any=[]
  constructor(
    private fb: FormBuilder,
    public dialog: MatDialogRef<AddProductComponent>,
    private commonservices: CommonService,
    private fileService: FileUploadService,
    private confirmService: AppConfirmService, @Inject(MAT_DIALOG_DATA) public data,
    private loader: AppLoaderService,
    private datePipe: DatePipe
  ) { }

  public ngOnInit(): void {

    this.loginData = JSON.parse(localStorage.getItem('loginData'));
    if (this.data.flag == "EXTEND") {

      const today = new Date();
      const currentDate = this.datePipe.transform(today, 'yyyy-MM-dd');
      this.maxDate = new Date();
      // this.minDate = currentDate;
      this.minDate = new Date();
      this.minDate.setDate(today.getDate() + this.data.daysLeft + 1);
      this.maxDate.setDate(today.getDate() + 30);
      this.maxDate = this.datePipe.transform(this.maxDate, 'yyyy-MM-dd');
    }
    else {
      this.BindInventory();
      this.onstate();
      this.getApplicationData();
      // this.getBrandsData();
      if (this.data.flag == 'EDIT') {
        this.getModelData(this.data.data.sub_cat.sub_cat_name,this.data.data.sub_cat_detail.name); 
      }
      this.addProductForm = this.fb.group({
        InventoryType: ['', [Validators.required]],
        category: ['', [Validators.required]],
        Subcategory: ['', [Validators.required]],
        kms: [this.data.flag == 'EDIT' ? this.data.data.odometer : '', [Validators.required]],
        brand: [this.data.flag == 'EDIT' ? this.data.data.brand : '', [Validators.required]],
        ownership: [this.data.flag == 'EDIT' ? this.data.data.no_of_owners : '', [Validators.required]],
        regno: [this.data.flag == 'EDIT' ? this.data.data.veh_registration_number : '', [Validators.required, Validators.minLength(9),Validators.maxLength(11),]],
        application: [this.data.flag == 'EDIT' ? this.data.data.application : '', [Validators.required]],
        model: [this.data.flag == 'EDIT' ? this.data.data.model : '', [Validators.required]],
        loadbody: [this.data.flag == 'EDIT' ? this.data.data.body_type : '', [Validators.required]],
        mft: [this.data.flag == 'EDIT' ? this.data.data.year_of_manufacture : '', [Validators.required, Validators.minLength(4)]],
        state: [this.data.flag == 'EDIT' ? this.data.data.state : '', [Validators.required]],
        district: [this.data.flag == 'EDIT' ? this.data.data.district : '', [Validators.required]],
        fuel_type: [this.data.flag == 'EDIT' ? this.data.data.fuel_type : '', [Validators.required]],
        visibility: [this.data.flag == 'EDIT' ? this.data.data.visibility : 'Dealer Only', [Validators.required]],
        chassis_no: [this.data.flag == 'EDIT' ? this.data.data.chassis_no : '',[Validators.minLength(16)]],
        imgFile: [null],
        selectedUploadType: ["vehicle"],
        InventoryUploadData: this.fb.array([]),
        vehicleType: [this.data.flag == 'EDIT' ? this.data.data.vehicle_certificate_type : '', [Validators.required]],
        finance: [this.data.flag == 'EDIT' ? this.data.data.finance_availability : '', [Validators.required]],
        warranty: [this.data.flag == 'EDIT' ? this.data.data.engine_warranty : '', [Validators.required]],
        emission: [this.data.flag == 'EDIT' ? this.data.data.emission : '', [Validators.required]],
        price: [this.data.flag == 'EDIT' ? this.data.data.dealer_suggestive_price : '', [Validators.required]],
      });

      if (this.data.flag == "EDIT") {
        this.onSelectInventory(this.data.data.cat.id, "tsFile");
        this.onSelectcategory(this.data.data.sub_cat.id, "tsFile");
        // this.getModelData();
        if (this.data.data.visibility == "") {
          this.addProductForm.controls.visibility.setValue("region");
        }
        for (let i = 0; i < this.data.data.vehicle_documents.length; i++) {
          // this.BindControlArray(this.data.data.vehicle_documents[i].path)
          // if (this.data.data.vehicle_documents[i].file_type == "image") {
          var position = this.data.data.vehicle_documents[i].position;
          var name = this.data.data.vehicle_documents[i].name;
          if (position == null && name == null) {
            position = "cover_image";
          } else if (name == 'Insurance') {
            this.insuranceImg = {
              file: this.data.data.vehicle_documents[i].path,
              srcPath: this.data.data.vehicle_documents[i].path,
              fileExtention: this.data.data.vehicle_documents[i].extension,
              id: i
            }
          }
          else if (name == 'RC') {
            this.rcImg = {
              file: this.data.data.vehicle_documents[i].path,
              srcPath: this.data.data.vehicle_documents[i].path,
              fileExtention: this.data.data.vehicle_documents[i].extension,
              id: i
            }
          }
          else if (name == 'PUC') {
            this.pucImg = {
              file: this.data.data.vehicle_documents[i].path,
              srcPath: this.data.data.vehicle_documents[i].path,
              fileExtention: this.data.data.vehicle_documents[i].extension,
              id: i
            }
          }
          else if (name == 'Permit_validity') {
            this.permitImg = {
              file: this.data.data.vehicle_documents[i].path,
              srcPath: this.data.data.vehicle_documents[i].path,
              fileExtention: this.data.data.vehicle_documents[i].extension,
              id: i
            }
          }
          else if (name == 'Fitness') {
            this.fitnessImg = {
              file: this.data.data.vehicle_documents[i].path,
              srcPath: this.data.data.vehicle_documents[i].path,
              fileExtention: this.data.data.vehicle_documents[i].extension,
              id: i
            }
          }

          // this.EditFile = this.data.data.vehicle_documents[i].path;
          if (position != null) {
            this.vehicleDocumentLength += 1;
            // console.log("disabled ==== ", this.vehicleDocumentLength);
            this.getmultipleControl.push(
              this.bindValueArray(this.data.data.vehicle_documents[i].path, this.data.data.vehicle_documents[i].path, position, "OLD")
            );
          }
          // }
        }
      }
    }

    this.breakpoint = window.innerWidth <= 600 ? 1 : 2; // Breakpoint observer code
  }

  RegistrationNo() {
    // return "/[^% 0-9a-zA-Z0-9]*/g";
    // return "^[a-zA-Z]{2}[0-9]{2}[a-zA-Z]{2}[0-9]{4}$";
       return "^[a-zA-Z]{2}[0-9]{2}[a-zA-Z]{1,2}[0-9]{4}$"
  }
  Chasisregx() {
    // MAT483534AYH0044
    return "^[a-zA-Z]{3}[0-9]{6}[a-zA-Z]{3}[0-9]{4}$";
    // return "^[a-zA-Z0-9]*$"; //number and alphabates only
  }
  isImgPositionDup() {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.controls
        .map(control => control.value);
      const names = totalSelected.map(value => value.ImagePositionType)
      const hasDuplicate = names.some(
        (ImagePositionType, index) => names.indexOf(ImagePositionType, index + 1) != -1
      );
      return hasDuplicate ? { duplicate: true } : null;
    }
    return validator;
  }
  getApplicationData() {
    let data = {
      "sub_lob": "ALL"
    }
    this.sub.add(this.commonservices.getAllApplications(data).subscribe({
      next: (res: any) => {
        this.applicationData = res.data;
      },
      error: () => { },
      complete: () => { }
    }))
  }

  restrictZero(event, value) {
    if (value.length === 0 && event.charCode == 48) {
      return false;
    }
    else {
      return true;
    }
  }
  getBrandsData() {
    let data = {
      "dropdown_type": "vehicle_brand",
      "vehicle_category": "",
      "vehicle_model": "",
      "vehicle_brand": ""
    }
    this.sub.add(this.commonservices.getAllBrandMaster(data).subscribe({
      next: (res: any) => {
        this.brandsData = res.data;
      },
      error: () => { },
      complete: () => { }
    }))
  }
  getModelData(lob:any,ppl:any,flag?) {
    let data = {
      // "lob":this.data.flag == "EDIT" ? this.data.data.sub_cat.sub_cat_name : this.lob,
      // "ppl":this.data.flag == "EDIT" ? this.data.data.sub_cat_detail.name : this.ppl,
      "lob":lob,
      "ppl":ppl,
      "offset": 0,
      "limit": 10,
      "search":"",
    }
    this.sub.add(this.commonservices.getModelList(data).subscribe({
      next: (res: any) => {
        if (res.success) {
          this.modelData = res.data.models;
          // console.log(this.modelData);
          // console.log(this.data.data);
          
          const modelValue = this.addProductForm.controls.model.value;
          if (this.modelData.length > 0) {
            const isModelValueInData = this.modelData.some((model: any) => model === (modelValue && this.data.data.model));
            if (!isModelValueInData) {
              this.addProductForm.controls.model.setValue('');
            }
            if (flag == 'set') {
              const isModelValueInData1 = this.modelData.some((model: any) => model == this.setFormData.DESC_TEXT_s);
            
              
            if (!isModelValueInData1) {
              // this.addProductForm.controls.model.setValue('');
              if (this.setFormData.DESC_TEXT_s) {
              this.modelData.push(this.setFormData.DESC_TEXT_s)
              this.addProductForm.controls["model"].setValue(this.setFormData.DESC_TEXT_s)
              }
            }
            if (isModelValueInData1) {
              if (this.setFormData.DESC_TEXT_s) {
                // this.modelData.push(this.setFormData.DESC_TEXT_s)
                this.addProductForm.controls["model"].setValue(this.setFormData.DESC_TEXT_s)
                }
            }
            }
          }
          else
          {
            this.addProductForm.controls.model.setValue('');
          } 
        }
        else{
          this.modelData=[];
          this.addProductForm.controls.model.setValue('');
        }
      },
      error: () => { },
      complete: () => { }
    }))
  }
  BindControlArray(data) {
    this.addProductForm["InventoryUploadData"] = [];
    // for (let file of data) {
    var position = "";
    let reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = (e: any) => {
      //this.urls.push(e.target.result);

      if (this.getmultipleControl.controls.length > 20 && this.onLoad) {
        this.onLoad = false;
        Swal.fire("Images are uploaded more than image positions.");
        return;
      } else {
        this.getmultipleControl.push(this.bindValueArray(data, e.target.result, position, "NEW"));
      }
      // this.getmultipleControl.push(this.bindValueArray(data, e.target.result, position, "NEW"));
    };
    // }
  }
  bindValueArray(FileName, Base64, Postion, ImageType) {
    return this.fb.group({
      FileImageSrc: [Base64,],
      FileName: [FileName],
      ImagePositionType: [Postion || ""],
      ImageType: [ImageType],
    });
  }
  get getmultipleControl() {
    return <FormArray>this.addProductForm.get("InventoryUploadData");
  }

  checkValidation(index) {
    this.getmultipleControl.controls.forEach((x, i) => {
      (x as FormGroup).get('ImagePositionType').updateValueAndValidity()
    })
  }
  duplicateImgPosValidation(index) {
    const control = <FormArray>(
      this.addProductForm.controls["InventoryUploadData"]);
    // for (let i = 0; i < control.controls.length; i++) {
    let FormArray = this.addProductForm.get('InventoryUploadData') as FormArray;
    for (let j = 0; j < control.controls.length; j++) {
      if (index != j) {
        if (control.controls[index].value.ImagePositionType == control.controls[j].value.ImagePositionType) {
          // control.controls[index].setValue(null);
          if (control.controls[j].value.FileImageSrc) {
            Swal.fire('Duplicate image position is not allowed.');
            return;
          }
          else {
            FormArray.removeAt(j);
            return;
          }
        }
      }
    }
    // }
  }
  removeGroup(i: number) {
    this.confirmService
      .confirm({ message: ` Are you sure you want to remove this image ?` })
      .subscribe((data) => {
        if (data) {
          // this.selectedFiles.FileList.splice(i,1);
          // this.addProductForm.controls['imgFile'].setValue(null);
          const control = <FormArray>(
            this.addProductForm.controls["InventoryUploadData"]);
          var filename = control.value[i].FileName;
          var ImageType = control.value[i].ImageType;
          let FormArray = this.addProductForm.get('InventoryUploadData') as FormArray;
          // console.log(this.getmultipleControl);
          (<HTMLInputElement>document.getElementById('idVehicleImg')).value = "";
          // return;
          if (this.data.flag == 'EDIT') {
            if (this.getmultipleControl.value[i].ImagePositionType == 'cover_image') {
              this.vehicleDocumentLength -= 1;
              FormArray.removeAt(i);
            }
            else {
              FormArray.at(i).patchValue({
                FileImageSrc: "",
                FileName: "",
                ImageType: "",
                ImagePositionType: this.getmultipleControl.value[i].ImagePositionType
              });
            }
          }
          else {
            FormArray.removeAt(i);
          }
          // this.getmultipleControl.controls[i]["FileImageSrc"].setValue("");
          // this.getmultipleControl.controls[i]["FileImageSrc"].setValue("");
          // this.getmultipleControl.controls[i]["FileName"].setValue("");
          // this.getmultipleControl.controls[i]["ImageType"].setValue("");
          // console.log(this.getmultipleControl.value[i].ImagePositionType);
          // FormArray.removeAt(i);
          Swal.fire("Image removed Successfully");
          // if (this.InventoryID && ImageType !== "NEW") {
          //   filename = filename.replace("https://epcfs-sink.s3.amazonaws.com/", "");
          //   var newfilename = filename.split("?");
          //   const objData: InventoryImage = {} as InventoryImage;
          //   objData.inventory_id = parseInt(this.InventoryID);
          //   objData.file_path = newfilename[0];
          //   this.InvenService.RemoveInventoryById(objData).pipe(first()).subscribe((res) => {
          //     if (res instanceof HttpErrorResponse) {
          //       return;
          //     }
          //     if (res.success) {
          //       Swal.fire("Image removed Successfully");
          //     } else {
          //       Swal.fire(res.data.msg, "Error");
          //     }
          //   },
          //     (error) => {
          //       Swal.fire(error.error.data.msg, "Error");
          //     }
          //   );
          //   control.removeAt(i);
          // }
          // else {
          //   control.removeAt(i);
          //   Swal.fire("Image Removed Successfully");
          // }
        }
      });
  }

  submit() {
    if (this.data.flag == "EXTEND") {
      if (this.selectedExtendDate) {
        this.UpdateVehicleValidity("extend", this.data.data);
      }
      else {
        Swal.fire("Please select date.")
      }
    }
    else {
      // console.log(this.getmultipleControl.controls);return;
      if (this.addProductForm.value.chassis_no != '' && this.addProductForm.value.chassis_no != null) {
        if (this.addProductForm.value.chassis_no.length > 24) {
          Swal.fire("Chassis number is invalid.");
          return;
        }
        // var pattern = new RegExp("^[a-zA-Z]{3}[0-9]{6}[a-zA-Z]{3}[0-9]{4}$");
        var pattern = new RegExp("^[a-zA-Z]{3}[0-9]{6}[a-zA-Z]{3}[0-9]{4,5}$");
        var result = pattern.test(this.addProductForm.value.chassis_no);

        // if (!result) {
        //   Swal.fire("Please add proper chassis number like XYZ123456ABC12345.");
        //   return;
        // }
      }
      const objData: InventoryRequest = {} as InventoryRequest;
      if (this.addProductForm.invalid) {
        Swal.fire("Please add all mandatory fields.");
        return;
      }
      if (this.addProductForm.get("InventoryUploadData").value.length == 0) {
        Swal.fire("Please Upload Vehicle Image with their position type");
        return;
      }
      const control = <FormArray>(
        this.addProductForm.controls["InventoryUploadData"]);
      for (let j = 0; j < control.controls.length; j++) {
        if (control.controls[j].value.ImagePositionType == "") {
          Swal.fire('Please select all image positions.');
          return;
        }
      }
      let temp = []
      temp = control.controls.filter((item: any) => item.controls.ImagePositionType.value == "cover_image")

      if (temp.length == 0) {
        Swal.fire('Cover image is mandatory.');
        return;
      }
      let FormArray = this.addProductForm.get('InventoryUploadData') as FormArray;

      for (let i = 0; i < control.controls.length; i++) {
        // if (control.controls[i].value.ImagePositionType != "cover_image") {
        //   Swal.fire('Please select all image positions.');
        //   return;
        // }
        for (let j = i + 1; j < control.controls.length; j++) {
          if (i != j) {
            if (control.controls[i].value.ImagePositionType == control.controls[j].value.ImagePositionType) {
              // if (control.controls[j].value.FileImageSrc) {
              Swal.fire('Duplicate image position is not allowed.');
              return;
              // }
              // else {
              //   FormArray.removeAt(i);
              //   return;
              // }
            }
          }
        }
      }


      // let prodata = {
      objData.cat_id = this.addProductForm.value.InventoryType;
      objData.sub_cat_id = this.addProductForm.value.category;
      objData.sub_cat_detail_id = this.addProductForm.value.Subcategory;
      objData.odometer = this.addProductForm.value.kms;
      objData.brand = this.addProductForm.value.brand;
      objData.no_of_owners = this.addProductForm.value.ownership;
      objData.veh_registration_number = this.addProductForm.value.regno.toUpperCase();
      objData.fuel_type = this.addProductForm.value.fuel_type;
      objData.application = this.addProductForm.value.application;
      objData.body_type = this.addProductForm.value.loadbody;
      objData.year_of_manufacture = this.addProductForm.value.mft;
      objData.state = this.addProductForm.value.state;
      objData.district = this.addProductForm.value.district;
      objData.model = this.addProductForm.value.model;
      objData.vehicle_certificate_type = this.addProductForm.value.vehicleType;
      objData.finance_availability = this.addProductForm.value.finance;
      objData.engine_warranty = this.addProductForm.value.warranty;
      objData.emission = this.addProductForm.value.emission;
      objData.dealer_suggestive_price = this.addProductForm.value.price;
      objData.msrp=this.addProductForm.value.price;

      if (this.addProductForm.value.chassis_no) {
        objData.chassis_no = this.addProductForm.value.chassis_no ? this.addProductForm.value.chassis_no.toUpperCase() : "";
      }
      // objData.visibility = "",
      // objData.region = "",
      // objData.inventory_id = "",
      // objData.inventory_code = "",

      // }
      if (this.addProductForm.value.visibility == "" || this.addProductForm.value.visibility == "region") {
        objData.region = this.loginData.region;
        objData.visibility = "";
      } else {
        objData.region = "";
        objData.visibility = this.addProductForm.value.visibility
      }

      // console.log('flaf',this.data.flag)
      if (this.data.flag == "EDIT") {
        objData.inventory_id = this.data.data.inventory.id;
        objData.inventory_code = this.data.data.inventory_code;
        this.onEditProduct(objData);
      }
      else {
        this.onAddProduct(objData);
      }
    }
  }

  UpdateVehicleValidity(flag, rowData) {
    // console.log('UpdateVehicleValidity')
    var json = {
      "vehicle_id": rowData.id,
      "action_type": flag,
      "extend_date": this.datePipe.transform(this.selectedExtendDate, 'yyyy-MM-dd')
    }

    this.loader.open();
    this.commonservices.updateVehicleValidity(json).subscribe(
      (res) => {

        if (res.success) {
          setTimeout(() => {
            this.loader.close();
            Swal.fire(res.data.msg)
            this.dialog.close('saved');
          }, 2000);

        } else {
          this.loader.close();
          this.dialog.close('cancle');
          const errorMessage = res;
          Swal.fire(res.error.data.msg)
          // return;
        }
      },
      (err) => {
        this.loader.close();
        Swal.fire('Oops...', err.error.data.msg, 'error')
      }
    );
  }
  public onAddProduct(prodata): void {

    // console.log("prodata",prodata);return;
    this.loader.open();
    const formData = new FormData();
    formData.append("contract_type", "inventory");
    for (let i of this.addProductForm.get("InventoryUploadData").value) {
      formData.append(i.ImagePositionType, i.FileName);
    }
    if (this.insuranceImg) {
      formData.append('Insurance', this.insuranceImg.file)
    }
    if (this.rcImg) {
      formData.append('RC', this.rcImg.file)
    }
    if (this.pucImg) {
      formData.append('PUC', this.pucImg.file)
    }
    if (this.permitImg) {
      formData.append('Permit_validity', this.permitImg.file)
    }
    if (this.fitnessImg) {
      formData.append('Fitness', this.fitnessImg.file)
    }
    this.sub.add(this.commonservices.AddInventoryList(prodata).subscribe(
      (res) => {
        // console.log(res)
        if (res.success) {
          // this.addList = res.data;
          if (this.data.flag == "EDIT") {
            formData.append("user_code", this.data.data.user.user_code);
            formData.append("inventory_code", this.data.data.inventory_code);
          } else {
            formData.append("user_code", res.data.user_code);
            formData.append("inventory_code", res.data.inventory_code);
          }


          // formData.append(temp, this.selectedFiles);
          this.upload(formData);
          setTimeout(() => {
            if (this.data.flag == "EDIT") {
              this.saveClicked.emit();
              this.dialog.close('saved');
              Swal.fire("Vehicle updated successfully.");
            }
            else {
              this.saveClicked.emit();
              this.dialog.close('saved');
              Swal.fire("Vehicle added successfully.");
            }
            this.loader.close();
          }, 4000);
          // Swal.fire("Data added successfully.");

        } else {
          this.loader.close();

          // console.log("inside onAdd else");
          // this.dialog.disableClose = false;
          const errorMessage = res;
          // Swal.fire(res.error.data.msg) commented because format changed
          Swal.fire(res.data.msg)
          return;
        }
      },
      (err) => {
        this.loader.close();
        // Swal.fire('Oops...', err.error.data.msg, 'error')
      }
    ));
    this.markAsDirty(this.addProductForm);
  }
  doesIncludeSubstring(String: string): boolean {
    return String.includes("https://epcfs-sink.s3.amazonaws.com");
  }
  // checkFormData(formData: FormData): boolean {
  //   return Array.from(formData.entries()).length > 4;
  // }
  public onEditProduct(prodata): void {

    // console.log("prodata",prodata);return;
    this.loader.open();
    const formData = new FormData();

    for (let i of this.addProductForm.get("InventoryUploadData").value) {
      if (i.FileImageSrc.includes("https://epcfs-sink.s3.amazonaws.com")) {

      } else {
        formData.append(i.ImagePositionType, i.FileName);
      }
    }
    if (this.insuranceImg) {
      let fileName = this.insuranceImg.file.toString()
      if (fileName.includes("https://epcfs-sink.s3.amazonaws.com")) {
        // formData.append('Insurance', "")
      }
      else {
        formData.append('Insurance', this.insuranceImg.file)
      }
    }
    // else {
    //   formData.append('Insurance', "")
    // }
    if (this.rcImg) {
      let fileName = this.rcImg.file.toString()
      if (fileName.includes("https://epcfs-sink.s3.amazonaws.com")) {
        // formData.append('RC', "")
      }
      else {
        formData.append('RC', this.rcImg.file)
      }
    }
    // else {
    //   formData.append('RC', "")
    // }
    if (this.pucImg) {
      let fileName = this.pucImg.file.toString()
      if (fileName.includes("https://epcfs-sink.s3.amazonaws.com")) {
        // formData.append('PUC', "")
      }
      else {
        formData.append('PUC', this.pucImg.file)
      }
      // formData.append('PUC', this.pucImg.file)
    }
    // else {
    //   formData.append('PUC', "")
    // }
    if (this.permitImg) {
      let fileName = this.permitImg.file.toString()
      if (fileName.includes("https://epcfs-sink.s3.amazonaws.com")) {
        // formData.append('Permit_validity', "")
      }
      else {
        formData.append('Permit_validity', this.permitImg.file)
      }
      // formData.append('Permit_validity', this.permitImg.file)
    }
    // else {
    //   formData.append('Permit_validity', "")
    // }
    if (this.fitnessImg) {
      let fileName = this.fitnessImg.file.toString()
      if (fileName.includes("https://epcfs-sink.s3.amazonaws.com")) {
        // formData.append('Fitness', "")
      }
      else {
        formData.append('Fitness', this.fitnessImg.file)
      }
      // formData.append('Fitness', this.fitnessImg.file)
    }
    // else {
    //   formData.append('Fitness', "")
    // }
    // console.log("endd======");
    
    // return;
    this.sub.add(this.commonservices.EditInventoryList(prodata).subscribe(
      (res) => {
        if (res.success) {
          // this.addList = res.data;
          // if (this.data.flag == "EDIT") {
          formData.append("contract_type", "inventory");
          formData.append("user_code", this.data.data.user.user_code);
          formData.append("inventory_id", this.data.data.inventory.id);
          formData.append("inventory_code", this.data.data.inventory_code);
          let count = 0;
          formData.forEach(() => {
            count++;
          });
          // return count > 4;
          console.log(count);
          if (count < 5) {
            this.saveClicked.emit();
            this.dialog.close('saved');
            Swal.fire("Vehicle updated successfully.");
            this.loader.close();
            return;
          }

          // } else {
          //   formData.append("user_code", res.data.user_code);
          //   formData.append("inventory_code", res.data.inventory_code);
          // }


          // formData.append(temp, this.selectedFiles);
          this.editUpload(formData);
          setTimeout(() => {
            if (this.data.flag == "EDIT") {
              this.saveClicked.emit();
              this.dialog.close('saved');
              Swal.fire("Vehicle updated successfully.");
            }
            else {
              this.saveClicked.emit();
              this.dialog.close('saved');
              Swal.fire("Vehicle added successfully.");
            }
            this.loader.close();
          }, 4000);

          // Swal.fire("Data added successfully.");

        } else {

          this.loader.close();
          const errorMessage = res;
          // Swal.fire(res.error.data.msg) commented because format updated 
          Swal.fire(res.data.msg)
          // return;
        }
      },
      (err) => {

        this.loader.close();
        Swal.fire('Oops...', err.error.data.msg, 'error')
      }
    ));
    this.markAsDirty(this.addProductForm);
  }

  ondistrict(value,flag?) {
    // this.cat_id = "";

    let stateValue = this.stateArray.filter((item: any) => item.name == value)    

    let json = { "state": { "code": stateValue[0].code, "state": stateValue[0].name } }
   
    this.commonservices.getDistrctList(json).subscribe(
      data => {
        this.districtArray = data;
        if (this.data.flag == "EDIT") {
          this.addProductForm.controls["district"].setValue(this.data.data.district)
        }
        if (flag == 'set') {
          const districtValue = this.districtArray.filter(
            (item: any) => item == this.setFormData.ACC_DIST_s
          );
          
          if (districtValue) {
            this.addProductForm.controls["district"].setValue(districtValue[0])
          }
         
  
            this.districtArray.push(this.setFormData.ACC_DIST_s)
            this.addProductForm.controls["district"].setValue(this.setFormData.ACC_DIST_s)
        
        }
        // if (data.success == true) {
        // }
        // else {
        //   Swal.fire(data.data.msg)
        // }
      }, (err) => {

        Swal.fire('Oops...', err.error.data.msg, 'error')

      }
    );
  }


  onstate() {
    this.sub.add(this.commonservices.getLocations().subscribe(
      data => {
        this.stateArray = data;
        if (this.data.flag == "EDIT") {
          this.addProductForm.controls["state"].setValue(this.data.data.state);
          this.ondistrict(this.data.data.state);
        }
      }, (err) => {
        Swal.fire('Oops...', err.error.data.msg, 'error')
      }))

  }

  public onResize(event: any): void {
    this.breakpoint = event.target.innerWidth <= 600 ? 1 : 2;
  }

  private markAsDirty(group: FormGroup): void {
    group.markAsDirty();
    // tslint:disable-next-line:forin
    for (const i in group.controls) {
      group.controls[i].markAsDirty();
    }
  }

  formChanged() {
    this.wasFormChanged = true;
  }

  async BindInventory(flag?) {
    await this.commonservices.BindInventory().subscribe(
      data => {
        if (data.success == true) {
          this.InventoryArray = [data.data[1]];
          if (this.data.flag == "EDIT") {
            if (this.data.data.cat.id == 3) {
              this.addProductForm.controls["InventoryType"].setValue(this.data.data.cat.id)
            }
            else
            {
              this.addProductForm.controls["InventoryType"].setValue('');
            }
            
          }

          if(flag == 'set'){
            this.addProductForm.controls["InventoryType"].setValue(3);
          }
        }
        else {
          // console.log(data);

          Swal.fire('Oops...', data.data.msg, 'error')
        }
      }, (err) => {
        console.log(err);

        Swal.fire('Oops...', err.error.data.msg, 'error')
      }
    );
  }

  async onSelectInventory(value, flag,flag1?,value1?) {
    this.addProductForm.controls["category"].setValue(null);
    this.addProductForm.controls["Subcategory"].setValue(null);
    this.inventoryArray = [];
    this.SubcategoryArray = [];
    this.cat_id = "";
    const dataList: SubCategoryDetails = {} as SubCategoryDetails;
    dataList.cat_id = value;
    dataList.lob=value1 ? value1 : ''
    this.cat_id = value;
    await this.commonservices.BindCategory(dataList).subscribe(
      data => {

        if (data.success == true) {
          this.inventoryArray = data.data;
          if (this.data.flag == "EDIT" && flag == "tsFile") {
            this.addProductForm.controls["category"].setValue(this.data.data.sub_cat.id)
          }
          if (flag1 == 'set') {
            
            let catId = this.inventoryArray.filter((item: any) => item.sub_cat_name == this.setFormData.LOB_s);
         
            if (catId.length > 0) {
            this.addProductForm.controls["category"].setValue(catId[0].sub_cat_id)
            this.onSelectcategory(catId[0].sub_cat_id, "tsFile","set");
            }
            
           else {
            
              this.onSelectInventory(3, "tsFile","set",this.setFormData.LOB_s);
              this.addProductForm.controls["category"].setValue(this.setFormData.LOB_s)

            }
          }
        }
        else {
          // console.log(data);

          Swal.fire('Oops...', data.data.msg, 'error')
        }
      }, (err) => {

        Swal.fire('Oops...', err.error.data.msg, 'error')

      }
    );
  }

  // FormCategory(id): SubCategoryDetails {
  //   const data: any = {} as any;
  //   data.cat_id = id;
  //   return data
  // }
  async onSelectcategory(cateval, flag,flag1?,value1?) {
    let selectedCategory;
    if (this.data.flag == "EDIT" && flag == "tsFile") {
      selectedCategory = this.data.data.sub_cat.sub_cat_name
    }
    else{
     selectedCategory = this.inventoryArray.find(cat => cat.sub_cat_id === cateval);
     selectedCategory = selectedCategory.sub_cat_name;
     this.modelData=[]
    }
    this.lob=selectedCategory
    this.addProductForm.controls["Subcategory"].setValue(null);
    if (flag=='html') {
      this.addProductForm.controls["model"].setValue(null);
    }
    this.SubcategoryArray = [];
    this.sub_cat_id = "";
    const dataList: SubCategoryDetails = {} as SubCategoryDetails;
    dataList.subcat_id = cateval;
    // if(value1){
    dataList.ppl=value1 ? value1 : ''
    this.sub_cat_id = cateval;
    await this.commonservices.BindSubCategory(dataList).subscribe(
      data => {

        if (data.success == true) {
          this.SubcategoryArray = data.data;
          let formvalue = this.addProductForm.value.category;
          let filter = this.inventoryArray.filter((element :any) => element.sub_cat_id == formvalue)  
          console.log("data",this.data);
          console.log(filter);
          
          if (this.data.flag !== "EDIT" && filter.length > 0) {
            if (filter[0].sub_cat_name == 'CV NTML') {
              this.addProductForm.controls["brand"].setValue('NTML');
              // this.addProductForm.controls["brand"].setValue(null);
              this.brandsDataArray=[{ name: "NTML" }]
            }
            else
            {
              if (flag1 == 'set') {
            this.addProductForm.controls["brand"].setValue('TML');
              }
              else{
                this.addProductForm.controls["brand"].setValue(null);
              }
            this.brandsDataArray = [
              { name: "TML" },
              { name: "NTML" }
            ]
            }
          }
          if (this.data.flag == "EDIT" && filter.length > 0) {
            if (filter[0].sub_cat_name == 'CV NTML') {
              this.addProductForm.controls["brand"].setValue('NTML');
              this.brandsDataArray=[{ name: "NTML" }]
            }
            else
            {
              this.brandsDataArray = [
                { name: "TML" },
                { name: "NTML" }
              ]
            }
          }
          if (this.data.flag == "EDIT" && flag == "tsFile") {
            this.addProductForm.controls["Subcategory"].setValue(this.data.data.sub_cat_detail.id)
            this.ppl=this.data.data.sub_cat_detail.name
          }
          if (flag1 == 'set') {
            let subcatId = this.SubcategoryArray.filter((item:any)=> item.name == this.setFormData.PPL_s);
            if (subcatId.length > 0) {
              this.addProductForm.controls["Subcategory"].setValue(subcatId[0].sub_cat_detail_id)
              this.ppl=subcatId[0].name
              this.onSelectSubcategory(subcatId[0].sub_cat_detail_id,'set')
            }
            else {

              let id =this.addProductForm.value.category
              // this.ppl=this.setFormData.PPL_s
              this.onSelectcategory(id, "tsFile","set",this.setFormData.PPL_s);
              this.addProductForm.controls["category"].setValue(this.setFormData.LOB_s)
            }
          }
        }
        else {
          if (flag1 == 'set') {
      
          let id =this.addProductForm.value.category
              // this.ppl=this.setFormData.PPL_s

              this.onSelectcategory(id, "tsFile","set",this.setFormData.PPL_s);
              this.addProductForm.controls["category"].setValue(id)
          }
          else{
            Swal.fire('Oops...', data.data.msg, 'error')
          }
        }
      }, (err) => {

        Swal.fire('Oops...', err.error.data.msg, 'error')

      });
  }

  onSelectSubcategory(Subcateval,flag?) {
    let subcat = {
      catId: this.cat_id,
      subcat_id: this.sub_cat_id,
      subcat_detail_id: Subcateval,

    }
    const selectedsubCategory = this.SubcategoryArray.find(cat => cat.sub_cat_detail_id === Subcateval);
    this.ppl=selectedsubCategory.name
    console.log("ppl",this.ppl);
    this.addProductForm.controls.model.setValue('');
    // this.getModelData(this.lob,this.ppl);
    if (flag == 'set') {
      this.getModelData(this.lob,this.ppl,'set');
    }
    else{
      this.getModelData(this.lob,this.ppl);
    }
    // this.sub.add(this.commonservices.BindControls(subcat).subscribe(
    //   data => {

    //     if (data.success == true) {
    //       this.bindControlArray = data.data;
    //     }
    //     else {
    //       Swal.fire(data.msg)
    //     }
    //   }, (err) => {

    //     Swal.fire(err.error.data.msg)

    //   }
    // ))
  }
  onImagePositionChange(value) {
    // this.isCoverImage = true;
    if (this.addProductForm.controls['ImagePositionType'].value == 'cover_image') {
      // this.isCoverImage = false;
    }
    else {
      // this.isCoverImage = true;
    }
    this.addProductForm.controls['imgFile'].setValue(null);
  }
  selectFiles(event: any): void {
    this.onLoad = true
    this.selectedFiles = event.target.files;
    this.previews = [];
    if (this.selectedFiles && this.selectedFiles[0]) {
      const numberOfFiles = this.selectedFiles.length;
      for (let i = 0; i < numberOfFiles; i++) {
        let file = this.selectedFiles[i];
        let fileExtention = file.name.split('.').pop();
        if (fileExtention.toLowerCase() == 'png' || fileExtention.toLowerCase() == 'jpg' || fileExtention.toLowerCase() == 'jpeg') {
          if (file.size < 5000000) {
            const reader = new FileReader();
            // if(this.getmultipleControl.length < 20){
              this.BindControlArray(file);
            // }
            reader.onload = (e: any) => {
              this.previews.push(e.target.result);
            };

            reader.readAsDataURL(this.selectedFiles[i]);

          }
          else {
            Swal.fire("File size should not be more than 5 MB.");
            return;
          }

        }
        else {
          this.addProductForm.controls['imgFile'].setValue(null);
          Swal.fire("Only JPEG,PNG and JPG files are allowed.");
        }
      }
      (<HTMLInputElement>document.getElementById('idVehicleImg')).value = "";
    }
  }

  upload(formData): void {
    // this.loader.open();
    this.fileService.uploadVehicleImage(formData).subscribe(
      data => {
        // this.loader.close();
        if (data.success == true) {
          //   this.SubcategoryArray = data.data;
        } else {
          Swal.fire(data.data.msg);
        }
      }, (err) => {
        // this.loader.close();
        Swal.fire('Oops...', err.error.data.msg, 'error')

      });

  }
  editUpload(formData): void {

    this.fileService.editUploadVehicleImage(formData).subscribe(
      data => {
        if (data.success == true) {
          // setTimeout(() => {
          //   this.saveClicked.emit();
          //   this.dialog.close('saved');
          //   if (this.data.flag == "EDIT") {
          //     Swal.fire("Data updated successfully.");
          //   }
          //   else {
          //     Swal.fire("Data added successfully.");
          //   }
          // }, 2000);

          //   this.SubcategoryArray = data.data;
        } else {
          Swal.fire(data.data.msg);
        }
      }, (err) => {

        Swal.fire('Oops...', err.error.data.msg, 'error')

      });

  }
  onUploadChange(event) {

    this.isDoc = event.value == 'vehicle' ? false : true;
  }

  selectInsuranceFiles(event: any): void {
    // this.selectedFiles = event.target.files;
    var selectedFile = event.target.files[0] as File;

    let fileExtention = selectedFile.name.split('.').pop();
    if (fileExtention.toLowerCase() == 'png' || fileExtention.toLowerCase() == 'jpg' || fileExtention.toLowerCase() == 'jpeg' || fileExtention.toLowerCase() == 'pdf') {
      if (selectedFile.size < 5000000) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.insuranceImg = {
            file: selectedFile,
            srcPath: e.target.result,
            fileExtention: fileExtention,
            id: 0
          }
        };
        reader.readAsDataURL(selectedFile);
      }
      else {
        Swal.fire("File size should not be more than 5 MB.");
        return;
      }

    }
    else {
      // this.addProductForm.controls['imgFile'].setValue(null);
      this.insuranceImg = null;
      Swal.fire("Only JPEG,PNG,JPG and PDF files are allowed.");
    }

  }
  selectRCFiles(event: any): void {
    // this.selectedFiles = event.target.files;
    var selectedFile = event.target.files[0] as File;
    let fileExtention = selectedFile.name.split('.').pop();
    if (fileExtention.toLowerCase() == 'png' || fileExtention.toLowerCase() == 'jpg' || fileExtention.toLowerCase() == 'jpeg' || fileExtention.toLowerCase() == 'pdf') {
      if (selectedFile.size < 5000000) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.rcImg = {
            file: selectedFile,
            srcPath: e.target.result,
            fileExtention: fileExtention,
            id: 0
          }
        };
        reader.readAsDataURL(selectedFile);
      }
      else {
        Swal.fire("File size should not be more than 5 MB.");
        return;
      }
    }
    else {
      // this.addProductForm.controls['imgFile'].setValue(null);
      this.rcImg = null;
      Swal.fire("Only JPEG,PNG,JPG and PDF files are allowed.");
    }

  }
  selectPUCFiles(event: any): void {
    // this.selectedFiles = event.target.files;
    var selectedFile = event.target.files[0] as File;
    let fileExtention = selectedFile.name.split('.').pop();
    if (fileExtention.toLowerCase() == 'png' || fileExtention.toLowerCase() == 'jpg' || fileExtention.toLowerCase() == 'jpeg' || fileExtention.toLowerCase() == 'pdf') {
      if (selectedFile.size < 5000000) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.pucImg = {
            file: selectedFile,
            srcPath: e.target.result,
            fileExtention: fileExtention,
            id: 0
          }
        };
        reader.readAsDataURL(selectedFile);
      }
      else {
        Swal.fire("File size should not be more than 5 MB.");
        return;
      }
    }
    else {
      // this.addProductForm.controls['imgFile'].setValue(null);
      this.pucImg = null;
      Swal.fire("Only JPEG,PNG,JPG and PDF files are allowed.");
    }

  }
  selectPermitFiles(event: any): void {
    // this.selectedFiles = event.target.files;
    var selectedFile = event.target.files[0] as File;
    let fileExtention = selectedFile.name.split('.').pop();
    if (fileExtention.toLowerCase() == 'png' || fileExtention.toLowerCase() == 'jpg' || fileExtention.toLowerCase() == 'jpeg' || fileExtention.toLowerCase() == 'pdf') {
      if (selectedFile.size < 5000000) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.permitImg = {
            file: selectedFile,
            srcPath: e.target.result,
            fileExtention: fileExtention,
            id: 0
          }
        };
        reader.readAsDataURL(selectedFile);
      }
      else {
        Swal.fire("File size should not be more than 5 MB.");
        return;
      }
    }
    else {
      // this.addProductForm.controls['imgFile'].setValue(null);
      this.permitImg = null;
      Swal.fire("Only JPEG,PNG,JPG and PDF files are allowed.");
    }

  }
  selectfitnessFiles(event: any): void {
    // this.selectedFiles = event.target.files;
    var selectedFile = event.target.files[0] as File;
    let fileExtention = selectedFile.name.split('.').pop();
    if (fileExtention.toLowerCase() == 'png' || fileExtention.toLowerCase() == 'jpg' || fileExtention.toLowerCase() == 'jpeg' || fileExtention.toLowerCase() == 'pdf') {
      if (selectedFile.size < 5000000) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.fitnessImg = {
            file: selectedFile,
            srcPath: e.target.result,
            fileExtention: fileExtention,
            id: 0
          }
        };
        reader.readAsDataURL(selectedFile);
      }
      else {
        Swal.fire("File size should not be more than 5 MB.");
        return;
      }
    }
    else {
      // this.addProductForm.controls['imgFile'].setValue(null);
      this.fitnessImg = null;
      Swal.fire("Only JPEG,PNG,JPG and PDF files are allowed.");
    }

  }
  removeDocNew(flag) {
    if (flag == "insurance") {
      this.insuranceImg = null;
      (<HTMLInputElement>document.getElementById('idInsuranceImg')).value = "";

    }
    else if (flag == "rc") {
      this.rcImg = null;
      (<HTMLInputElement>document.getElementById('#idRcImg')).value = "";

    }
    else if (flag == "puc") {
      this.pucImg = null;
      (<HTMLInputElement>document.getElementById('idPucImg')).value = "";

    }
    else if (flag == "permit") {
      this.permitImg = null;
      (<HTMLInputElement>document.getElementById('idPermitImg')).value = "";

    }
    else if (flag == "fitness") {
      this.fitnessImg = null;
      // let element = document.getElementById("idFitnessImg")
      // element.value = "";
      (<HTMLInputElement>document.getElementById('idFitnessImg')).value = "";
    }

  }
  removeDoc(flag,imgData){
    this.confirmService
      .confirm({ message: ` Are you sure you want to remove this image. Image will be deleted permanently?` })
      .subscribe((data) => {
        if (data) {
          if (this.data.flag == 'EDIT' && imgData.srcPath.includes("https://epcfs-sink.s3.amazonaws.com")) {
            let index = 0
            if (flag == "insurance") {
              index = this.insuranceImg.id;
        
            }
            else if (flag == "rc") {
              index = this.rcImg.id;        
            }
            else if (flag == "puc") {
              index = this.pucImg.id;        
            }
            else if (flag == "permit") {
              index = this.permitImg.id;        
            }
            else if (flag == "fitness") {
              index = this.fitnessImg.id;
            }
            let requestBody = {
              "vehicle_id": this.data.data.vehicle_documents[index].vehicle,
              "file_id": this.data.data.vehicle_documents[index].id
            }

            this.commonservices.RemoveInventoryById(requestBody).subscribe((res) => {
              if (res.success) {

                if (flag == "insurance") {
                  this.insuranceImg = null;
                  (<HTMLInputElement>document.getElementById('idInsuranceImg')).value = "";
            
                }
                else if (flag == "rc") {
                  this.rcImg = null;
                  (<HTMLInputElement>document.getElementById('#idRcImg')).value = "";
            
                }
                else if (flag == "puc") {
                  this.pucImg = null;
                  (<HTMLInputElement>document.getElementById('idPucImg')).value = "";
            
                }
                else if (flag == "permit") {
                  this.permitImg = null;
                  (<HTMLInputElement>document.getElementById('idPermitImg')).value = "";
            
                }
                else if (flag == "fitness") {
                  this.fitnessImg = null;
                  // let element = document.getElementById("idFitnessImg")
                  // element.value = "";
                  (<HTMLInputElement>document.getElementById('idFitnessImg')).value = "";
                }
                
                Swal.fire("Remove Image Successfully");
              } else {
                Swal.fire(res.error.data.msg, "Error");
              }
            },
              (error) => {
                Swal.fire(error.error.data.msg, "Error");
              }
            );
          }
          else {
            if (flag == "insurance") {
              this.insuranceImg = null;
              (<HTMLInputElement>document.getElementById('idInsuranceImg')).value = "";
        
            }
            else if (flag == "rc") {
              this.rcImg = null;
              (<HTMLInputElement>document.getElementById('#idRcImg')).value = "";
        
            }
            else if (flag == "puc") {
              this.pucImg = null;
              (<HTMLInputElement>document.getElementById('idPucImg')).value = "";
        
            }
            else if (flag == "permit") {
              this.permitImg = null;
              (<HTMLInputElement>document.getElementById('idPermitImg')).value = "";
        
            }
            else if (flag == "fitness") {
              this.fitnessImg = null;
              // let element = document.getElementById("idFitnessImg")
              // element.value = "";
              (<HTMLInputElement>document.getElementById('idFitnessImg')).value = "";
            }
          }
        }
      });
  }
  removeVehicleImages(i: number) {
    this.confirmService
      .confirm({ message: ` Are you sure you want to remove this image. Image will be deleted permanently ?` })
      .subscribe((data) => {
        if (data) {
          if (this.data.flag == 'EDIT') {
            let requestBody = {
              "vehicle_id": this.data.data.vehicle_documents[i].vehicle,
              "file_id": this.data.data.vehicle_documents[i].id
            }

            this.commonservices.RemoveInventoryById(requestBody).subscribe((res) => {
              if (res.success) {
                let FormArray = this.addProductForm.get('InventoryUploadData') as FormArray;
                FormArray.removeAt(i);
                this.vehicleDocumentLength -= 1;
                
                Swal.fire("Remove Image Successfully");
              } else {
                Swal.fire(res.error.data.msg, "Error");
              }
            },
              (error) => {
                Swal.fire(error.error.data.msg, "Error");
              }
            );
          }
          else {
            (<HTMLInputElement>document.getElementById('idVehicleImg')).value = "";
            let FormArray = this.addProductForm.get('InventoryUploadData') as FormArray;
                FormArray.removeAt(i);
            // FormArray.at(i).patchValue({
            //   FileImageSrc: "",
            //   FileName: "",
            //   ImageType: "",
            //   ImagePositionType: this.getmultipleControl.value[i].ImagePositionType
            // });
          }
          // control.removeAt(i);
          // }
          // else {
          //   // control.removeAt(i);
          //   Swal.fire("Image Removed Successfully");
          // }
        }
      });
  }
  getInputModels(data:any){
    data=data.target.value
    let payLoad = {
      // "lob":this.data.flag == "EDIT" ? this.data.data.sub_cat.sub_cat_name : this.lob,
      // "ppl":this.data.flag == "EDIT" ? this.data.data.sub_cat_detail.name : this.ppl,
      "lob":this.lob,
      "ppl":this.ppl,
      "search":data,
      "offset": 0,
      "limit": 10,
    }
    this.sub.add(this.commonservices.getModelList(payLoad).subscribe({
      next: (res: any) => {
        if (res.success) {
          this.modelData = res.data.models;
          
        }
        else{
          this.modelData=[];
          // this.addProductForm.controls.model.setValue('');
        }
      },
      error: () => { },
      complete: () => { }
    }))
  }
  // getBlur(){
  //   const modelValue = this.addProductForm.controls.model.value;
  //   const isModelValueInData = this.modelData.some((model: any) => model === (modelValue));
  //   if (!isModelValueInData) {
  //     if (this.data.flag == 'EDIT') {
  //       this.getStaticModels();
  //       const isModelValueInDataedit = this.modelData.some((model: any) => model === (this.data.data.model));
  //       if (isModelValueInDataedit) {
  //         this.addProductForm.controls.model.setValue(this.data.data.model);
  //       }
  //       else{
  //         this.addProductForm.controls.model.setValue('');
  //       }
  //     }
  //     else{
  //       this.addProductForm.controls.model.setValue('');
  //       this.getStaticModels();
  //     }
  //   }
  // }
  getBlur(){
    const modelValue = this.addProductForm.controls.model.value;
    const isModelValueInData = this.modelData.some((model: any) => model === (modelValue));
    if (!isModelValueInData) {
      // if (this.data.flag == 'EDIT') {
      //   const isModelValueInDataedit = this.modelData.some((model: any) => model === (this.data.data.model && modelValue));
      //   if (!isModelValueInDataedit) {
      //     this.addProductForm.controls.model.setValue(this.data.data.model);
      //   }
      // }
      // else{
        this.addProductForm.controls.model.setValue('');
        this.getStaticModels();
      // }
    }
  }
  getStaticModels(){
    let payLoad = {
      // "lob":this.data.flag == "EDIT" ? this.data.data.sub_cat.sub_cat_name : this.lob,
      // "ppl":this.data.flag == "EDIT" ? this.data.data.sub_cat_detail.name : this.ppl,
      "lob":this.lob,
      "ppl":this.ppl,
      "search":'',
      "offset": 0,
      "limit": 10,
    }
    this.sub.add(this.commonservices.getModelList(payLoad).subscribe({
      next: (res: any) => {
        if (res.success) {
          this.modelData = res.data.models;
          
        }
        else{
          this.modelData=[];
          this.addProductForm.controls.model.setValue('');
        }
      },
      error: () => { },
      complete: () => { }
    }))
  }
  closeModal(){
    const control = <FormArray>(
      this.addProductForm.controls["InventoryUploadData"]);
    let temp = control.controls.filter((item: any) => item.controls.ImagePositionType.value == "cover_image")

      if (temp.length == 0 && this.data.flag == "EDIT") {
        Swal.fire('Cover image is mandatory.');
        return;
      }else{
        this.dialog.close('cancle');
      }
  }
  notAllowSpace(event: KeyboardEvent) {
    if (event.key === ' ') {
      event.preventDefault();
    }
  }  

  getRegistrationDetails(data:any){
console.log(data);
let payload = {
  "reg_number":data
}
this.loader.open()

this.sub.add(this.commonservices.getRegistrationData(payload).subscribe({
  next: (res: any) => {
    if (res.success) {
      this.setFormData=res.data[0]
      console.log("res",res.data[0]);
      this.setFormValues(res.data[0])
      this.loader.close();
    }
    else{
      this.addProductForm.reset()
      this.loader.close();
      Swal.fire('Vehicle not found.');
      this.setFormData=[];
    }
    this.loader.close();
  },
  error: () => {this.loader.close() },
  complete: () => {this.loader.close() }
}))
this.loader.close();
  }


  setFormValues(data:any){
    // this.loader.close();
    // this.loader.open()
    // if (data.ACC_STATE_so) {
    // let stateValue = this.stateArray.filter((item: any) => item.code == data.ACC_STATE_so) ;
    // this.addProductForm.controls["state"].setValue(stateValue)
    // }
    // this.addProductForm = this.fb.group({
    //   InventoryType: ['', [Validators.required]],
    //   category: ['', [Validators.required]],
    //   Subcategory: ['', [Validators.required]],
    //   kms: [data.LAST_SERVICE_KM_so ? data.LAST_SERVICE_KM_so : ''],
    //   brand: [data.flag == 'EDIT' ? data.data.brand : ''],
    //   // ownership: [data.flag == 'EDIT' ? data.data.no_of_owners : ''],
    //   regno: [data.REGISTRATION_NUM_so ? data.REGISTRATION_NUM_so : ''],
    //   // application: [data.flag == 'EDIT' ? data.data.application : ''],
    //   // model: [data.flag == 'EDIT' ? data.data.model : ''],
    //   // loadbody: [data.flag == 'EDIT' ? data.data.body_type : ''],
    //   // mft: [data.flag == 'EDIT' ? data.data.year_of_manufacture : ''],
    //   // state: [data.flag == 'EDIT' ? data.data.state : ''],
    //   // district: [data.flag == 'EDIT' ? data.data.district : ''],
    //   fuel_type: [data.FUEL_TYPE_so ? data.FUEL_TYPE_so : ''],
    //   // visibility: [data.flag == 'EDIT' ? data.data.visibility : data.visibility],
    //   chassis_no: [data.CHASSIS_NUM_so ? data.CHASSIS_NUM_so : ''],
    //   // imgFile: [null],
    //   // selectedUploadType: ["vehicle"],
    //   // InventoryUploadData: this.fb.array([]),
    //   // vehicleType: [data.flag == 'EDIT' ? data.data.vehicle_certificate_type : ''],
    //   // finance: [data.flag == 'EDIT' ? data.data.finance_availability : ''],
    //   // warranty: [data.flag == 'EDIT' ? data.data.engine_warranty : ''],
    //   emission: [data.EMITION_NOM_so ? data.EMITION_NOM_so : ''],
    //   // price: [data.flag == 'EDIT' ? data.data.dealer_suggestive_price : ''],
    // });
    this.addProductForm.patchValue({
      InventoryType: '',
      category: '',
      Subcategory: '',
      kms: data.LAST_SERVICE_KM_s ? data.LAST_SERVICE_KM_s : '',
      regno: data.REGISTRATION_NUM_so ? data.REGISTRATION_NUM_so : '',
      chassis_no: data.CHASSIS_NUM_so ? data.CHASSIS_NUM_so : '',
      // fuel_type:data.FUEL_TYPE_s ? data.FUEL_TYPE_s : ''
      // mft:'2001',
      // price:'2001',
      // finance:'Yes',
      // warranty:'Yes',
      // vehicleType:'Certified',
      // brand:'TML'

    });
    if (data.ACTL_DELVIRY_DATE_dt) {
      const dateString = data.ACTL_DELVIRY_DATE_dt;
      const date = new Date(dateString); 
      const year = date.getFullYear();  
      this.addProductForm.controls["mft"].setValue(year);
    }
    if (data.FUEL_TYPE_s) {
      const existingFuel = this.fuelType.find(fuel => fuel === data.FUEL_TYPE_s);
      if (!existingFuel) {
        this.fuelType.push(data.FUEL_TYPE_s);
      }
      this.addProductForm.controls["fuel_type"].setValue(data.FUEL_TYPE_s);
    }
    
    if (data.EMITION_NOM_s) {
      const existingNorm = this.emissionNorms.find(norm => norm.value === data.EMITION_NOM_s);

      if (!existingNorm) {
        this.emissionNorms.push({ value: data.EMITION_NOM_s, display: data.EMITION_NOM_s });
      }
    this.addProductForm.controls["emission"].setValue(data.EMITION_NOM_s)
      
    }
    //state bind
     if (data.ACC_STATE_so) {
    let stateValue = this.stateArray.filter((item: any) => item.code == data.ACC_STATE_so) ;
    this.addProductForm.controls["state"].setValue(stateValue[0].name)
    this.ondistrict(stateValue[0].name,'set')
    
    }
    this.BindInventory('set')
    if (data.LOB_s) {
    this.onSelectInventory(3, "tsFile","set");
    }
    
    if (data.VEH_RFTYPE_s) {
      if (data.VEH_RFTYPE_s == 'Certified') {
        this.addProductForm.controls["vehicleType"].setValue('Certified');
      }
      else if(data.VEH_RFTYPE_s == 'Non - Certified') {
        this.addProductForm.controls["vehicleType"].setValue('Non-Certified');
      }
      else{
        this.addProductForm.controls["vehicleType"].setValue('');
      }
    }
    if (data.OPTY_VEHICLEAPP_s) {
      const existingvehicle = this.applicationData.find(fuel => fuel === data.OPTY_VEHICLEAPP_s);
      console.log(existingvehicle,!existingvehicle);
      
      if (!existingvehicle) {
        this.applicationData.push(data.OPTY_VEHICLEAPP_s);
      }
      this.addProductForm.controls["application"].setValue(data.OPTY_VEHICLEAPP_s);
    }
    // if (this.setFormData.FUEL_TYPE_s) {
    //   let finfFuel=this.fuelType.filter((item:any)=> item === this.setFormData.FUEL_TYPE_s)
    //   if (finfFuel.length > 0) {
    //     this.addProductForm.controls["fuel_type"].setValue(this.setFormData.FUEL_TYPE_s)
    //   } 
    //   else{
    //     this.fuelType.push(this.setFormData.FUEL_TYPE_s)
    //     this.addProductForm.controls["fuel_type"].setValue(this.setFormData.FUEL_TYPE_s)
    //   }
    // }
    // if (this.setFormData.EMITION_NOM_s) {
    //   let finemission=this.emissionNorms.filter((item:any)=> item.display === this.setFormData.EMITION_NOM_s)
    //   console.log(finemission);
      
    //   if (finemission.length > 0) {
    //     this.emissionNorms.push({ value: this.setFormData.EMITION_NOM_s, display: this.setFormData.EMITION_NOM_s })
    //     this.addProductForm.controls["emission"].setValue(this.setFormData.EMITION_NOM_s)
    //   } 
    //   else{
    //     this.emissionNorms.push({ value: this.setFormData.EMITION_NOM_s, display: this.setFormData.EMITION_NOM_s })
    //     this.addProductForm.controls["emission"].setValue(this.setFormData.EMITION_NOM_s)
    //   }
    // }
  
   
    // this.onSelectcategory(this.data.data.sub_cat.id, "tsFile");
    // this.loader.close()
    // this.loader.close();
  }
}
export class InventoryImage {
  file_path: string;
  inventory_id: number;
}

export class InventoryRequest {
  cat_id: any;
  model: any;
  sub_cat_id: any;
  sub_cat_detail_id: any;
  odometer: any;
  brand: any;
  no_of_owners: any;
  veh_registration_number: any;
  fuel_type: any;
  application: any;
  body_type: any;
  year_of_manufacture: any;
  chassis_no: any;
  state: any;
  district: any;
  visibility: any;
  region: any;
  inventory_id: any;
  inventory_code: any;
  vehicle_certificate_type: any;
  finance_availability: any;
  engine_warranty: any;
  emission: any;
  dealer_suggestive_price: any;
  msrp:any
}
import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, DoCheck } from '@angular/core';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { CommonService } from 'app/shared/services/MyServices/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product-filter',
  templateUrl: './product-filter.component.html',
  styleUrls: ['./product-filter.component.scss']
})
export class ProductFilterComponent implements OnInit , OnChanges,DoCheck {
  sub = new Subscription
  public productFilter: FormGroup;
  isOpendropdown: boolean = false;
  @Input() filterFields: any;
  @Input() isRegional: any;
  @Output() filterData = new EventEmitter();
  districtArray: any;
  stateArray: any;
  public breakpoint: number;
  SessionData: any;
  inventoryArray: any;
  SubcategoryArray: any;
  isTSM: boolean =false;
  constructor(private commonservices: CommonService, private fb: FormBuilder, private commonservice: CommonService,private router: Router) { }
  nofOwners = [
    "First-Owner",
    "Second-Owner",
    "Third-Owner",
    "Four-Owner",
    "Fifth-Owner",
    "Six-Owner",
    "Seven-Owner",
    "Eight-Owner",
    "Nine-Owner"
  ];
  regions = [
    "East",
    "North",
    "South",
    "West"
  ];
  visibilty = [
    "public",
    "private",
  ];
  brandsDataArray = [
    { name: "TML" },
    { name: "NTML" }
  ]
  Inactive=[
    { name: "Active", value:true},
    { name: "Inactive", value:false}
  ]
  @Input() callrefresh: boolean;
  private previousCallRefresh: boolean | undefined;
  isInventory:boolean=false
  ngOnInit() {
    const currentPath = this.router.url.split('?')[0].split('#')[0];
    if (currentPath === '/pages/InventoryList') {
      this.isInventory=true
    }
    else{
      this.isInventory=false
    }
    this.SessionData = this.commonservice.getUserDetails();
    // console.log(this.SessionData);
    if (this.SessionData.position == 'Tata OK TSM') {
    this.isTSM =true;
    }
    else{
      this.isTSM =false;
    }
    this.onstate();
    this.selectCategory();
    this.productFilter = this.fb.group({
      owner: [null],
      region: [null],
      sold: [null],
      zone: [null],
      state: [null],
      district: [null],
      visiblity: [null],
      vehiclecategory: [null],
      min_odometer: [null],
      max_odometer: [null],
      vehicleType: [null],
      finance: [null],
      warranty: [null],
      emission: [null],
      approval_status:[null],
      sub_cat_name:[null],
      sub_cat_detail_name:[null],
      active:[null],
      brand:[null]
    })
    this.breakpoint = window.innerWidth <= 600 ? 1 : 2;
  }
  openDropdown() {
    this.isOpendropdown = !this.isOpendropdown;
  }

  closeDropdown() {
    this.isOpendropdown = false;
  }

  onstate() {
    this.sub.add(this.commonservices.getLocations().subscribe(
      data => {
        this.stateArray = data;
      }, (err) => {
        Swal.fire(err.error.data.msg)
      }))

  }
  ondistrict(value) {
    value = this.stateArray.filter((item: any) => item.code == this.productFilter.controls['state'].value)
    value = value[0];

    let json = { "state": { "code": value.code, "state": value.state } }

    this.commonservices.getDistrctList(json).subscribe(
      data => {
        this.districtArray = data;
      }, (err) => {
        Swal.fire('Oops...', err.error.data.msg, 'error')
      }
    );
  }

  emitData() {
    this.filterData.emit(this.productFilter.value);
    this.closeDropdown();
  }

  reset() {
    this.productFilter.reset();
    this.filterData.emit("reset");
    this.closeDropdown();
  }

  public onResize(event: any): void {
    this.breakpoint = event.target.innerWidth <= 600 ? 1 : 2;
  }
  async selectCategory(){
    this.inventoryArray = [];
    this.SubcategoryArray = [];
    let dataList = { "cat_id": 3 }
    await this.commonservices.BindCategory(dataList).subscribe(
      data => {

        if (data.success == true) {
          this.inventoryArray = data.data;
        }
        else {
          Swal.fire('Oops...', data.data.msg, 'error')
        }
      }, (err) => {

        Swal.fire('Oops...', err.error.data.msg, 'error')

      }
    );
  }
async  getSubcategory(data:any){
let payload = {
  'subcat_id':data.sub_cat_id
}
await this.commonservices.BindSubCategory(payload).subscribe(
  data => {

    if (data.success == true) {
      this.SubcategoryArray = data.data;
    }
    else {
      Swal.fire('Oops...', data.data.msg, 'error')
    }
  }, (err) => {

    Swal.fire('Oops...', err.error.data.msg, 'error')

  });
  }
  ngOnChanges(changes: SimpleChanges) {
    this.isOpendropdown = false
    // this.productFilter.reset();
  }
  ngDoCheck() {
    if (this.callrefresh !== this.previousCallRefresh) {
      // this.isOpendropdown = false;

      if (this.callrefresh) {
        this.productFilter.reset();
      }

      this.previousCallRefresh = this.callrefresh;
    }
  }
  // ngOnDestroy() {
  //   this.isOpendropdown = false
  // }
}
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-confirm',
  template: `
    <div class="closebtn">
      <span (click)="dialogRef.close(false)">X</span>
    </div>
    <div style="overflow-x: auto;">
      <h1>Annexure List</h1>
      
      <!-- Clickable headings to toggle tables -->
      <div class="annexure-tabs">
        <button *ngIf="this.annexure1Data && this.annexure1Data.length > 0" (click)="toggleAnnexure(1)" [class.active]="activeAnnexure === 1">Annexure 2</button>
        <button *ngIf="this.annexure2Data && this.annexure2Data.length > 0" (click)="toggleAnnexure(2)" [class.active]="activeAnnexure === 2">Annexure 3</button>
      </div>

      <!-- Show the table based on active annexure -->
      <div *ngIf="activeAnnexure === 1">
        <table class="styled-table">
          <thead>
            <tr>
              <th *ngFor="let key of annexure1Keys">
                {{ formatKey(key) }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of annexure1Data">
              <td *ngFor="let key of annexure1Keys">
                {{ item[key] || '-' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div *ngIf="activeAnnexure === 2">
        <table class="styled-table">
          <thead>
            <tr>
              <th *ngFor="let key of annexure2Keys">
                {{ formatKey(key) }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of annexure2Data">
              <td *ngFor="let key of annexure2Keys">
                {{ item[key] || '-' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div mat-dialog-actions class="dialog-actions">
      <button type="button" color="accent" mat-raised-button (click)="dialogRef.close(false)">Cancel</button>
    </div>
  `,
  styles: [`
    .closebtn {
      display: flex;
      justify-content: end;
    }
    .closebtn span {
      font-size: 20px;
      cursor: pointer;
    }
    .styled-table {
      width: 100%;
      border-collapse: collapse;
      font-family: 'Arial', sans-serif;
      margin: 20px 0;
      font-size: 16px;
      text-align: left;
    }
    .styled-table thead tr {
      background-color: #092165;
      color: white;
      text-align: left;
      font-weight: bold;
    }
    .styled-table th, .styled-table td {
      padding: 12px 15px;
      border: 1px solid #ddd;
    }
    .styled-table tbody tr {
      border-bottom: 1px solid #ddd;
    }
    .styled-table tbody tr:nth-of-type(even) {
      background-color: #f3f3f3;
    }
    .styled-table tbody tr:hover {
      background-color: #f1f1f1;
    }
    .styled-table tbody tr:last-of-type {
      border-bottom: 2px solid #092165;
    }
    .dialog-actions {
      margin-top: 20px;
      text-align: right;
    }
    .dialog-actions button {
      background-color: blue;
      color: #fff;
      border: none;
      padding: 10px 20px;
      cursor: pointer;
    }
    .dialog-actions button:hover {
      background-color: #092165;
    }

    /* Styles for the tabs */
    .annexure-tabs {
      display: flex;
      gap: 10px;
      margin-bottom: 20px;
    }
    .annexure-tabs button {
        background-color: white;
        color:rgba(14, 16, 104, 0.96);
        border:1px solid rgba(14, 16, 104, 0.96) !important;
      padding: 10px 20px;
      cursor: pointer;
      border: none;
    }
    .annexure-tabs button.active {
        border:1px solid rgba(14, 16, 104, 0.96);
    
      background-color: #092165;
      color: white;
      padding: 10px 20px;
    }
   
  `]
})
export class Annexure {
  activeAnnexure: number = 1; // By default, Annexure 1 is active
  annexure1Data: any[] = [];
  annexure2Data: any[] = [];
  annexure1Keys: string[] = [];
  annexure2Keys: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<Annexure>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    
    
    // Split data into annexure 1 and annexure 2
    this.annexure1Data = data.agreement_vehicle_details_annexure1;
    this.annexure2Data = data.agreement_vehicle_details_annexure2;
    
    // Get the keys for both annexures
    // if (this.annexure1Data && this.annexure1Data.length > 0) {
    //   this.annexure1Keys = Object.keys(this.annexure1Data[0]);
    // }
    if (this.annexure1Data && this.annexure1Data.length > 0) {
      const excludedKeys = [
        'base_price',
        'created_at',
        'updated_at',
        'agreement_id',
        'sub_cat',
        'sub_cat_detail'
      ];
      this.annexure1Keys = Object.keys(this.annexure1Data[0]).filter(
        (key) => !excludedKeys.includes(key)
      );
    }

    // if (this.annexure2Data && this.annexure2Data.length > 0) {
    //   this.annexure2Keys = Object.keys(this.annexure2Data[0]);
    // }
    if (this.annexure2Data && this.annexure2Data.length > 0) {
      const excludedKeys = [
        'base_price',
        'created_at',
        'updated_at',
        'agreement_id',
        'sub_cat',
        'sub_cat_detail'
      ];
      this.annexure2Keys = Object.keys(this.annexure2Data[0]).filter(
        (key) => !excludedKeys.includes(key)
      );
    }
  }

  // Toggle the active annexure
  toggleAnnexure(annexure: number): void {
    this.activeAnnexure = annexure;
  }

  // Method to format keys (remove underscores and capitalize words)
  formatKey(key: string): string {
    return key
      .replace(/_/g, ' ')   // Replace underscores with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize each word
  }
}

import { Observable } from 'rxjs';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material';
import { Injectable } from '@angular/core';

import { AppComfirmComponent } from './app-confirm.component';
import {LogUotConfirmation} from './LogOutConfirmation'
import { RemarkComfirmComponent } from './remark-confirm.component';
import { PopUpComponent } from './pop-upcomponent';
import { SwitchAccountComponent } from './switch-account';
interface confirmData {
  title?: string,
  message?: string
}

@Injectable()
export class AppConfirmService {

  constructor(private dialog: MatDialog) { }

  public confirm(data:confirmData = {}): Observable<boolean> {
    data.title = data.title || 'Confirm';
    data.message = data.message || 'Are you sure?';
    let dialogRef: MatDialogRef<AppComfirmComponent>;
    dialogRef = this.dialog.open(AppComfirmComponent, {
      width: '380px',
      disableClose: true,
      data: {title: data.title, message: data.message}
    });
    return dialogRef.afterClosed();
  }


  
  public LogOutOCnform(data:confirmData = {}): Observable<boolean> {
    data.title = data.title || 'Confirm';
    data.message = data.message || 'Are you sure?';
    let dialogRef: MatDialogRef<LogUotConfirmation>;
    dialogRef = this.dialog.open(LogUotConfirmation, {
      width: '380px',
      disableClose: true,
      data: {title: data.title, message: data.message}
    });
    return dialogRef.afterClosed();
  }
  public confirmremakrs(data:confirmData = {}): Observable<boolean> {
    data.title = data.title || 'Confirm';
    data.message = data.message || 'Are you sure?';
    let dialogRef: MatDialogRef<RemarkComfirmComponent>;
    dialogRef = this.dialog.open(RemarkComfirmComponent, {
      width: '380px',
      disableClose: true,
      data: {title: data.title, message: data.message}
    });
    return dialogRef.afterClosed();
  }

  public newpopup(data:confirmData = {}): Observable<boolean> {
    // data.title = data.title || 'Confirm';
    // data.message = data.message || 'Are you sure?';
    let dialogRef: MatDialogRef<PopUpComponent>;
    dialogRef = this.dialog.open(PopUpComponent, {
      width: 'width: 96vw;',
      maxWidth:'96vw',
      disableClose: true,
      data: data
    });
    return dialogRef.afterClosed();
  }
  public switchaccount(data:confirmData = {}): Observable<boolean> {
    // data.title = data.title || 'Confirm';
    // data.message = data.message || 'Are you sure?';
    let dialogRef: MatDialogRef<SwitchAccountComponent>;
    dialogRef = this.dialog.open(SwitchAccountComponent, {
      width: '380px',
      disableClose: true,
      data: {
        title: 'Switch Account',
        message: 'Please choose an account to switch to:',
        options: ['Account 1', 'Account 2'] 
      }
    
    });
    return dialogRef.afterClosed();
  }
}
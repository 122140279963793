import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http'
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject, forkJoin, of, throwError, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment, } from '../../../../environments/environment';
import { AuthorizeService } from '../MyServices/authorize.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private VehicleSearchURL = `${environment.TataapiUri}/api/tataoksourcing/vehicle/search/`;

  private StateURL = `${environment.TataapiUri}/api/states/`;
  private DistrictsURL = `${environment.TataapiUri}/api/districts/ `;
  private CitiesURL = `${environment.TataapiUri}/api/cities/`;
  private TalukasURL = `${environment.TataapiUri}/api/talukas/`;
  private PincodesURL = `${environment.TataapiUri}/api/pincodes/`;

  // private CategoryUrl = `${environment.TataapiUri}/api/v1/masters/category/`;
  // private SubCategoryUrl = `${environment.TataapiUri}/api/v1/masters/subcategory/`;
  // private ControlBindUrl = `${environment.TataapiUri}/api/v1/masters/getControls/`;

  private InventoryUrl = `${environment.TataapiUri}/api/v1/masters/category/`;
  private CategoryUrl = `${environment.TataapiUri}/api/v1/masters/subcategory/`;
  private SubCategoryUrl = `${environment.TataapiUri}/api/v1/masters/sub_category_details/`;
  private ControlBindUrl = `${environment.TataapiUri}/api/v1/masters/getControls/`;
  private CheckDuplicateUrl=`${environment.TataapiUri}/api/v1/accounts/catalogue/check_duplicate_chassis/`


  private InventoryDrpDown = `${environment.TataapiUri}/api/v1/auction/drpinventory/`;
  private PartyTypeDrpDown = `${environment.TataapiUri}/api/v1/masters/get_party_types/`;
  private ReasonList = `${environment.TataapiUri}/api/v1/masters/remark_list/ `;
  
  private RoleTypeDrpDown = `${environment.TataapiUri}/api/v1/masters/get_role_masters/`;
  private PartySubTypeDrpDown = `${environment.TataapiUri}/api/v1/masters/get_party_sub_types/`;
  private ValidateUserName = `${environment.TataapiUri}/api/v2/accounts/validate_user/`;

  private DashboardDataApi = `${environment.TataapiUri}/api/v1/accounts/dashboard/`;

  //private StockVcData = `${environment.TataapiUri}/api/tataok/get/instockvehicle/detailed-info/v2/`;
  private StockVcData = `${environment.TataapiUri}/api/tataok/get/allvehicle-tml-ntml/`;
  private ChassisVcInfo=`${environment.TataapiUri}/api/tataok/vehicle/info/`;
  private OspleadList=`${environment.TataapiUri}/api/v2/accounts/catalogue/get_leads/`;
  private GetEnquiryListData=`${environment.TataapiUri}/api/v1/accounts/catalogue/get_leads/`;
  private GetSourceOfContact =`${environment.TataapiUri}/api/v1/masters/get_source_of_contact/`;
  private webRoletypeDrpDwn = `${environment.TataapiUri}/api/v1/ecom/admin/other_user/roles_list/ `;
  private PositionDrpDwn = `${environment.TataapiUri}/api/v1/ecom/admin/position_list/`;
  private InsertWebPageMappingURL = `${environment.TataapiUri}/api/v1/ecom/admin/insert_customer_role_permissions/`;
  private InsertWebPannelUpdateRoleMasterURL = `${environment.TataapiUri}/api/v1/ecom/admin/other_user/add_role/`;
  private GetPostionListURL = `${environment.TataapiUri}/api/v1/ecom/admin/position_list/`;
  private InsertPagemappingURL = `${environment.TataapiUri}/api/v1/ecom/admin/list/UpdateRoleMapping/`;
  private GetVehicleApplication=`${environment.ospProd}/api/v1/cv_online/get_vehicle_applications/`;
  private ActiveDeactiveInv=`${environment.TataapiUri}/api/v1/inventory/active_deactive/`;
  private PagemasterDataURL = `${environment.TataapiUri}/api/v1/masters/get_mapped_pages_with_all_pages/`;
  private InsertUpdatePagemappingURL = `${environment.TataapiUri}/api/v1/masters/update_page_mapping/`;
  private InsertUpdatePositionMasppingURL = `${environment.TataapiUri}/api/v1/masters/update_or_create_party_type_and_sub_type/`;
  private GetSubpartyListURL = `${environment.TataapiUri}/api/v1/masters/get_party_type_mapping/`;

  private GetInventoryListUrl=`${environment.TataapiUri}/api/v2/inventory/list_inventory/`
  private ViewInventoryUrl=`${environment.TataapiUri}/api/v2/inventory/view_inventory/`
  private AddInventoryUrl=`${environment.TataapiUri}/api/v2/inventory/add_inventory/`
  private EditInventoryUrl=`${environment.TataapiUri}/api/v2/auction/edit_single_new_inventory/`
  private GetPermissionsPageListUrl=`${environment.TataapiUri}/api/v1/masters/get_mapped_pages_with_all_pages/`;

  private GetPartyTypeListUrl=`${environment.TataapiUri}/api/v1/masters/get_party_types/`
  private GetPartySubTypeListUrl=`${environment.TataapiUri}/api/v1/masters/get_party_sub_types/`
  
  private UpdatePageStatusUrl=`${environment.TataapiUri}/api/v1/masters/update_page_mapping/`
  
  private vehicleImageUploadUrl = `${environment.TataapiUri}/api/v2/front_end/upload_vehicle_files/`;

  private getLocation = `${environment.TataapiUri}/api/states/`

  private district = `${environment.TataapiUri}/api/districts/`
  private getAllApplication = `${environment.ospProd}/api/v1/cv_online/get_vehicle_applications/`
  private getAllBrands = `${environment.ospProd}/api/v1/cv_online/no_auth/dropdown/`;
  private updateVehicleValidityURL = `${environment.TataapiUri}/api/v2/inventory/close_inventory/`;
  private modelListURL = `${environment.TataapiUri}/api/v2/osp/masters/get_model_list_v2/`;
  // private modelListURL = `${environment.ospProd}/api/v2/cv_online/get_models_list/`;
  private getLeadURL=`${environment.TataapiUri}/api/v2/accounts/catalogue/get_leads/`;
  private removeVehcileImgURL=`${environment.TataapiUri}/api/v2/inventory/remove_vehicle_file/`;
  private approveurl=`${environment.TataapiUri}/api/v2/inventory/osp/dealer_vehicle_approved_disapproved/`;
  private TSMROLELIST=`${environment.TataapiUri}/api/v2/inventory/osp/dealer_pending_vehicle_list/`;
  private switchAccountApi=`${environment.TataapiUri}/api/v2/accounts/get_page_permissions/`;

  private activeInactive=`${environment.TataapiUri}/api/v2/inventory/active_inactive_vehicle/`;
  private activeInactiveosp=`${environment.TataapiUri}/api/v2/inventory/osp/osp_active_inactive_vehicle/`;

  private updateprice=`${environment.TataapiUri}/api/v2/inventory/update_dealer_price/`;
  private registrationdetails = `${environment.TataapiUri}/api/v2/get_vehicle_info_data/`;
private addagreeMent = `${environment.TataapiUri}/api/v1/inventory/agreement/create_agreement/`;
private EditagreeMent = `${environment.TataapiUri}/api/v1/inventory/agreement/update_agreement/`;

private agreeMentList = `${environment.TataapiUri}/api/v1/inventory/agreement/list_agreement/`;

private sendOtp = `${environment.TataapiUri}/api/tcl/common/send/otp/'`;
private verifyOtp = `${environment.TataapiUri}/api/common/get/verify_otp/`;
private agreementUserAddress = `${environment.TataapiUri}/api/v2/get_adress_of_dealer/`;
private readdagreeMent = `${environment.TataapiUri}/api/v1/inventory/agreement/create_agreement_pdf_and_email_send/`;
 private getStatusChange = `${environment.TataapiUri}/api/v2/inventory/osp/osp_change_lead_status/`
  private getStatus = `${environment.TataapiUri}/api/v2/masters/osp/get_create_lead_stages/`
  private getHistroy = `${environment.TataapiUri}/api/v2/inventory/osp/osp_change_lead_status_history/`
  private qcList = `${environment.TataapiUri}/api/v2/inventory/qa_list/`
  private qcDataUpdate = `${environment.TataapiUri}/api/v2/inventory/osp/dealer_vehicle_approved_disapproved/`
  private InventoryStatus = new BehaviorSubject<string>('');
  private QcPopupData = new BehaviorSubject<string>('');
  cast = this.InventoryStatus.asObservable();
  QcVehicleDetail = this.QcPopupData.asObservable();
  constructor(private http: HttpClient, private auth: AuthorizeService) { }
  getAuditButtonStatus(data) {
    this.InventoryStatus.next(data);
  }

  getQCVehicleDetail(data){
    this.QcPopupData.next(data)
  }
  private getToken = this.auth.getToken();
  // BindCategory(): Observable<any> {
  //   return this.http.post<any>(this.CategoryUrl, '').pipe(catchError(this.handlError));
  // }

  // BindSubCategory(data: any): Observable<any> {
  //   return this.http.post<any>(this.SubCategoryUrl, data).pipe(catchError(this.handlError));
  // }
  BindSourceOfContact(): Observable<any> {
    return this.http.post<any>(this.GetSourceOfContact, '').pipe(catchError(this.handlError));
  }
  ActiveDeactiveInventory(data): Observable<any> {
    return this.http.post<any>(this.ActiveDeactiveInv,data).pipe(catchError(this.handlError));
  }
  CheckDuplicateChassis(data): Observable<any> {
    return this.http.post<any>(this.CheckDuplicateUrl,data).pipe(catchError(this.handlError));
  }
  BindEnquiryListData(data): Observable<any> {
    return this.http.post<any>(this.GetEnquiryListData,data).pipe(catchError(this.handlError));
  }
  BindStockVehicle(data): Observable<any> {
    return this.http.post<any>(this.StockVcData,data).pipe(catchError(this.handlError));
  }
  // BindStockVehicle(data): Observable<any> {
  //   return this.http.post<any>(this.StockVcData,data).pipe(catchError(this.handlError));
  // }

  BindChassisVcData(data): Observable<any> {
    return this.http.post<any>(this.ChassisVcInfo,data).pipe(catchError(this.handlError));
  }

  BindVehicleApplication(data:any): Observable<any> {
    return this.http.post<any>(this.GetVehicleApplication, data).pipe(catchError(this.handlError));
  }

  BindInventory(): Observable<any> {
    return this.http.post<any>(this.InventoryUrl, '').pipe(catchError(this.handlError));
  }

  BindCategory(data: any): Observable<any> {
    return this.http.post<any>(this.CategoryUrl, data).pipe(catchError(this.handlError));
  }
  BindSubCategory(data: any): Observable<any> {
    return this.http.post<any>(this.SubCategoryUrl, data).pipe(catchError(this.handlError));
  }

  BindControls(Data: any): Observable<any> {
    return this.http.post<any>(this.ControlBindUrl, Data).pipe(catchError(this.handlError));
  }

  BindPartyType(Data: any): Observable<any> {
    return this.http.post<any>(this.PartyTypeDrpDown, Data).pipe(catchError(this.handlError));
  }
//DashBoard Data
  DashboardData(Data: any): Observable<any> {
    return this.http.post<any>(this.DashboardDataApi, Data).pipe(catchError(this.handlError));
  }

//
  BindReasonType(Data: any): Observable<any> {
    return this.http.post<any>(this.ReasonList, Data).pipe(catchError(this.handlError));
  }

  BindRoleType(Data: any): Observable<any> {
    return this.http.post<any>(this.RoleTypeDrpDown, Data).pipe(catchError(this.handlError));
  }
  
  BindSubPartyType(Data: any): Observable<any> {
    return this.http.post<any>(this.PartySubTypeDrpDown, Data).pipe(catchError(this.handlError));
  }

  ValidatUserName(Data: any): Observable<any> {
    return this.http.post<any>(this.ValidateUserName, Data).pipe(catchError(this.handlError));
  }

  BindInventoryDrpdown(Data: any): Observable<any> {
    return this.http.post<any>(this.InventoryDrpDown, Data).pipe(catchError(this.handlError));
  }

  BindState(Data: any): Observable<any> {
    return this.http.post<any>(this.StateURL, null).pipe(catchError(this.handlError));
  }

  // BindState() {
  //   return this.http.post(this.StateURL, {
  //     headers:{"Content-Type": "application/json"},
  //    observe: 'response'
  //  }).pipe(catchError(err => of(err)));
  //}

  BindDistrict(data: any): Observable<any> {
    return this.http.post(this.DistrictsURL, data).pipe(catchError(err => of(err)));
  }


  BindCity(data: any): Observable<any> {
    return this.http.post(this.CitiesURL, data, { headers: { "Content-Type": "application/json" } }).pipe(catchError(err => of(err)));
  }

  BindTaluka(data: any): Observable<any> {
    return this.http.post(this.TalukasURL, data, { headers: { "Content-Type": "application/json" } }).pipe(catchError(err => of(err)));
  }

  BindPincode(data: any): Observable<any> {
    return this.http.post(this.PincodesURL, data, { headers: { "Content-Type": "application/json" } }).pipe(catchError(err => of(err)));
  }


 //For WEb pannelPermission
 BindWebRoleType(Data: any):Observable<any>
 {
   return this.http.post(this.webRoletypeDrpDwn,Data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)));
 }

 BindPosition(Data: any):Observable<any>
  {
    return this.http.post(this.PositionDrpDwn,Data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)));
  }

  //FOr WEb Pannel 
  InsertWebPageMapping(Data: any):Observable<any>
  {
    return this.http.post(this.InsertWebPageMappingURL,Data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)));
  }

  
//FOr WEb Pannel 
InsertWebPannelUpdateRoleMaster(Data: any):Observable<any>
{
  return this.http.post(this.InsertWebPannelUpdateRoleMasterURL,Data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)));
}

  
  GetPostionList(Data: any):Observable<any>
  {
    return this.http.post(this.GetPostionListURL,Data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)));
  }


  InsertPageMapping(Data: any):Observable<any>
  {
    return this.http.post(this.InsertPagemappingURL,Data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)));
  }
  


  getUserCode(): string {
    if (localStorage.getItem('loginData') && localStorage.getItem('loginData') != "null") {
      var i = JSON.parse(localStorage.getItem('loginData'))
     return i.user_id;
    }
    else {
      return null;
    }
  }
  getUserCode1(): string {
    if (localStorage.getItem('loginData') && localStorage.getItem('loginData') != "null") {
      var i = JSON.parse(localStorage.getItem('loginData'))
     return i.user_code;
    }
    else {
      return null;
    }
  }
  getDealerCode(): string {
    if (localStorage.getItem('loginData') && localStorage.getItem('loginData') != "null") {
      var i = JSON.parse(localStorage.getItem('loginData'))
      //return i[0].dealer_code;
      return i.username;
    }
    else {
      return null;
    }
  }

  getUserDetails(): string {
    if (localStorage.getItem('loginData') && localStorage.getItem('loginData') != "null") {
      var i = JSON.parse(localStorage.getItem('loginData'))
     return i;
    }
    else {
      return null;
    }
  }

  getFullName(): string {
    if (localStorage.getItem('loginData') && localStorage.getItem('loginData') != "null") {
      var i = JSON.parse(localStorage.getItem('loginData'))
     // return i[0].dsm_name;

     let Name=";"
     if(i.first_name =null)
      {
        Name= i.first_name +' '+ i.last_name ;
      }
      else{
        Name= i.username;
      }
     return Name ;
    // return i.username;
    }
    else {
      return null;
    }
  }

  VehicleSearch(data): Observable<any> {
    return this.http.post<any>(this.VehicleSearchURL,data).pipe(catchError(this.handlError));
  }

  getRole(): string {
    if (localStorage.getItem('loginData') && localStorage.getItem('loginData') != "null") {
      var i = JSON.parse(localStorage.getItem('loginData'))
      return i.role_name;
    }
    else {
      return null;
    }
  }
  GetPagemasterData(Data: any):Observable<any>
  {
    return this.http.post(this.PagemasterDataURL,Data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)));
  }  

  Insertupdate_page_mapping(Data: any):Observable<any>
  {
    return this.http.post(this.InsertUpdatePagemappingURL,Data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)));
  }
  
  InsertUpdateSubpartyMapping(Data: any):Observable<any>
  {
    return this.http.post(this.InsertUpdatePositionMasppingURL,Data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)));
  }

  GetSubpartyMappingList(Data: any):Observable<any>
  {
    return this.http.post(this.GetSubpartyListURL,Data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)));
  }

  GetInventoryList(Data: any):Observable<any>
  {
    return this.http.post(this.GetInventoryListUrl,Data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)));
  }
  GetPermissionsPageList(Data: any):Observable<any>
  {
    return this.http.post(this.GetPermissionsPageListUrl,Data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)));
  }
  GetPartyTypeList():Observable<any>
  {
    return this.http.post(this.GetPartyTypeListUrl, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)));
  }
  GetPartySubTypeList(Data: any):Observable<any>
  {
    return this.http.post(this.GetPartySubTypeListUrl,Data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)));
  }
  AddInventoryList(Data:any):Observable<any>{
    return this.http.post(this.AddInventoryUrl,Data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)));
  }

  EditInventoryList(Data:any):Observable<any>{
    return this.http.post(this.EditInventoryUrl,Data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)));
  }

  getLocations() {
    return this.http.post(this.getLocation,  {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)))
  }

  getDistrctList(data: any) {
    return this.http.post(this.district, data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)))
  }
  UpdatePageStatusList(Data:any):Observable<any>{
    return this.http.post(this.UpdatePageStatusUrl,Data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)));
  }

  uploadImage(Data:any):Observable<any>{
    return this.http.post(this.vehicleImageUploadUrl,Data).pipe(catchError(err => of(err)));
  }
  p
getAllApplications(data: any): Observable<any> {
    return this.http.post(this.getAllApplication, data).pipe(catchError(err => of(err)))
  }

  getAllBrandMaster(data: any): Observable<any> {
    return this.http.post(this.getAllBrands, data).pipe(catchError(err => of(err)))
  }
  updateVehicleValidity(data: any) {
    return this.http.post(this.updateVehicleValidityURL, data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)))
  }

  getModelList(data: any) {
    return this.http.post(this.modelListURL, data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)))
  }
  getLeadList(Data: any):Observable<any>
  {
    return this.http.post(this.getLeadURL,Data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)));
  }
  RemoveInventoryById(Data: any):Observable<any>
  {
    return this.http.post(this.removeVehcileImgURL,Data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)));
  }
  
  ospLeadsList(Data:any):Observable<any>{
    return this.http.post(this.OspleadList,Data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)));
  } 

  approveCall(Data:any):Observable<any>{
    return this.http.post(this.approveurl,Data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)));
  }
  TSMLeadsList(Data:any):Observable<any>{
    return this.http.post(this.TSMROLELIST,Data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)));
  }
  getQcList(Data:any):Observable<any>{
    return this.http.post(this.qcList,Data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)));
  } 
  switchAccount(Data:any):Observable<any>{
    return this.http.post(this.switchAccountApi,Data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)));
  } 

  activenInactive(Data:any):Observable<any>{
    return this.http.post(this.activeInactive,Data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)));
  } 
  activenInactiveOSP(Data:any):Observable<any>{
    return this.http.post(this.activeInactiveosp,Data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)));
  } 
  UpdatePrice(Data:any):Observable<any>{
    return this.http.post(this.updateprice,Data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)));
  } 
  getRegistrationData(data: any):Observable<any> {
    return this.http.post(this.registrationdetails, data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)))
  }
  handlError(error: HttpErrorResponse) {
    return throwError(error)
  }

  addagreement(data: any):Observable<any> {
    return this.http.post(this.addagreeMent, data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)))
  }
  agreementListData(data:any):Observable<any> {
    return this.http.post(this.agreeMentList,data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)))
  }
  editagreement(data: any):Observable<any> {
    return this.http.post(this.EditagreeMent, data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)))
  }
  sendOtpUser(data: any):Observable<any> {
    return this.http.post(this.sendOtp, data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)))
  }
  VerfifyOtpUser(data: any):Observable<any> {
    return this.http.post(this.verifyOtp, data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)))
  }
  agreementAddress(data: any):Observable<any> {
    return this.http.post(this.agreementUserAddress, data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)))
  }
  readdagreement(data: any):Observable<any> {
    return this.http.post(this.readdagreeMent, data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)))
  }

  getStatusdata(data: any):Observable<any> {
    return this.http.post(this.getStatus, data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)))
  }
  getStatusChangedata(data: any):Observable<any> {
    return this.http.post(this.getStatusChange, data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)))
  }

  getHistoryStatus(data: any):Observable<any> {
    return this.http.post(this.getHistroy, data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)))
  }

  updateQcDataStatus(data: any):Observable<any> {
    return this.http.post(this.qcDataUpdate, data, {headers:{"Content-Type":"application/json"}}).pipe(catchError(err => of(err)))
  }
}

import { element } from 'protractor';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileUploadService } from 'app/file-upload/file-upload.service';
import { SubCategoryDetails } from 'app/shared/models/models';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { CommonService } from 'app/shared/services/MyServices/common.service';
import { Subscription, timer } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-agreement',
  templateUrl: './add-agreement.component.html',
  styleUrls: ['./add-agreement.component.scss']
})
export class AddAgreementComponent implements OnInit {
  isForm: boolean = false;
  UserAddress: any;
  applicationData: any;
  City: any;

  constructor(public dialog: MatDialogRef<AddAgreementComponent>,private fb: FormBuilder,private commonservices: CommonService, private loader: AppLoaderService,private fileservice :FileUploadService, @Inject(MAT_DIALOG_DATA) public data) { }
  userForm: FormGroup;
  annexure_2Form:FormGroup;
  annexure2Data: any[] = [];
  annexure_1Form:FormGroup;
  annexure1Data: any[] = [];
  // uploadedDocuments: { file: File; url: string; position: string;type?:any }[] = [];
  // this.documentsForm = this.fb.group({
    //   vehicle_delivery_receipt: [''],
    //   vehicle_rc_copy: [''],
    //   owner_id_proof: [''],
    //   permanent_address_proof: [''],
    //   current_address_proof: [''],
    //   loan_agreement_copy: [''],
    //   motor_vehicle_tax: [''],
    //   insurance_copy: [''],
    //   certificate_of_fitness: [''],
    //   vehicle_permit: [''],
    //   signature_verification: ['']
    // });
  documents: string[] = [
    'vehicle_delivery_receipt',
    'vehicle_rc_copy',
    'owner_id_proof',
    'permanent_address_proof',
    'current_address_proof',
    'loan_agreement_copy',
    'insurance_copy',
    'insurance_copy',
    'certificate_of_fitness',
    'vehicle_permit',
    'signature_verification',
    'motor_vehicle_tax'
  ];
  editingIndex: number | null = null;
  editIndex: number | null = null;
  breakpoint: number;
sub = new Subscription
ImagePositionTypeList = [
    { name: "Vehicle delivery receipt copy", value: "vehicle_delivery_receipt_copy", isSelected: false, position: NaN },
  { name: "Vehicle RC copy", value: "vehicle_rc_copy", isSelected: false, position: NaN },
  { name: "Vehicle Owner ID proof (e.g. Pan Card/ Form 60)", value: "vehicle_owner_id_proof", isSelected: false, position: NaN },
  { name: "Vehicle Owner permanent address proof (e.g. Aadhar Card copy)", value: "vehicle_owner_permanent_address_proof", isSelected: false, position: NaN },
  { name: "Proof of vehicle owner’s current address (if diff. from Permeant address)", value: "vehicle_owner_current_address_proof", isSelected: false, position: NaN },
  { name: "Loan Agreement Copy", value: "loan_agreement_copy", isSelected: false, position: NaN },
  { name: "Motor Vehicle Tax", value: "motor_vehicle_tax", isSelected: false, position: NaN },
  { name: "Insurance Copy", value: "insurance_copy", isSelected: false, position: NaN },
  { name: "Certificate of fitness", value: "certificate_of_fitness", isSelected: false, position: NaN },
  { name: "Vehicle Permit", value: "vehicle_permit", isSelected: false, position: NaN },
  { name: "Signature verification", value: "signature_verification", isSelected: false, position: NaN },

]
loadBodyData = [
  "Full Deck- Steel",
  "Container- Steel",
  "Half Body Wooden",
  "Full Body Wooden- Punjab",
  "Flat Platform",
  "Goods Carrier",
  "SS Tanker",
  "Water Tanker",
  "MS Tanker",
  "20' Flat Bed Trailer",
  "40' Flat Bed Trailer",
  "Car Carrier",
  "Open Cargo Body",
  "Scooter Carrier",
  "RMC/Transit Mixer",
  "16 CuM Tipper",
  "18 CuM Tipper",
  "10 CuM Tipper",
  "Cargo High Deck",
  "Passenger- 8 Seater",
  "Passenger- 5 Seater",
  "Tipper Body",
  "Bus-Passengers",
  "40' Trailer/Trolley",
  "40' +Trailer/Trolley",
  "Angle Body",
  "Half Deck- Steel",
  "Full Deck- Steel",
  "Container- Steel",
  "Half Body Wooden",
  "Full Body Wooden- Punjab",
  "Flat Platform",
  "Goods Carrier",
  "SS Tanker",
  "Water Tanker",
  "MS Tanker",
  "20' Flat Bed Trailer",
  "40' Flat Bed Trailer",
  "Car Carrier",
  "Open Cargo Body",
  "Scooter Carrier",
  "RMC/Transit Mixer",
  "16 CuM Tipper",
  "18 CuM Tipper",
  "10 CuM Tipper",
  "Cargo High Deck",
  "Passenger- 8 Seater",
  "Passenger- 5 Seater",
  "Tipper Body",
  "Bus-Passengers",
  "40' Trailer/Trolley",
  "40' +Trailer/Trolley"
]
accountTypes: string[] = ['Savings', 'Current','Cash Credit'];
totalAmount: number = 0;
InventoryType:any[]=[];
CategoryArray:any[] = [];
SubCategoryArray:any[] = [];
ModelsArray:any[]=[];
cat_id: string;
sub_cat_id: string;
lob:any;
ppl:any
CategoryArray2:any[] = [];
SubCategoryArray2:any[] = [];
ModelsArray2:any[]=[];
cat_id2: string;
sub_cat_id2: string;
lob2:any;
ppl2:any
checkBoxchekedlogin:boolean=false;
isOtpSent: boolean = false;
isOtpVerified: boolean = false;
otp: string = '';
phoneNumber: string = '9372470482';
msgRefNo: number = 0;
documentsForm: FormGroup;

isSendCodeDisabled: boolean = false;
timer: number = 60;  // Timer for countdown
uploadedDocuments: { file: File | null; url: string | null; position: string; type?: string; flag?:any }[] = this.documents.map((doc) => ({
  file: null,
  url: null,
  position: doc,
  flag:false
}));
SessionData: any;
  ngOnInit(): void {
    this.SessionData = this.commonservices.getUserDetails();

    this.getAddress();
    this.getApplicationData();
    // this.uploadedDocuments = this.documents.map((_, index) => ({
    //   file: null,
    //   url: '',
    //   position: index.toString()
    // }));
    this.documents = [
      'vehicle_delivery_receipt',
      'vehicle_rc_copy',
      'owner_id_proof',
      'permanent_address_proof',
      'current_address_proof',
      'loan_agreement_copy',
      'insurance_copy',
      'insurance_copy',
      'certificate_of_fitness',
      'vehicle_permit',
      'signature_verification',
      'motor_vehicle_tax'
    ];
    this.getInventory()
    this.getCategory1(3,"set");
    this.getCategory2(3,"set");
    this.userForm = this.fb.group({
      full_name: [this.data.flag == 'EDIT' ? this.data.data.full_name : '', Validators.required],
      director_name: [this.data.flag == 'EDIT' ? this.data.data.director_name :'', Validators.required],
      mobile_number: [this.data.flag == 'EDIT' ? this.data.data.mobile_number :'', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      email_id: [this.data.flag == 'EDIT' ? this.data.data.email_id :'', [Validators.required, Validators.email]],
      perment_address: [this.data.flag == 'EDIT' ? this.data.data.perment_address :'',Validators.required],
      current_resident_address: [this.data.flag == 'EDIT' ? this.data.data.current_resident_address :''],
      dealer_name: [this.data.flag == 'EDIT' ? this.data.data.dealer_name :this.SessionData.organization_name],
      dealer_code: [this.data.flag == 'EDIT' ? this.data.data.dealer_code :this.SessionData.distributor_code],
      dealer_position: [this.data.flag == 'EDIT' ? this.data.data.dealer_position :this.SessionData.position],
      park_and_sell_period: [this.data.flag == 'EDIT' ? this.data.data.park_and_sell_period :'',Validators.required],
      vehicle_base_price: [this.data.flag == 'EDIT' ? this.data.data.vehicle_base_price :'', Validators.required],
      // delaer_ship_service_charges: [this.data.flag == 'EDIT' ? this.data.data.delaer_ship_service_charges :''],
      dealer_current_address: [this.data.flag == 'EDIT' ? this.data.data.dealer_current_address :this.UserAddress],
      dealer_permanent_address: [this.data.flag == 'EDIT' ? this.data.data.dealer_permanent_address :this.UserAddress],
      notice_period: [this.data.flag == 'EDIT' ? this.data.data.notice_period :''],

      account_name: [this.data.flag == 'EDIT' ? this.data.data.agreement_bank_details[0].account_name :'', Validators.required],
      bank_name: [this.data.flag == 'EDIT' ? this.data.data.agreement_bank_details[0].bank_name :'', Validators.required],
      branch_name: [this.data.flag == 'EDIT' ? this.data.data.agreement_bank_details[0].branch_name :'', Validators.required],
      branch_address: [this.data.flag == 'EDIT' ? this.data.data.agreement_bank_details[0].branch_address :'', Validators.required],
      branch_city: [this.data.flag == 'EDIT' ? this.data.data.agreement_bank_details[0].branch_city :'', Validators.required],
      branch_pin_code: [this.data.flag == 'EDIT' ? this.data.data.agreement_bank_details[0].branch_pin_code :'', Validators.required],
      account_number: [this.data.flag == 'EDIT' ? this.data.data.agreement_bank_details[0].account_number :'', Validators.required],
      ifsc_code: [this.data.flag == 'EDIT' ? this.data.data.agreement_bank_details[0].ifsc_code :'', Validators.required],
      branch_code: [this.data.flag == 'EDIT' ? this.data.data.agreement_bank_details[0].branch_code :''],
      micr_no: [this.data.flag == 'EDIT' ? this.data.data.agreement_bank_details[0].micr_no :''],
      account_type: [this.data.flag == 'EDIT' ? this.data.data.agreement_bank_details[0].account_type :'', Validators.required],
      rtgs_transfer: [this.data.flag == 'EDIT' ? this.data.data.agreement_bank_details[0].rtgs_transfer :''],
      neft_transfer: [this.data.flag == 'EDIT' ? this.data.data.agreement_bank_details[0].neft_transfer :''],



      post_sales_vehicle_payment_realese: [this.data.flag == 'EDIT' ? this.data.data.post_sales_vehicle_payment_realese :'',[Validators.required, Validators.min(0), Validators.max(100)]],
      post_sales_vehicle_payment_realese_period: [this.data.flag == 'EDIT' ? this.data.data.post_sales_vehicle_payment_realese_period :''],
      vehicle_balance_payment: [this.data.flag == 'EDIT' ? this.data.data.vehicle_balance_payment :'',[Validators.required, Validators.min(0), Validators.max(100)]],
      vehicle_balance_payment_realese_period:[this.data.flag == 'EDIT' ? this.data.data.vehicle_balance_payment_realese_period :''],
      vehicle_compensation_return_period:[this.data.flag == 'EDIT' ? this.data.data.vehicle_compensation_return_period :''],
      fixed_commission: [this.data.flag == 'EDIT' ? this.data.data.fixed_commission :''],
      sales_commission: [this.data.flag == 'EDIT' ? this.data.data.sales_commission :'',[Validators.required, Validators.min(1), Validators.max(100)]],

      is_annexure2_available: [this.data.flag == 'EDIT' ? this.data.data.is_annexure2_available :''],





      // // imgFile:[''],
      // annexure_1: this.fb.array([this.createAnnexure1()]),
      // annexure_2: this.fb.array([this.createAnnexure2()])
    });
    this.userForm.get('post_sales_vehicle_payment_realese').valueChanges.subscribe((value: string) => {
      const payment = parseFloat(value);
    
      if (isNaN(payment) || payment <= 0 || payment > 100 || value === '') {
        this.userForm.get('vehicle_balance_payment').setValue('', { emitEvent: false });
      } else {
        const balance = 100 - payment;
        this.userForm.get('vehicle_balance_payment').setValue(Math.round(balance), { emitEvent: false });
      }
    });
    
    this.annexure_1Form = this.fb.group({
      registration_number: ['', [Validators.required, Validators.minLength(9),Validators.maxLength(11)]],
      chassis_number: ['', [Validators.required,Validators.minLength(16)]],
      make: ['', Validators.required],
      sub_cat:['',Validators.required],
      sub_cat_detail: ['',Validators.required],
      model: ['', Validators.required],
      variant: [''],
      km_run: ['', [Validators.required]],
      color: [''],
      insurance_status: [''],
      no_of_tyres: ['', Validators.required],
      engine_no: ['', Validators.required],
      hypothecation_status: [''],
      expected_price :[],
      body_type : [''],
      application:[''],
      // expected_average_price:[]
    })
    this.annexure_2Form = this.fb.group({
      registration_number: ['', [Validators.required, Validators.minLength(9),Validators.maxLength(11)]],
      make: ['', Validators.required],
      sub_cat:['',Validators.required],
      sub_cat_detail: ['',Validators.required],
      model: ['', Validators.required],
      km_run: ['', [Validators.required]],
      color: [''],
      job_to_carried: [''],
      est_parts_amt: ['', Validators.required],
      est_labour_amt: ['', Validators.required],
      total_amt: ['']
    })
    // this.documentsForm = this.fb.group({
    //   vehicle_delivery_receipt: [''],
    //   vehicle_rc_copy: [''],
    //   owner_id_proof: [''],
    //   permanent_address_proof: [''],
    //   current_address_proof: [''],
    //   loan_agreement_copy: [''],
    //   motor_vehicle_tax: [''],
    //   insurance_copy: [''],
    //   certificate_of_fitness: [''],
    //   vehicle_permit: [''],
    //   signature_verification: ['']
    // });
    if (this.data.flag == 'EDIT') {
      this.annexure1Data=this.data.data.agreement_vehicle_details_annexure1
      this.annexure2Data=this.data.data.agreement_vehicle_details_annexure2
      this.uploadedDocuments=this.data.data.agreement_documents
     
      this.documents=[]
      if (this.uploadedDocuments.length > 0) {
        this.uploadedDocuments.forEach((element:any) => {
          this.documents.push(element.name)
        });
      }

      this.uploadedDocuments = this.data.data.agreement_documents.map((doc) => ({
        file: null, // No file for existing documents
        url: doc.path, // URL from API
        position: doc.name, // Position from API
        type: doc.extension, //
        flag:doc.flag, //
      }));

if (this.data.data.is_annexure2_available == true) {
  this.onCheckboxChangelogin(true)
  
}

    }
    this.annexure_2Form.valueChanges.subscribe((values) => {
      const partsAmt = parseFloat(values.est_parts_amt) || 0;
      const labourAmt = parseFloat(values.est_labour_amt) || 0;
      this.totalAmount = partsAmt + labourAmt;
    });
  }
  closeModal(){
    this.dialog.close('cancle');
  }


  // Create Annexure 1 Form Group
  createAnnexure1(): FormGroup {
    return this.fb.group({
      registration_number: ['', Validators.required],
      chassis_number: ['', Validators.required],
      make: ['', Validators.required],
      model: ['', Validators.required],
      variant: [''],
      km_run: ['', [Validators.required]],
      color: [''],
      insurance_status: [''],
      no_of_tyres: ['', Validators.required],
      engine_no: ['', Validators.required],
      hypothecation_status: ['']
    });
  }

  // Create Annexure 2 Form Group
  createAnnexure2(): FormGroup {
    return this.fb.group({
      registration_number: ['', Validators.required],
      make: ['', Validators.required],
      model: ['', Validators.required],
      km_run: ['', [Validators.required]],
      color: [''],
      job_to_carried: [''],
      est_parts_amt: ['', Validators.required],
      est_labour_amt: ['', Validators.required],
      total_amt: ['', Validators.required]
    });
  }

  

  // Submit Form
  onSubmit(): void {

    const isValid = this.validateDocuments(this.uploadedDocuments);
  
  if (!isValid) {
   Swal.fire('Please add documet where you selected yes')
    return; // Stop submission
  }
    // Check if documents are uploaded
    // if (this.uploadedDocuments.length > 0) {
    //   // Check if any document has an empty position
    //   const hasEmptyPosition = this.uploadedDocuments.some(doc => !doc.position);
    //   // if (hasEmptyPosition) {
    //   //   Swal.fire('Error', 'Please assign a position to all uploaded images.', 'error');
    //   //   return;
    //   // }
  
    //   // Check for duplicate positions
    //   const positions = this.uploadedDocuments.map(doc => doc.position);
    //   const hasDuplicatePositions = positions.length !== new Set(positions).size;
    //   // if (hasDuplicatePositions) {
    //   //   Swal.fire('Error', 'Each uploaded image must have a unique position.', 'error');
    //   //   return;
    //   // }
    // }
  
    // Proceed with form validation and submission
    if (this.userForm.valid) {
    
  
      if (this.data.flag === 'EDIT') {
        this.editAgreement(this.userForm.value);
      } else {
        this.submitAgreement(this.userForm.value);
      }
    } else {
      this.userForm.markAllAsTouched();
      Swal.fire('Error', 'Form is invalid', 'error');
    }
  }
  
  openPdf(data: any) {
 
    if (this.data.flag == 'EDIT' && data.type == "pdf") {
      window.open(data.url, '_blank');
    }
  }
 submitAgreement(data:any){
  // this.annexure1Data = this.annexure1Data.map(({ category, sub_category, ...rest }) => rest);
  // this.annexure2Data = this.annexure2Data.map(({ category, sub_category, ...rest }) => rest);
  let payload :any= {
    
    "user_data": {
      "full_name": data.full_name,
      "director_name":data.director_name,
      "mobile_number": data.mobile_number,
      "email_id": data.email_id,
      "perment_address": data.perment_address,
      "current_resident_address": data.current_resident_address,
      "dealer_name": data.dealer_name,
      "dealer_code": data.dealer_code,
      "dealer_position": data.dealer_position,
      "park_and_sell_period":data.park_and_sell_period,
      "vehicle_base_price":data.vehicle_base_price,
      // "delaer_ship_service_charges":data.delaer_ship_service_charges,
      // "fixed_commission":data.fixed_commission,
      // "vehicle_payment_realese": data.vehicle_payment_realese,
      // "vehicle_payment_realese_period":data.vehicle_payment_realese,
      // "balance_payment_realese_period": data.balance_payment_realese_period,
      "post_sales_vehicle_payment_realese": data.post_sales_vehicle_payment_realese,
      "post_sales_vehicle_payment_realese_period":data.post_sales_vehicle_payment_realese_period,
      "vehicle_balance_payment":data.vehicle_balance_payment,
      "vehicle_balance_payment_realese_period":data.vehicle_balance_payment_realese_period,
      "vehicle_compensation_return_period":data.vehicle_compensation_return_period,
      "fixed_commission":data.fixed_commission,
      "sales_commission":data.sales_commission,
      "dealer_current_address":data.dealer_current_address,
      "dealer_permanent_address": data.dealer_permanent_address,
      "notice_period":data.notice_period,
  },
  "bank_detail": {
    "account_name": data.account_name,
    "bank_name": data.bank_name,
    "branch_name": data.branch_name,
    "branch_address": data.branch_address,
    "branch_city": data.branch_city,
    "branch_pin_code": data.branch_pin_code,
    "account_number": data.account_number,
    "ifsc_code": data.ifsc_code,
    "branch_code":data.branch_code,
    "micr_no":data.micr_no,
    "account_type":data.account_type,
    "rtgs_transfer":data.rtgs_transfer,
    "neft_transfer":data.neft_transfer
},
// "annexure_1":data.annexure_1,
// "annexure_2":data.annexure_2
"annexure_1": this.annexure1Data,
// "annexure_2":this.annexure2Data
  }
  if (this.checkBoxchekedlogin) {
    payload.annexure_2=this.annexure2Data
  }
  // if(this.data.flag == 'EDIT'){
  //   payload['agreement_code'] = this.data.data.agreement_code
  // }
  this.loader.open();
  this.sub.add(this.commonservices.addagreement(payload).subscribe({
    next: (res: any) => {
      if (res.success) {
        this.uploadImages(res.agreement_code)
   
        this.loader.close();
      }
      else{
        Swal.fire(res.error.data.msg)
        this.loader.close();
      }
    },
    error: (err) => {
      Swal.fire('Oops...', err.error.data.msg, 'error')
      this.loader.close();
    },
    complete: () => { }
  }))
 }
 editAgreement(data:any){
  // this.annexure1Data = this.annexure1Data.map(({ category, sub_category, ...rest }) => rest);
  // this.annexure2Data = this.annexure2Data.map(({ category, sub_category, ...rest }) => rest);
  let payload :any= {
    
    "user_data": {
      "full_name": data.full_name,
      "director_name":data.director_name,
      "mobile_number": data.mobile_number,
      "email_id": data.email_id,
      "perment_address": data.perment_address,
      "current_resident_address": data.current_resident_address,
      "dealer_name": data.dealer_name,
      "dealer_code": data.dealer_code,
      "dealer_position": data.dealer_position,
      "park_and_sell_period":data.park_and_sell_period,
      "vehicle_base_price":data.vehicle_base_price,
      // "delaer_ship_service_charges":data.delaer_ship_service_charges,
      "post_sales_vehicle_payment_realese": data.post_sales_vehicle_payment_realese,
      "post_sales_vehicle_payment_realese_period":data.post_sales_vehicle_payment_realese_period,
      "vehicle_balance_payment":data.vehicle_balance_payment,
      "vehicle_balance_payment_realese_period":data.vehicle_balance_payment_realese_period,
      "vehicle_compensation_return_period":data.vehicle_compensation_return_period,
      "fixed_commission":data.fixed_commission,
      "sales_commission":data.sales_commission,


      "dealer_current_address":data.dealer_current_address,
      "dealer_permanent_address": data.dealer_permanent_address,
      "notice_period":data.notice_period,
  },
  "bank_detail": {
    "account_name": data.account_name,
    "bank_name": data.bank_name,
    "branch_name": data.branch_name,
    "branch_address": data.branch_address,
    "branch_city": data.branch_city,
    "branch_pin_code": data.branch_pin_code,
    "account_number": data.account_number,
    "ifsc_code": data.ifsc_code,
    "branch_code":data.branch_code,
    "micr_no":data.micr_no,
    "account_type":data.account_type,
    "rtgs_transfer":data.rtgs_transfer,
    "neft_transfer":data.neft_transfer
},
// "annexure_1":data.annexure_1,
// "annexure_2":data.annexure_2
"annexure_1": this.annexure1Data,
// "annexure_2":this.annexure2Data
  }
  if (this.checkBoxchekedlogin) {
    payload.annexure_2=this.annexure2Data
  }
  if(this.data.flag == 'EDIT'){
    payload['agreement_code'] = this.data.data.agreement_code
  }
  this.loader.open();
  this.sub.add(this.commonservices.editagreement(payload).subscribe({
    next: (res: any) => {
      if (res.success) {
        this.uploadImages(res.agreement_code)
     
        this.loader.close();
      }
      else{
        Swal.fire(res.error.data.msg)
        this.loader.close();
      }
    },
    error: (err) => {
      Swal.fire('Oops...', err.error.data.msg, 'error')
      this.loader.close();
    },
    complete: () => { }
  }))
 }
 //document function
//  onFileSelect(event: Event): void {
//   const input = event.target as HTMLInputElement;
//   if (!input.files || input.files.length === 0) {
//     return; // No files selected
//   }

//   Array.from(input.files).forEach((file) => {
//     const reader = new FileReader();
//     reader.onload = () => {
//       this.uploadedDocuments.push({
//         file,
//         url: reader.result as string,
//         position: '', // Default position value
//       });
//     };
//     reader.readAsDataURL(file);
//   });

//   // Reset the file input to allow re-upload of the same files
//   input.value = '';
// }
onFileSelect(event: Event): void {
  const input = event.target as HTMLInputElement;
  if (!input.files || input.files.length === 0) {
    return; // No files selected
  }

  Array.from(input.files).forEach((file) => {
    const fileType = file.type;

    if (fileType.startsWith('image/')) {
      // Handle image files
      const reader = new FileReader();
      reader.onload = () => {
        this.uploadedDocuments.push({
          file,
          url: reader.result as string, // Image preview
          position: '', // Default position value
          type: 'image',
        });
      };
      reader.readAsDataURL(file); // Read image as Data URL
    } else if (fileType === 'application/pdf') {
      // Handle PDF files
      this.uploadedDocuments.push({
        file,
        url: '', // Placeholder URL, as PDFs cannot be previewed directly
        position: '', // Default position value
        type: 'pdf',
      });
    }
  });

  // Reset the file input to allow re-upload of the same files
  input.value = '';
}


updatePosition(index: number, positionValue: string): void {
  if (index >= 0 && index < this.uploadedDocuments.length) {
    this.uploadedDocuments[index].position = positionValue;
  } else {
    console.error('Invalid index provided to updatePosition');
  }
}


removeDocument(index: number): void {
  if (index >= 0 && index < this.uploadedDocuments.length) {
    this.uploadedDocuments.splice(index, 1);
  } else {
    console.error('Invalid index provided to removeDocument');
  }
}
uploadImages(code:any) {
  this.loader.open();
  const formData = new FormData();
  
  
  formData.append("agreement_code", code);
formData.append("contract_type", 'agreement');
if (this.data.flag == 'EDIT') {
  formData.append("action_type", 'update');
}
  // this.uploadedDocuments.forEach((doc: any) => {
  //   formData.append(doc.position, doc.file, doc.file.name);
  // });

  this.uploadedDocuments.forEach((doc) => {
    if (doc.file) {
      // For newly uploaded files
      formData.append(doc.position || 'new_document', doc.file, doc.file.name);
    } else if (doc.url) {
      // For existing documents, create a Blob or placeholder to pass in FormData
      const existingFileBlob = new Blob([], { type: 'application/octet-stream' });
      formData.append(doc.position, existingFileBlob, doc.url.split('/').pop() || 'existing_file');
    }
    else{
      formData.append(doc.position, null);
    }
  });

  
  // this.uploadedDocuments.forEach((doc) => {
  //   if (doc.file) {
  //     // For newly uploaded files
  //     formData.append(doc.position, doc.file, doc.file.name);
  //   } else {
  //     // Append null for positions without files
  //     formData.append(doc.position, null);
  //   }
  // });
  // this.loader.open();

  this.sub.add(this.fileservice.uploadVehicleImageAgreement(formData).subscribe({
    next: (res: any) => {
      if (res.success) {
        // console.log(res);
        // if (this.data.flag != 'EDIT') {
          this.submitAgreement2(this.userForm.value,code);
        // }
        //  else {
        //   this.submitAgreement2(this.userForm.value,code);
        // }
        // Swal.fire(res.data.msg)
        this.loader.close();
        // if (this.data.flag == 'EDIT') {
        // // this.dialog.close('saved');
        // this.loader.close();
        // }
      } else {
        this.loader.close();
        Swal.fire(res.error.data.msg)
        this.dialog.close('cancle');
      }
      
    },
    error: (err) => {
      Swal.fire('Oops...', err.error.data.msg, 'error');
      this.loader.close();
    },
    complete: () => {}
  }));
}
duplicateImgPosValidation(index: number): void {
  const selectedPosition = this.uploadedDocuments[index].position;
  // Check for duplicates but exclude the current image (index)
  const isDuplicate = this.uploadedDocuments.some(
    (doc, i) => doc.position === selectedPosition && i !== index && selectedPosition !== null
  );

  if (isDuplicate) {
    // Show alert using SweetAlert2
    Swal.fire('Duplicate position not allowed');
    this.uploadedDocuments[index].position = null; // Only reset if it's a duplicate
    return;
  }
}

addOrUpdateAnnexure2() {
  if (this.annexure_2Form.invalid) {
    this.annexure_2Form.markAllAsTouched();
    return;
  }

  const formData = this.annexure_2Form.value;
  formData.km_run = Number(formData.km_run) || ''; 
  formData.est_parts_amt = Number(formData.est_parts_amt) || 0;
  formData.est_labour_amt = Number(formData.est_labour_amt) || 0;

  formData.total_amt = formData.est_parts_amt + formData.est_labour_amt;



  if (this.editingIndex !== null) {
    // Update existing entry
    this.annexure2Data[this.editingIndex] = formData;
    this.editingIndex = null;
  } else {
    // Add new entry
    this.annexure2Data.push(formData);
  }

  this.annexure_2Form.reset();
}

editAnnexure2(index: number) {
  this.editingIndex = index;
  // this.annexure_2Form.patchValue(this.annexure2Data[index]);
  const annexure = this.annexure2Data[index];
  this.getCategory2(3,"EDIT",annexure);
  this.annexure_2Form.patchValue({
    registration_number: annexure.registration_number,
    make: annexure.make,
    km_run: annexure.km_run,
    sub_cat: annexure.sub_cat,
    sub_cat_detail: annexure.sub_cat_detail,
    model: annexure.model,
    color: annexure.color,
    job_to_carried: annexure.job_to_carried,
    est_parts_amt: annexure.est_parts_amt,
    est_labour_amt: annexure.est_labour_amt,
    total_amt: annexure.total_amt,

  });
}

removeAnnexure2(index: number) {
  this.annexure2Data.splice(index, 1);
}

addOrUpdateAnnexure1(): void {


  if (this.annexure_1Form.invalid) {
    this.annexure_1Form.markAllAsTouched();
    return;
  }

  const formData = this.annexure_1Form.value;
  formData.km_run = Number(formData.km_run) || '';
  formData.no_of_tyres = Number(formData.no_of_tyres) || '';


  if (this.editIndex !== null) {
    // Update existing entry
    this.annexure1Data[this.editIndex] = formData;
    this.editIndex = null;
  } else {
    // Add new entry
    this.annexure1Data.push(formData);
  }

  this.annexure_1Form.reset();
  // if (this.annexure_1Form.valid) {
  //   const formData = this.annexure_1Form.value;

  //   if (this.editIndex === null) {
  //     // Add new entry
  //     formData.km_run = Number(formData.km_run) || '';
  //     formData.no_of_tyres = Number(formData.no_of_tyres) || '';
  //     this.annexure1Data.push(formData);
  //   } else {
  //     this.annexure1Data[this.editIndex] = formData
  //     this.editIndex = null; 
  //   }
  //   this.annexure_1Form.reset();
  // } else {
  //   this.annexure_1Form.markAllAsTouched(); 
  // }
}

// Edit Annexure
editAnnexure1(index: number): void {
  this.editIndex = index; 
  const annexure = this.annexure1Data[index];
  
  // this.annexure_1Form.patchValue(annexure); 
  // Populate the form with the selected annexure data
  // Fetch and set category, subcategory, and model sequentially
  this.getCategory1(3,"EDIT",annexure);

  //   this.getSubCategories(annexure.category_id).subscribe((subcategories) => {
  //     this.SubCategoryArray = subcategories; // Populate subcategories
  //     this.annexure_1Form.patchValue({
  //       sub_category: annexure.sub_category_id, // Set subcategory
  //     });

  //     this.getModels(annexure.sub_category_id).subscribe((models) => {
  //       this.ModelsArray = models; // Populate models
  //       this.annexure_1Form.patchValue({
  //         model: annexure.model, // Set model
  //       });
  //     });
  //   });
  // });

  // Populate other form fields
  this.annexure_1Form.patchValue({
    registration_number: annexure.registration_number,
    chassis_number: annexure.chassis_number,
    make: annexure.make,
    engine_no: annexure.engine_no,
    variant: annexure.variant,
    km_run: annexure.km_run,
    color: annexure.color,
    insurance_status: annexure.insurance_status,
    no_of_tyres: annexure.no_of_tyres,
    hypothecation_status: annexure.hypothecation_status,
    application:annexure.application,
    body_type:annexure.body_type,
    // expected_average_price:annexure.expected_average_price,
    expected_price:annexure.expected_price

  });
}

// Remove Annexure
removeAnnexure1(index: number): void {
  this.annexure1Data.splice(index, 1); 
}
async getInventory(flag?) {
  await this.commonservices.BindInventory().subscribe(
    data => {
      if (data.success == true) {
        this.InventoryType = [data.data[1]];
      }
      else {
        this.InventoryType = []
        Swal.fire('Oops...', data.data.msg, 'error')
      }
    }, (err) => {


      Swal.fire('Oops...', err.error.data.msg, 'error')
    }
  );
   
}
async getCategory1(value, flag,flag1?,value1?) {
  // this.userForm.controls["category"].setValue(null);
  // this.userForm.controls["Subcategory"].setValue(null);
  this.CategoryArray = [];
  this.SubCategoryArray = [];
  this.cat_id = "";
  const dataList: SubCategoryDetails = {} as SubCategoryDetails;
  dataList.cat_id = value;
  dataList.lob=value1 ? value1 : ''
  this.cat_id = value;
  await this.commonservices.BindCategory(dataList).subscribe(
    data => {

      if (data.success == true) {
        this.CategoryArray = data.data;
       if (flag == 'EDIT') {
        this.annexure_1Form.controls.sub_cat.setValue(flag1.sub_cat);
        this.onselectCategory1(flag1,"EDIT")
       }
        
      }
      else {
        this.CategoryArray = []
        Swal.fire('Oops...', data.data.msg, 'error')
      }
    }, (err) => {

      Swal.fire('Oops...', err.error.data.msg, 'error')

    }
  );
}
async onselectCategory1(cateval, flag, flag1?, value1?) {
  this.loader.open(); // Open loader at the start
  let selectedCategory;
  if (flag == "EDIT") {
    selectedCategory = cateval.sub_cat;
  } else {
    selectedCategory = this.CategoryArray.find(cat => cat.sub_cat_id == cateval);
    selectedCategory = selectedCategory.sub_cat_name;
    this.ModelsArray = [];
  }
  this.annexure_1Form.controls.sub_cat_detail.setValue('');
  this.annexure_1Form.controls.model.setValue('');
  let loB: any = this.CategoryArray.filter(c => c.sub_cat_id == cateval.sub_cat);

  this.lob = flag == 'EDIT' ? loB[0].sub_cat_name : selectedCategory;

  this.SubCategoryArray = [];
  this.sub_cat_id = "";
  const dataList: SubCategoryDetails = {} as SubCategoryDetails;
  dataList.subcat_id = flag == 'EDIT' ? cateval.sub_cat : cateval;
  dataList.ppl = value1 ? value1 : '';
  this.sub_cat_id = flag == 'EDIT' ? cateval.sub_cat : cateval;

  await this.commonservices.BindSubCategory(dataList).subscribe({
    next: (data) => {
      if (data.success == true) {
        this.loader.close();
        this.SubCategoryArray = data.data;
        if (flag == 'EDIT') {
          this.annexure_1Form.controls.sub_cat_detail.setValue(cateval.sub_cat_detail);
          this.onSelectSubcategory1(cateval.sub_cat_detail, "EDIT", cateval);
        }
      } else {
        this.loader.close();
        this.SubCategoryArray = [];
        Swal.fire('Oops...', data.data.msg, 'error');
      }
    },
    error: (err) => {
      this.loader.close();
      Swal.fire('Oops...', err.error.data.msg, 'error');
    },
    complete: () => {
      this.loader.close(); // Close loader when the API call completes
    }
  });
}

onSelectSubcategory1(Subcateval, flag?, Data?) {
  this.loader.open(); // Open loader at the start
  let subcat = {
    catId: this.cat_id,
    subcat_id: this.sub_cat_id,
    subcat_detail_id: Subcateval,
  };
  const selectedsubCategory = this.SubCategoryArray.find(cat => cat.sub_cat_detail_id == Subcateval);
  this.ppl = selectedsubCategory.name;

  this.annexure_1Form.controls.model.setValue('');
  if (flag == 'set') {
    this.loader.close();
    this.getModelData1(this.lob, this.ppl, 'set', Data);
  } else {
    this.getModelData1(this.lob, this.ppl, 'EDIT', Data);
    this.loader.close();
  }
  this.loader.close(); // Close loader at the end
}

getModelData1(lob: any, ppl: any, flag?, model?) {
  // this.loader.open(); // Open loader at the start
  let data = {
    "lob": lob,
    "ppl": ppl,
    "offset": 0,
    "limit": 10,
    "search": "",
  };
  this.sub.add(this.commonservices.getModelList(data).subscribe({
    next: (res: any) => {
      if (res.success) {
        this.ModelsArray = res.data.models;
        this.annexure_1Form.controls.model.setValue(model.model);
        this.loader.close();
      } else {
        this.ModelsArray = [];
        this.annexure_1Form.controls.model.setValue('');
        this.loader.close();
      }
    },
    error: () => {
      this.loader.close();
     },
    complete: () => {
      this.loader.close(); // Close loader when the API call completes
    }
  }));
}
async getCategory2(value, flag,flag1?,value1?) {
  // this.userForm.controls["category"].setValue(null);
  // this.userForm.controls["Subcategory"].setValue(null);
  this.CategoryArray2 = [];
  this.SubCategoryArray2 = [];
  this.cat_id2 = "";
  const dataList: SubCategoryDetails = {} as SubCategoryDetails;
  dataList.cat_id = value;
  dataList.lob=value1 ? value1 : ''
  this.cat_id2 = value;
  await this.commonservices.BindCategory(dataList).subscribe(
    data => {

      if (data.success == true) {
        this.CategoryArray2 = data.data;
       if (flag == 'EDIT') {
        this.annexure_2Form.controls.sub_cat.setValue(flag1.sub_cat);
        this.onselectCategory2(flag1,"EDIT")
       }
        
      }
      else {
        this.CategoryArray2 = []
        Swal.fire('Oops...', data.data.msg, 'error')
      }
    }, (err) => {

      Swal.fire('Oops...', err.error.data.msg, 'error')

    }
  );
}
async onselectCategory2(cateval, flag, flag1?, value1?) {
  this.loader.open(); // Open loader at the start
  let selectedCategory;
  if (flag == "EDIT") {
    selectedCategory = cateval.sub_cat;
  } else {
    selectedCategory = this.CategoryArray2.find(cat => cat.sub_cat_id == cateval);
    selectedCategory = selectedCategory.sub_cat_name;
    this.ModelsArray2 = [];
  }
  this.annexure_2Form.controls.sub_cat_detail.setValue('');
  this.annexure_2Form.controls.model.setValue('');
  let loB: any = this.CategoryArray2.filter(c => c.sub_cat_id == cateval.sub_cat);

  this.lob2 = flag == 'EDIT' ? loB[0].sub_cat_name : selectedCategory;

  this.SubCategoryArray2 = [];
  this.sub_cat_id2 = "";
  const dataList: SubCategoryDetails = {} as SubCategoryDetails;
  dataList.subcat_id = flag == 'EDIT' ? cateval.sub_cat : cateval;
  dataList.ppl = value1 ? value1 : '';
  this.sub_cat_id2 = flag == 'EDIT' ? cateval.sub_cat : cateval;

  await this.commonservices.BindSubCategory(dataList).subscribe({
    next: (data) => {
      if (data.success == true) {
        this.loader.close();
        this.SubCategoryArray2 = data.data;
        if (flag == 'EDIT') {
          this.annexure_2Form.controls.sub_cat_detail.setValue(cateval.sub_cat_detail);
          this.onSelectSubcategory2(cateval.sub_cat_detail, "EDIT", cateval);
        }
      } else {
        this.loader.close();
        this.SubCategoryArray2 = [];
        Swal.fire('Oops...', data.data.msg, 'error');
      }
    },
    error: (err) => {
      this.loader.close();
      Swal.fire('Oops...', err.error.data.msg, 'error');
    },
    complete: () => {
      this.loader.close(); // Close loader when the API call completes
    }
  });
}

onSelectSubcategory2(Subcateval, flag?, Data?) {
  this.loader.open(); // Open loader at the start
  let subcat = {
    catId: this.cat_id,
    subcat_id: this.sub_cat_id,
    subcat_detail_id: Subcateval,
  };
  const selectedsubCategory = this.SubCategoryArray2.find(cat => cat.sub_cat_detail_id == Subcateval);
  this.ppl2 = selectedsubCategory.name;

  this.annexure_2Form.controls.model.setValue('');
  if (flag == 'set') {
    this.loader.close();
    this.getModelData2(this.lob2, this.ppl2, 'set', Data);
  } else {
    this.getModelData2(this.lob2, this.ppl2, 'EDIT', Data);
    this.loader.close();
  }
  this.loader.close(); // Close loader at the end
}

getModelData2(lob: any, ppl: any, flag?, model?) {
  // this.loader.open(); // Open loader at the start
  let data = {
    "lob": lob,
    "ppl": ppl,
    "offset": 0,
    "limit": 10,
    "search": "",
  };
  this.sub.add(this.commonservices.getModelList(data).subscribe({
    next: (res: any) => {
      if (res.success) {
        this.ModelsArray2 = res.data.models;
        this.annexure_2Form.controls.model.setValue(model.model);
        this.loader.close();
      } else {
        this.ModelsArray2 = [];
        this.annexure_2Form.controls.model.setValue('');
        this.loader.close();
      }
    },
    error: () => {
      this.loader.close();
     },
    complete: () => {
      this.loader.close(); // Close loader when the API call completes
    }
  }));
}

onCheckboxChangelogin(checked: boolean) {
  if (checked) {
    this.checkBoxchekedlogin=true
  } else {
    this.checkBoxchekedlogin=false
  }

  
}

// onSendCode(): void {
//   this.loader.open();
//   let data = {
//     "app_name": "com.tatamotors.tataokresale",
//     "device_id": "2e2f09b94d152874",
//     "email_id": "",
//     "key": "1000",
//     "message": "",
//     "phone_number": this.userForm.value.mobile_number
// };
//   this.sub.add(this.commonservices.sendOtpUser(data).subscribe({
//     next: (res: any) => {
//       if (res.success) {
//         this.isOtpSent = true;
//         Swal.fire(res.msg)
//         this.loader.close();
//       } else {
//         console.log(res.error.msg);

//         Swal.fire(res.error.msg)
//         this.loader.close();
//       }
//     },
//     error: (err) => {
//       console.log(err);
      
//       Swal.fire(err.error.msg)
//       this.loader.close();
//      },
//     complete: () => {
//       this.loader.close();
//     }
//   }));
// }

// onVerifyOtp(): void {
//   this.loader.open();
//   let data = {
//     "app_name": "com.tatamotors.tataokresale",
//     "device_id": "2e2f09b94d152874",
//     "email_id": "",
//     "msg_ref_no": 586,
//     "otp_number":this.otp,
//     "phone_number": this.userForm.value.mobile_number,
//     "status": "Registration OTP"
// };
//   this.sub.add(this.commonservices.VerfifyOtpUser(data).subscribe({
//     next: (res: any) => {
//       if (res.success) {
//         this.isOtpVerified = true;
//         Swal.fire(res.msg)
//         this.loader.close();
//       } else {
//         console.log(res.error.msg);
//      Swal.fire(res.error.msg)
//         this.loader.close();
//       }
//     },
//     error: (err) => {
//       console.log(err);
//       Swal.fire(err.error.msg)
//       this.loader.close();
//      },
//     complete: () => {
//       this.loader.close(); // Close loader when the API call completes
//     }
//   }));
// }

  // Send OTP
  otpTimer: any;
  onSendCode(): void {
    if (!this.userForm.get('mobile_number').valid) {
      Swal.fire('Please enter a valid mobile number.');
      return;
    }
    this.loader.open();
    let data = {
      "app_name": "com.tatamotors.tataokresale",
      "device_id": "2e2f09b94d152874",
      "email_id": "",
      "key": "1000",
      "message": "",
      "phone_number": this.userForm.value.mobile_number
    };
    this.sub.add(this.commonservices.sendOtpUser(data).subscribe({
      next: (res: any) => {
        if (res.success) {
          this.isOtpSent = true;
          Swal.fire(res.msg);
          this.loader.close();
          this.timer = 60; // Reset the timer value (e.g., 60 seconds)
          this.startOtpTimer(); // Start the timer once OTP is sent
        } else {
          this.isOtpSent = false;
    
          Swal.fire(res.error.msg);
          this.loader.close();
        }
      },
      error: (err) => {
   
        Swal.fire(err.error.msg);
        this.loader.close();
      },
      complete: () => {
        this.loader.close();
      }
    }));
  }
  
  // Start timer countdown for resend button
  startOtpTimer(): void {
    this.isSendCodeDisabled = true; // Disable send code button
    this.otpTimer = timer(0, 1000).pipe(
      takeWhile(() => this.timer > 0)
    ).subscribe(() => {
      this.timer--;
    }, null, () => {
      this.isSendCodeDisabled = false; // Re-enable send code button after timer expires
    });
  }

  // // Get OTP value from the input
  // getUserotp(otp: string): void {
  //   this.otp = otp;
  // }

  // Verify OTP
  stopTimer(): void {
    if (this.otpTimer) {
      this.otpTimer.unsubscribe(); // Unsubscribe from the timer
    }
    this.isSendCodeDisabled = false; // Re-enable send code button
  }
  onVerifyOtp(): void {
    this.loader.open();
    let data = {
      "app_name": "com.tatamotors.tataokresale",
      "device_id": "2e2f09b94d152874",
      "email_id": "",
      "msg_ref_no": 586,
      "otp_number": this.otp,
      "phone_number": this.userForm.value.mobile_number,
      "status": "Registration OTP"
    };
    this.sub.add(this.commonservices.VerfifyOtpUser(data).subscribe({
      next: (res: any) => {
        if (res.success) {
          this.isOtpVerified = true;
          this.stopTimer();
          Swal.fire(res.msg);
          this.loader.close();
        } else {
        
          Swal.fire(res.error.msg);
          this.loader.close();
        }
      },
      error: (err) => {

        Swal.fire(err.error.msg);
        this.loader.close();
      },
      complete: () => {
        this.loader.close();
      }
    }));
  }
  getForm(){
    this.isForm = !this.isForm;

    
  }
  getUserotp(data:any){

    this.otp = data
    
  }

 
  // onFileUpload(event: Event, index: number, position: string): void {
  //   const input = event.target as HTMLInputElement;
  //   if (input.files && input.files.length > 0) {
  //     const file = input.files[0];
  //     const reader = new FileReader();
  
  //     reader.onload = () => {
  //       this.uploadedDocuments[index] = {
  //         file,
  //         url: reader.result as string,
  //         position,
  //         type: file.type,
  //       };
  //     };
  
  //     reader.readAsDataURL(file);
  //   }
  // }
  onFileUpload(event: Event, index: number, position: string): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      const reader = new FileReader();
  
      reader.onload = () => {
        this.uploadedDocuments[index] = {
          file,
          url: reader.result as string,
          position,
          type: file.type,
          flag: true, // Set the flag to true (Yes) when a file is uploaded
        };
      };
  
      reader.readAsDataURL(file);
    }
  }
  isImage(fileType: string | undefined): boolean {
    return fileType.startsWith('image/');
  }

  
  previewFile(index: number): void {
    const fileData = this.uploadedDocuments[index];
    if (fileData.url) {
      window.open(fileData.url, '_blank');
    }
  }

  // previewFile(index: number): void {
  //   const fileData = this.uploadedDocuments[index];
  //   if (fileData.url) {
  //     window.open(fileData.url, '_blank');
  //   }
  // }
  submitData(){
    const isValid = this.validateDocuments(this.uploadedDocuments);
  
    if (!isValid) {
     Swal.fire('Please add documet where you selected yes')
      return; // Stop submission
    }

    this.uploadImages('AGG00001')
    
  }
  formatDocumentName(name: string): string {
    if (!name) return '';
    return name
      .split('_') // Split by underscores
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(' '); // Join words with spaces
  }
  // onFlagChange(event: any, i: number) {
  //   const selectedValue = event.target.value;
  //   const document = this.uploadedDocuments[i]; // Get the correct document from the array
  //   console.log('Selected value:', selectedValue);
  //   console.log('Document before change:', document);
  
  //   if (selectedValue === 'false') {
  //     document.url = ''; // Remove the URL if 'No' is selected
  //     document.flag= false; // Set flag to false if 'No' is selected
  //   }
  
  //   console.log('Document after change:', document);
  // }
  // onFlagChange(event: any, i: number): void {
  //   const selectedValue = event.target.value; // Get the selected value (true or false)
  //   const document = this.uploadedDocuments[i]; // Get the correct document from the array
  
  //   // Set the flag based on the selected value
  //   if (selectedValue === 'false') {
  //     document.flag = false; // Set flag to false if 'No' is selected
  //     document.url = ''; // Clear the URL when 'No' is selected
  //   } else {
  //     document.flag = true; // Set flag to true if 'Yes' is selected
  //     // Optionally, you can prompt the user to upload a file if 'Yes' is selected, or handle it elsewhere
  //   }
  
  //   console.log('Document after flag change:', document); // Log the updated document
  // }
  onFlagChange(event: any, i: number): void {
    const selectedValue = event.target.value; // Get the selected value (true or false)
    const document = this.uploadedDocuments[i]; // Get the correct document from the array
  
    // Set the flag based on the selected value
    if (selectedValue === 'false') {
      document.flag = false; // Set flag to false if 'No' is selected
      document.url = ''; // Clear the URL when 'No' is selected
      document.file = null; // Clear the file reference if 'No' is selected
  
      // Clear the file input by resetting it programmatically
      const inputElement = event.target.closest('tr').querySelector('input[type="file"]') as HTMLInputElement;
      if (inputElement) {
        inputElement.value = ''; // Clear the file input
      }
    } else {
      document.flag = true; // Set flag to true if 'Yes' is selected
      // Optionally, you can prompt the user to upload a file if 'Yes' is selected, or handle it elsewhere
    }
  
    // console.log('Document after flag change:', document); // Log the updated document
  }
  validateDocuments(documents: any[]): boolean {
    const invalidDocs = documents.filter(doc => {
      // Check if flag is true and either file or url is missing (or url is empty string)
      return doc.flag && (!doc.file && (!doc.url || doc.url === ''));
    });
  
    if (invalidDocs.length > 0) {
      invalidDocs.forEach(doc => {
        console.error(`Validation failed for position: ${doc.position}`);
        // Adding custom error message for blank URL and flag true
        if (doc.flag && (!doc.url || doc.url === '')) {
          Swal.fire('Please upload an image where you selected "Yes"');
        }
      });
      return false; // Validation failed
    }
  
    return true; // Validation passed
  }
  onInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    let value = parseInt(input.value, 10);
  
    if (isNaN(value) || value < 1 || value > 100) {
      this.userForm.get('post_sales_vehicle_payment_realese').setValue('', { emitEvent: false });
    } else {
      this.userForm.get('post_sales_vehicle_payment_realese').setValue(value, { emitEvent: false });
    }
  }
  getAddress(): void {
    this.loader.open();
    let data = {
    };
    this.sub.add(this.commonservices.agreementAddress(data).subscribe({
      next: (res: any) => {
        if (res.success) {
          this.UserAddress = res.data[0];
          this.City = res.data[0].DIV_CITY_s
          console.log(this.City)
          if (this.UserAddress && Object.keys(this.UserAddress).length > 0) {
            const values: string[] = [];
            for (const key in this.UserAddress) {
              if (this.UserAddress.hasOwnProperty(key)) {
                values.push(this.UserAddress[key]);
              }
            }
            this.UserAddress =values.reverse().join(', ');
            this.userForm.controls["dealer_current_address"].setValue(this.UserAddress);
            this.userForm.controls["dealer_permanent_address"].setValue(this.UserAddress);

            // console.log(this.UserAddress);
            
          }
          this.loader.close();
        } else {
        
          // Swal.fire("Unable to process the request");
          this.loader.close();
        }
      },
      error: (err) => {
        // console.log(err);
        Swal.fire(err.error.msg);
        this.loader.close();
      },
      complete: () => {
        this.loader.close();
      }
    }));
  }
  getApplicationData() {
    let data = {
      "sub_lob": "ALL"
    }
    this.sub.add(this.commonservices.getAllApplications(data).subscribe({
      next: (res: any) => {
        this.applicationData = res.data;
        this.loader.close();
      },
      error: () => { },
      complete: () => { }
    }))
  }
  calculateAveragePrice = (dataArray: any[]) => {
    // Convert `expected_price` to numbers and calculate the sum
    const totalPrice = dataArray.reduce((sum, item) => {
      const price = parseFloat(item.expected_price);
      return sum + (isNaN(price) ? 0 : price);
    }, 0);
  
    // Calculate the average
    const averagePrice = totalPrice / dataArray.length;
  
    return averagePrice || 0; // Return 0 if no valid data
  };
  submitAgreement2(data:any,code:any){
    this.loader.open();
    // this.annexure1Data = this.annexure1Data.map(({ category, sub_category, ...rest }) => rest);
    // this.annexure2Data = this.annexure2Data.map(({ category, sub_category, ...rest }) => rest);
    let payload :any= {
      "city":this.City ? this.City : '',
      "agreement_code":code,
      "user_data": {
        "full_name": data.full_name,
        "director_name":data.director_name,
        "mobile_number": data.mobile_number,
        "email_id": data.email_id,
        "perment_address": data.perment_address,
        "current_resident_address": data.current_resident_address,
        "dealer_name": data.dealer_name,
        "dealer_code": data.dealer_code,
        "dealer_position": data.dealer_position,
        "park_and_sell_period":data.park_and_sell_period,
        "vehicle_base_price":data.vehicle_base_price,
        // "delaer_ship_service_charges":data.delaer_ship_service_charges,
        // "fixed_commission":data.fixed_commission,
        // "vehicle_payment_realese": data.vehicle_payment_realese,
        // "vehicle_payment_realese_period":data.vehicle_payment_realese,
        // "balance_payment_realese_period": data.balance_payment_realese_period,
        "post_sales_vehicle_payment_realese": data.post_sales_vehicle_payment_realese,
        "post_sales_vehicle_payment_realese_period":data.post_sales_vehicle_payment_realese_period,
        "vehicle_balance_payment":data.vehicle_balance_payment,
        "vehicle_balance_payment_realese_period":data.vehicle_balance_payment_realese_period,
        "vehicle_compensation_return_period":data.vehicle_compensation_return_period,
        "fixed_commission":data.fixed_commission,
        "sales_commission":data.sales_commission,
        "dealer_current_address":data.dealer_current_address,
        "dealer_permanent_address": data.dealer_permanent_address,
        "notice_period":data.notice_period,
    },
    "bank_detail": {
      "account_name": data.account_name,
      "bank_name": data.bank_name,
      "branch_name": data.branch_name,
      "branch_address": data.branch_address,
      "branch_city": data.branch_city,
      "branch_pin_code": data.branch_pin_code,
      "account_number": data.account_number,
      "ifsc_code": data.ifsc_code,
      "branch_code":data.branch_code,
      "micr_no":data.micr_no,
      "account_type":data.account_type,
      "rtgs_transfer":data.rtgs_transfer,
      "neft_transfer":data.neft_transfer
  },
  // "annexure_1":data.annexure_1,
  // "annexure_2":data.annexure_2
  "annexure_1": this.annexure1Data,
  // "annexure_2":this.annexure2Data
    }
    if (this.checkBoxchekedlogin) {
      payload.annexure_2=this.annexure2Data
    }
    // if(this.data.flag == 'EDIT'){
    //   payload['agreement_code'] = this.data.data.agreement_code
    // }
    this.sub.add(this.commonservices.readdagreement(payload).subscribe({
      next: (res: any) => {
        if (res.success) {
          // this.uploadImages(res.agreement_code)
          // Swal.fire(res.data.msg)
          Swal.fire("Data saved successfully")
        this.dialog.close('saved');

          this.loader.close();

        }
        else{
          Swal.fire(res.error.data.msg)

          this.loader.close();
        this.dialog.close('cancle');

        }
      },
      error: (err) => {
        Swal.fire('Oops...', err.error.data.msg, 'error')
        this.loader.close();
      },
      complete: () => { }
    }))
   }
   editAgreement2(data:any,code:any){
    // this.annexure1Data = this.annexure1Data.map(({ category, sub_category, ...rest }) => rest);
    // this.annexure2Data = this.annexure2Data.map(({ category, sub_category, ...rest }) => rest);
    let payload :any= {
      "agreement_code":code,
      "user_data": {
        "full_name": data.full_name,
        "director_name":data.director_name,
        "mobile_number": data.mobile_number,
        "email_id": data.email_id,
        "perment_address": data.perment_address,
        "current_resident_address": data.current_resident_address,
        "dealer_name": data.dealer_name,
        "dealer_code": data.dealer_code,
        "dealer_position": data.dealer_position,
        "park_and_sell_period":data.park_and_sell_period,
        "vehicle_base_price":data.vehicle_base_price,
        // "delaer_ship_service_charges":data.delaer_ship_service_charges,
        "post_sales_vehicle_payment_realese": data.post_sales_vehicle_payment_realese,
        "post_sales_vehicle_payment_realese_period":data.post_sales_vehicle_payment_realese_period,
        "vehicle_balance_payment":data.vehicle_balance_payment,
        "vehicle_balance_payment_realese_period":data.vehicle_balance_payment_realese_period,
        "vehicle_compensation_return_period":data.vehicle_compensation_return_period,
        "fixed_commission":data.fixed_commission,
        "sales_commission":data.sales_commission,
  
  
        "dealer_current_address":data.dealer_current_address,
        "dealer_permanent_address": data.dealer_permanent_address,
        "notice_period":data.notice_period,
    },
    "bank_detail": {
      "account_name": data.account_name,
      "bank_name": data.bank_name,
      "branch_name": data.branch_name,
      "branch_address": data.branch_address,
      "branch_city": data.branch_city,
      "branch_pin_code": data.branch_pin_code,
      "account_number": data.account_number,
      "ifsc_code": data.ifsc_code,
      "branch_code":data.branch_code,
      "micr_no":data.micr_no,
      "account_type":data.account_type,
      "rtgs_transfer":data.rtgs_transfer,
      "neft_transfer":data.neft_transfer
  },
  // "annexure_1":data.annexure_1,
  // "annexure_2":data.annexure_2
  "annexure_1": this.annexure1Data,
  // "annexure_2":this.annexure2Data
    }
    if (this.checkBoxchekedlogin) {
      payload.annexure_2=this.annexure2Data
    }
    if(this.data.flag == 'EDIT'){
      payload['agreement_code'] = this.data.data.agreement_code
    }
    this.loader.open();
    this.sub.add(this.commonservices.editagreement(payload).subscribe({
      next: (res: any) => {
        if (res.success) {
          // this.uploadImages(res.agreement_code)
  
          this.loader.close();
        }
        else{
          Swal.fire(res.error.data.msg)
          this.loader.close();
        }
      },
      error: (err) => {
        Swal.fire('Oops...', err.error.data.msg, 'error')
        this.loader.close();
      },
      complete: () => { }
    }))
   }
   validateStep1(): boolean {
    const invalidControls = [
      'full_name',
      'director_name',
      'email_id',
      'perment_address',
      'current_resident_address',
      'park_and_sell_period',
      'vehicle_base_price',
      'dealer_name',
      'dealer_code',
      'dealer_current_address',
      'dealer_permanent_address'
    ];
  
    // Mark each control as touched and check for invalid
    let isFormValid = true;
    invalidControls.forEach(control => {
      const formControl = this.userForm.get(control);
      if (formControl) {
        formControl.markAsTouched(); // Mark the control as touched
  
        // Check if it's invalid
        if (formControl.invalid) {
          isFormValid = false; // If any control is invalid, set the flag to false
        }
      }
    });
  
    if (!isFormValid) {
      Swal.fire('Please fill mandatory fields');
      return false;
    }
  
    return true;
  }
  validateStep2(): boolean {
    const invalidControls = [
      'account_name',
      'bank_name',
      'branch_name',
      'branch_address',
      'branch_city',
      'branch_pin_code',
      'account_number',
      'ifsc_code',
      'account_type',
    ];
  
    // Mark each control as touched and check for invalid
    let isFormValid = true;
    invalidControls.forEach(control => {
      const formControl = this.userForm.get(control);
      if (formControl) {
        formControl.markAsTouched(); // Mark the control as touched
  
        // Check if it's invalid
        if (formControl.invalid) {
          isFormValid = false; // If any control is invalid, set the flag to false
        }
      }
    });
  
    if (!isFormValid) {
      Swal.fire('Please fill mandatory fields');
      return false;
    }
  
    return true;
  }
  validateStep3(): boolean {
    const invalidControls = [
      'sales_commission',
      'fixed_commission',
      'post_sales_vehicle_payment_realese'
    ];
  
    // Mark each control as touched and check for invalid
    let isFormValid = true;
    invalidControls.forEach(control => {
      const formControl = this.userForm.get(control);
      if (formControl) {
        formControl.markAsTouched(); // Mark the control as touched
  
        // Check if it's invalid
        if (formControl.invalid) {
          isFormValid = false; // If any control is invalid, set the flag to false
        }
      }
    });
  
    if (!isFormValid) {
      Swal.fire('Please fill mandatory fields');
      return false;
    }
  
    return true;
  }
  validateStep4(){
    if(this.annexure1Data.length > 0){
return true; 
    }
    else
    {
      Swal.fire("Please add  Vehicle Details")
return false;
    }
  }
  notAllowSpace(event: KeyboardEvent) {
    if (event.key === ' ') {
      event.preventDefault();
    }
  } 
  onlyNumbers(event: KeyboardEvent): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    }
    return true;
  }
  preventSpecialCharacters(event: KeyboardEvent): boolean {
    const regex = /^[a-zA-Z0-9 ]+$/; // Allow letters, numbers, and spaces
    const key = String.fromCharCode(event.keyCode);
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
    return true;
}
  getCategory(data:any){
    const result = this.CategoryArray.find(cat => cat.sub_cat_id == data);
    return result ? result.sub_cat_name : 'Category not found';
  }
  getSubCategory(data:any){
    const result = this.SubCategoryArray.find(cat => cat.sub_cat_detail_id == data);
    console.log(result);
    
    return result ? result.name : 'Category not found';
  }
  notspacefirst(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;
    const currentValue = input.value;

    // Check if space is pressed and if it's at the start of the input value
    if (event.key === ' ' && currentValue.length === 0) {
      event.preventDefault(); // Prevent the space key from being entered
    }
  }
  restrictZero(event, value) {
    if (value.length === 0 && event.charCode == 48) {
      return false;
    }
    else {
      return true;
    }
  }
  // (keypress)="notspacefirst($event)" 
  validatePaste(event: ClipboardEvent) {
    event.preventDefault(); // Prevent default paste action
  
    const pastedData = event.clipboardData.getData('text') || '';
    
    // Allow only numeric values
    if (/^\d+$/.test(pastedData)) {
      const input = event.target as HTMLInputElement;
      const currentValue = input.value;
      
      // Insert valid numeric data at cursor position
      const start = input.selectionStart || 0;
      const end = input.selectionEnd || 0;
      input.value = currentValue.substring(0, start) + pastedData + currentValue.substring(end);
  
      // Move cursor position
      input.setSelectionRange(start + pastedData.length, start + pastedData.length);
    }
  }
}
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-lead-popup',
  templateUrl: './lead-popup.component.html',
  styleUrls: ['./lead-popup.component.scss']
})
export class LeadPopupComponent implements OnInit {
  displayedColumns: string[] = ['salesId', 'status', 'date'];
  statusData = [
    
  ];

  errorMessage = `Payment Error: 201{"order_ref_id":"req407f-85d1-cfea0b3fab2e","msg":"Order created successfully","DSM_name":"SURESH AGLAVE","DSM_phone":"9999999999","DSM_email":"ss1.quality@tatamotors.com","div_id":"1-G3ZG5"}`;
  history: any;

  constructor(
    public dialogRef: MatDialogRef<LeadPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  closeDialog(): void {
    this.dialogRef.close();
    this.statusData = []
  }

  ngOnInit() {
    this.statusData = this.data.lead.lead;
    this.history = this.data.lead.history_data;
    console.log('Received Data:',  this.statusData 
    
    );
  }

}

import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Component, Inject } from "@angular/core";
import { CommonService } from "../MyServices/common.service";
import { AppLoaderService } from "../app-loader/app-loader.service";
import { Router } from "@angular/router";
import Swal from "sweetalert2";

@Component({
  selector: "app-switch",
  template: `<h1 matDialogTitle class="mb-05">{{ data.title }}</h1>
    <div mat-dialog-content class="mb-1">{{ data.message }}</div>


    <div *ngIf="userPositions.length > 0; else noPositionsTemplate">
      <mat-radio-group [(ngModel)]="selectedOption">
        <div *ngFor="let option of userPositions" class="radio-row">
          <mat-radio-button [value]="option">
            {{ option }}
          </mat-radio-button>
        </div>
      </mat-radio-group>
    </div>

    <ng-template #noPositionsTemplate>
      <div>No available positions to switch.</div>
    </ng-template>

    <div mat-dialog-actions>
      <button
        type="button"
        mat-raised-button
        color="primary"
        [disabled]="!selectedOption"
        (click)="dialogRef.close(selectedOption); callApi(selectedOption)"
      >
        OK
      </button>
      &nbsp;
      <span fxFlex></span>
      <button
        type="button"
        color="accent"
        mat-raised-button
        (click)="dialogRef.close(false)"
      >
        Cancel
      </button>
    </div> `,
})
export class SwitchAccountComponent {
  selectedOption: string = "";
  userPositions: any;
  SessionData: any;

  currentPositions: any = localStorage.getItem("userCurrentPosition");
  constructor(private router: Router,
    public dialogRef: MatDialogRef<SwitchAccountComponent>,
    private commonService: CommonService,
    private loader: AppLoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.userPositions = localStorage.getItem("userPositions");
    this.userPositions = this.userPositions
      ? this.userPositions.split(",")
      : [];

    this.selectedOption = this.userPositions.includes(this.currentPositions)
      ? this.currentPositions
      : "";
    console.log(this.userPositions);
    this.SessionData = this.commonService.getUserDetails();
  }
  callApi(data: any) {
    console.log(localStorage.getItem('userCurrentPosition'));
    console.log(data);
    let payload = {
      position: data,
    };
    this.loader.open();
    this.commonService.switchAccount(payload).subscribe(
      (res) => {
        if (res.success) {
          console.log(res);
          this.loader.close();
          localStorage.removeItem("userPositions");
          localStorage.removeItem("userCurrentPosition");
           
          if (
            res.data.user_other_positions &&
            res.data.user_other_positions.length > 0
          ) {
            localStorage.setItem("userPositions", res.data.user_other_positions[0]);
          }
          localStorage.setItem("userCurrentPosition", data);

          // if (res.data.role_wise_pages.length > 0) {
            // localStorage.setItem(
            //   "PageDetails",
            //   JSON.stringify(res.data.role_wise_pages)
            // );
          // }
       const pages:any = res.data.role_wise_pages
       
          if (pages.length > 0) {
            localStorage.removeItem("PageDetails");
            localStorage.setItem(
              "PageDetails",
              JSON.stringify(res.data.role_wise_pages)
            );
            if (this.SessionData.position == 'Tata OK TSM') {
              this.router.navigate(['pages/TSMProductList'])
            }
            else{
              this.router.navigate(['pages/InventoryList'])
            }
            setTimeout(() => {
            window.location.reload()
            }, 300);
          }
          else{
            this.loader.close();
            Swal.fire('Page is not available please connect to TML.')
          }
         
        } else {
          this.loader.close();
          Swal.fire('Invalid party subtype.')
        }
      },
      (err) => {
        this.loader.close();
        localStorage.removeItem("userCurrentPosition");
        localStorage.setItem("userCurrentPosition", data);
        console.log("error occured", err);
      }
    );
  }
}

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-confirm',
  template: `
  <div class="closebtn">
    <span (click)="dialogRef.close(false)">X</span>
  </div>
  <div style="overflow-x: scroll;">
    <table class="styled-table">
      <thead>
        <tr>
          <th *ngFor="let key of allKeys">
            {{ key }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of data">
          <td *ngFor="let key of allKeys">
            {{ item[key] || '' }} 
          </td>
        </tr>
      </tbody>
    </table>
    </div>
    <div mat-dialog-actions class="dialog-actions">
      <button type="button" color="accent" mat-raised-button (click)="dialogRef.close(false)">Cancel</button>
    </div>
  `,
  styles: [`
  .closebtn {
    display: flex;
    justify-content: end;
    span{
      font-size: 20px;
      cursor: pointer;
    }
  }
    .styled-table {
      width: 100%;
      border-collapse: collapse;
      font-family: 'Arial', sans-serif;
      margin: 20px 0;
      font-size: 16px;
      text-align: left;
    }

    .styled-table thead tr {
      background-color: #092165;
      color: white;
      text-align: left;
      font-weight: bold;
    }

    .styled-table th, .styled-table td {
      padding: 12px 15px;
      border: 1px solid #ddd;
    }

    .styled-table tbody tr {
      border-bottom: 1px solid #ddd;
    }

    .styled-table tbody tr:nth-of-type(even) {
      background-color: #f3f3f3;
    }

    .styled-table tbody tr:hover {
      background-color: #f1f1f1;
    }

    .styled-table tbody tr:last-of-type {
      border-bottom: 2px solid #092165;
    }

    .dialog-actions {
      margin-top: 20px;
      text-align: right;
    }

    .dialog-actions button {
      background-color: blue;
      color: #fff;
      border: none;
      padding: 10px 20px;
      cursor: pointer;
    }

    .dialog-actions button:hover {
      background-color: #092165;
    }
  `]
})
export class PopUpComponent {
  remarksForm: FormGroup;
  allKeys: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<PopUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[],  
    private fb: FormBuilder
  ) {
    this.remarksForm = this.fb.group({
      remarks: ['', Validators.required]
    });
    
    this.allKeys = this.getAllUniqueKeys(data);
    console.log(this.allKeys);
    
  }

  // getAllUniqueKeys(data: any[]): string[] {
  //   const keySet = new Set<string>();
  //   data.forEach(item => {
  //     Object.keys(item).forEach(key => keySet.add(key));
  //   });
  //   return Array.from(keySet); 
  // }
  getAllUniqueKeys(data: any[]): string[] {
    const keySet = new Set<string>();
    let minKey = null;
    let maxKey = null;
    let manufacturerYearKey = null;
    let aptsKey = null;

    data.forEach(item => {
      Object.keys(item).forEach(key => {
        if (key === 'Min') {
          minKey = 'Min';
        } else if (key === 'Max') {
          maxKey = 'Max';
        } else if (key === 'Manufacturer_Year') {
          manufacturerYearKey = 'Manufacturer_Year';
        } else if (key === 'APTS') {
          aptsKey = 'APTS';
        } else {
          keySet.add(key);
        }
      });
    });
  

    const allKeys = Array.from(keySet);

    if (aptsKey) {
      allKeys.push(aptsKey);
    }
    if (minKey) {
      allKeys.push(minKey);
    }
    if (maxKey) {
      allKeys.push(maxKey);
    }
    if (manufacturerYearKey) {
      allKeys.push(manufacturerYearKey);
    }
  
    return allKeys;
  }
  
  
}

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
 // apiUri: 'https://eguruskindev.api.tatamotors',
  //TataapiUri: 'http://139.59.77.169:8321'
  // TataapiUri: 'https://skindevreplica.api.tatamotors', // sandbox
  TataapiUri: 'https://skinprod.api.tatamotors', // Production
  ospProd:'https://digital-osp-cv-prod-ms.api.tatamotors'
  // ospProd:'https://digital-osp-cv-dev-ms.api.tatamotors'

};

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { AuthGuard } from '../../shared/services/auth/auth.guard';
//import { InventoryMasterComponent } from '../../views/pages/inventory-master/inventory-master.component';
import { InventoryListComponent } from '../../views/pages/inventory-list/inventory-list.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuctionMasterComponent } from './auction-master/auction-master.component';
import { AuctionListComponent } from './auction-list/auction-list.component';
import  { UserListComponent } from './user-list/user-list.component';
import { UserRegistrationComponent } from './user-registration/user-registration.component';
import { BuyerRegistrationComponent } from './buyer-registration/buyer-registration.component';
import { BuyerRegistrationListComponent } from './buyer-registration-list/buyer-registration-list.component';
import { AdminRegistrationComponent } from './admin-registration/admin-registration.component';
import { AdminRegistrationListComponent } from './admin-registration-list/admin-registration-list.component';
import { DepositListComponent } from './deposit-list/deposit-list.component';
import { DepositMasterComponent } from './deposit-master/deposit-master.component';
import { BidListComponent } from './bid-list/bid-list.component';
import { InvListComponent } from './inv-list/inv-list.component';
import { AddInventoryComponent } from './add-inventory/add-inventory.component';
import {BidInventoryComponent} from './bid-inventory/bid-inventory.component'
import {ClosedBidListComponent} from './closed-bid-list/closed-bid-list.component'
import {BidderComponent} from './bidder/bidder.component'
import {BidSettelmentComponent} from './bid-settelment/bid-settelment.component'
import {SettelmentmasterComponent} from './settelmentmaster/settelmentmaster.component'
import { InventoryMastersComponent } from './inventory-masters/inventory-masters.component'
import { TestPageComponent } from './test-page/test-page.component';
import { InventoryAddComponent } from './inventory-add/inventory-add.component';
import { RefundListComponent } from './refund-list/refund-list.component';
import { RefundMasterComponent } from './refund-master/refund-master.component';
import { AutionTestComponent } from './aution-test/aution-test.component';
import { BankListComponent } from './bank-list/bank-list.component';
import { BankDetailComponent } from './bank-detail/bank-detail.component';
import { CheckStockVehicleComponent } from './inventory-add/chcek-stcok-vehicle/check-stock-vehicle.component';
import { ViewEnquiryComponent } from './view-enquiry/view-enquiry.component';
import { RolePageMappingComponent } from './Master/role-page-mapping/role-page-mapping.component';
import { PositionMasterComponent } from './Master/position-master/position-master.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { ProductListComponent } from './product-list/product-list.component';
import { PermissionListComponent } from './permission-list/permission-list.component';
import { LeadListComponent } from './product-list/lead-list/lead-list.component';
import { TsmProductListComponent } from './product-list/tsm-product-list/tsm-product-list.component';
import { InventoryAddRemarkComponent } from './inventory-add-remark/inventory-add-remark.component';
import { AgreementListComponent } from './product-list/agreement-list/agreement-list.component';
import { TempInventoryComponent } from './temp-inventory/temp-inventory.component';

const routes: Routes = [
  // commented code

  // {path:'BankDetail',component:BankDetailComponent,data: { title: 'BankDetail', breadcrumb: 'BankDetail' }},
  // {path:'BankList',component:BankListComponent,data: { title: 'BankList', breadcrumb: 'BankList' }},
  // {path:'auctiondemo',component:AutionTestComponent,data: { title: 'Auction', breadcrumb: 'Auction' }},
  // {path:'test',component:TestPageComponent,data: { title: 'Test', breadcrumb: 'Test' }},
  // {path:'Dashboard',component:DashboardComponent,data: { title: 'Dashboard', breadcrumb: 'Dashboard' }},
  // {path:'RefundList',component:RefundListComponent,data: { title: 'RefundList', breadcrumb: 'RefundList' }},
  // {path:'RefundMaster',component:RefundMasterComponent,data: { title: 'RefundMaster', breadcrumb: 'RefundMaster' }},

  // commented code

  {path:'InvList',component:InventoryListComponent,data: { title: 'InventoryList', breadcrumb: 'InventoryList' }},


  // {path:'Auction',component:AuctionMasterComponent,data: { title: 'Auction', breadcrumb: 'Auction' }},
  // {path:'AuctionList',component:AuctionListComponent,data: { title: 'AuctionList', breadcrumb: 'AuctionList' }},


  {path:'BuyerReg',component:BuyerRegistrationComponent,data: { title: 'BuyerReg', breadcrumb: 'BuyerReg' }},
  {path:'BuyerUserList',component:BuyerRegistrationListComponent,data: { title: 'BuyerUserList', breadcrumb: 'BuyerUserList' }},
  {path:'AdminReg',component:AdminRegistrationComponent,data: { title: 'AdminReg', breadcrumb: 'AdminReg' }},
  {path:'AdminUserList',component:AdminRegistrationListComponent,data: { title: 'AdminUserList', breadcrumb: 'AdminUserList' }},
  {path:'Userlist',component:UserListComponent,data: { title: 'Userlist', breadcrumb: 'Userlist' }},

// commented code

  // {path:'DepositList',component:DepositListComponent,data: { title: 'DepositList', breadcrumb: 'DepositList' }},
  // {path:'AddDeposit',component:DepositMasterComponent,data: { title: 'AddDeposit', breadcrumb: 'AddDeposit' }},
  // {path:'BidList',component:BidListComponent,data: { title: 'BidList', breadcrumb: 'BidList' }},

// commented code

  {path:'AddInv',component:InventoryMastersComponent,data: { title: 'InventoryMaster', breadcrumb: 'InventoryMaster' }},
  {path:'InventoryList',component:InvListComponent,data: { title: 'InvList', breadcrumb: 'InvList' }},
  // {path:'InventoryListOLD',component:TempInventoryComponent,data: { title: 'InvList', breadcrumb: 'InvList' }},
  {path:'InventoryMaster',component:InventoryAddComponent,data: { title: 'InventoryMaster',  breadcrumb: 'InventoryMaster'}},
  {path:'InventoryMasterTSM',component:InventoryAddRemarkComponent,data: { title: 'InventoryMasterTSM',  breadcrumb: 'InventoryMasterTSM'}},

// commented code

  // {path:'BidInventory',component:BidInventoryComponent,data: { title: 'BidInventory', breadcrumb: 'BidInventory' }},
  // {path:'BidSettelMent',component:BidSettelmentComponent,data: { title: 'BidSettelment', breadcrumb: 'BidSettelment' }},
  // {path:'Bidder',component:BidderComponent,data: { title: 'Bidder', breadcrumb: 'Bidder' }},
  // {path:'ClosedBidList',component:ClosedBidListComponent,data: { title: 'CloseBid', breadcrumb: 'CloseBid' }},
  // {path:'SettelMentMaster',component:SettelmentmasterComponent,data: { title: 'SettelMentMaster', breadcrumb: 'SettelMentMaster' }},
  // { path: 'InventoryMaster/:InventoryId', component: InventoryAddComponent,data: { title: 'InventoryMaster', breadcrumb: 'InventoryMaster' }},

// commented code

  {path:'StockVehcile',component:CheckStockVehicleComponent,data: { title: 'StockVehcile', breadcrumb: 'StockVehcile' }},
  {path:'ViewEnquiry',component:ViewEnquiryComponent,data: { title: 'ViewEnquiry', breadcrumb: 'ViewEnquiry' }},
  {path:'RolePageMapping',component:RolePageMappingComponent,data: { title: 'Role Page Mapping', breadcrumb: 'Role page Mapping' }},
  {path:'PartyMaster',component:PositionMasterComponent,data: { title: 'Party Master', breadcrumb: 'Party Master' }},
  {path:'ProductList',component:ProductListComponent,data: { title: 'AddProduct', breadcrumb: 'AddProduct' }},
  {path:'TSMProductList',component:TsmProductListComponent,data: { title: 'TSMAddProduct', breadcrumb: 'TSMAddProduct' }},
  {path:'AgreementList',component:AgreementListComponent,data: { title: 'TSMAddProduct', breadcrumb: 'TSMAddProduct' }},
  {path:'PermissionList',component:PermissionListComponent,data: { title: 'PermissionList', breadcrumb: 'PermissionList' }},
  {path:'LeadList',component:LeadListComponent,data: { title: 'LeadList', breadcrumb: 'LeadList' }},
  {path:'ErrorPage',component:ErrorPageComponent,data: { title: 'Error Page', breadcrumb: 'Error Page' }},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
